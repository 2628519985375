import styled from "styled-components";
import { Grid } from "ui";
import AppBar from "../../components/AppBar";
export const LayoutGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, 1fr);
  grid-template-areas:
    "header"
    "main";
  min-height: 100vh;
`;

export const Header = styled(AppBar)`
  grid-area: header;
  padding: 24px;
`;

export const Main = styled.div`
  display: flex;
  grid-area: main;
`;

export const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  max-width: 1200px;
  padding-top: 40px;
`;

export const MainContent = styled.div`
  /* position: relative; */
  flex: 1;
  height: 100vh;
  padding-top: 40px;
  max-width: 1200px;
`;

export const Background = styled.div<{ showBackground: boolean }>`
  ${({ showBackground }) =>
    showBackground
      ? `
    
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  `
      : `
    background-color: #fff;
  `}
`;

// Define a custom media query breakpoint
const customBreakpoint = "1430px"; // Set this to your desired breakpoint

export const GridWithFlexPadding = styled(Grid)`
  backgroundcolor: ${(props) => props.theme.colors.lightGray};
  padding: 80px 120px; // Default padding

  // Media query for smaller screens
  @media (max-width: ${customBreakpoint}) {
    // You can adjust this breakpoint as needed
    padding: 40px 20px; // Reduced padding for smaller screens
  }

  // You can add more breakpoints if needed
  @media (max-width: 480px) {
    padding: 20px 30px; // Further reduced padding for very small screens
  }
`;
