import styled from "styled-components";
import { Link } from "ui";

export const EntityName = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;

type LinkProps = {
  isUnderLine?: boolean;
};
export const MuiLink = styled(Link)`
  display: flex;
  gap: 8px;
  text-underline-offset: 2px;
  align-items: center;
  text-decoration: ${(props: LinkProps) => (props?.isUnderLine ? "underline" : "unset")};
  cursor: ${(props: LinkProps) => (props?.isUnderLine ? "pointer" : "unset")};
  height: 32px;
`;
