import { BitcoinIcon, EthereumIcon, Grid, Label } from "ui";

type Props = {
  address: string;
  type: "eth" | "btc";
  onClick?: () => void;
};
import * as S from "./styled";
export const AddressItem = ({ address, type, onClick }: Props) => (
  <Grid container item xs={12} columnSpacing={2} flexWrap="nowrap" onClick={onClick}>
    <Grid item>
      <S.IconWrapper>{type === "btc" ? <BitcoinIcon /> : <EthereumIcon />}</S.IconWrapper>
    </Grid>
    <Grid container item xs={12} columnSpacing={1} direction="column">
      <Grid item>
        <b>{address}</b>
      </Grid>
      <Grid item>
        <Label></Label>
      </Grid>
    </Grid>
  </Grid>
);

export default AddressItem;
