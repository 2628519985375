import { Avatar, Grid, UnknownIcon, VariousIcon } from "ui";

export const TokenTags = ({ tokens = [] }: { tokens?: string[] }) => {
  const renderIcon = (token: string) => {
    if (token === "Unknown") {
      return <UnknownIcon width={15} height={15} />;
    } else if (token === "Various") {
      return <VariousIcon />;
    }
    return <Avatar src={`/images/tokens/${token.toUpperCase()}.png`} size="extraSmall" />;
  };
  return (
    <Grid container flexWrap="nowrap">
      {tokens.map((token) => (
        <Grid
          container
          columnSpacing={1}
          wrap="nowrap"
          key={token}
          width="fit-content"
          alignItems="center"
          marginRight={1}
        >
          <Grid sx={{ display: "flex" }} item>
            {renderIcon(token)}
          </Grid>
          <Grid item>{token}</Grid>
        </Grid>
      ))}
    </Grid>
  );
};
