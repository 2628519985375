import React from "react";
import { Box } from "ui";

type Props = {
  children: React.ReactNode;
  noBackground?: boolean;
  hidePrint?: boolean;
  style?: React.CSSProperties;
};

export const Section = ({ children, noBackground = false, hidePrint = false, style }: Props) => (
  <Box
    bgcolor={(p) => (noBackground ? "none" : p.colors.lightGray)}
    borderRadius={2}
    paddingX={4}
    paddingY={3}
    marginY={2}
    displayPrint={hidePrint ? "none" : "block"}
    style={style}
  >
    {children}
  </Box>
);

export default Section;
