import { Grid, Subtitle } from "ui";
import { EntityName } from "components";
import ClusterActions from "./ClusterActions";
import type { Cluster } from "types";

import * as S from "./styled";

type Props = {
  cluster: Cluster;
  address: string;
  editMode: boolean;
  setEditMode: (isEditMode: boolean) => void;
  removeAddressFromCluster: () => void;
};

export const ClusterHeader = ({ cluster, address, editMode, setEditMode, removeAddressFromCluster }: Props) => (
  <Grid container item flexWrap="nowrap" direction="column" xs={12}>
    <Grid container item flexWrap="nowrap" alignItems="center">
      <Grid item>
        <Subtitle>Cluster</Subtitle>
      </Grid>
      <Grid container item justifyContent="flex-end">
        {!editMode && (
          <ClusterActions
            isClusterLinked={!!cluster.tag}
            removeAddressFromCluster={removeAddressFromCluster}
            handleEditMode={(isEditMode) => {
              setEditMode(isEditMode);
            }}
          />
        )}
      </Grid>
    </Grid>
    <Grid container item direction="column" alignItems="flex-start">
      <Grid item>
        <S.Address>{address}</S.Address>
        <EntityName name={cluster.tag} />
      </Grid>
    </Grid>
  </Grid>
);

export default ClusterHeader;
