import React from "react";
import * as S from "./styled";

type Props = {
  children: React.ReactNode[] | React.ReactNode | string;
};

const TableCell = ({ children }: Props) => <S.TableCell>{children}</S.TableCell>;

export default TableCell;
