import styled from "styled-components";
import { Colors } from "styles/variables";
import CSS from "csstype";

type Props = {
  color: keyof Colors;
  style?: CSS.Properties;
};

export const Description = styled.span<Props>`
  color: ${(p) => p.theme.colors[p.color]};
  ${(p) => ({ ...p.style })}
`;
