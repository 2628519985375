import styled from "styled-components";

import { Input as InputUI, Autocomplete as AutocompleteUI, Button as ButtonUI } from "ui";

export const SearchWrapper = styled.div`
  display: flex;
  height: 72px;
`;

export const AddressSearch = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const Input = styled(InputUI)`
  margin-right: 10px;
`;

export const Autocomplete = styled(AutocompleteUI)`
  width: 420px;
  margin-right: 10px;

  & .MuiInputBase-root {
    background-color: ${(prop) => prop.theme.colors.lightGray};

    svg {
      path {
        fill: ${(p) => p.theme.colors.secondary};
      }
    }
  }
`;

export const Button = styled(ButtonUI)`
  margin-left: 10px;
`;
