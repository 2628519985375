import React from "react";

type Props = {
  text: string;
  variant?: "primary" | "secondary";
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

import * as S from "./styled";

export const TextButton = ({ text, onClick, variant = "primary" }: Props) => {
  return (
    <S.Text onClick={onClick} variant={variant}>
      {text}
    </S.Text>
  );
};

export default TextButton;
