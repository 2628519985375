import axios, { AxiosError } from "axios";
import { useErrorHandler } from "hooks";
import { useState } from "react";
import { toast } from "react-toastify";
import { DeleteIcon, EditIcon, Grid, IconButton, Subtitle } from "ui";
import { MetadataDetail } from "./MetadataDetail";
import MetadataForm from "./MetadataForm";
import type { MetaData } from "./MetadataForm";
import DeleteMetadata from "./DeleteMetadata";
import { EntityFormType } from "pages/Address/Metadata/CreateEntity";

type Props = {
  address?: string;
  metadata: MetaData | null;
  onMetadataUpdate: (newMetaData: MetaData | null) => void;
};

export const Metadata = ({ address, metadata: metadataProp, onMetadataUpdate }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [metadata, setMetadata] = useState<MetaData>(
    metadataProp || {
      ownerTag: "",
      ownerType: "",
      ownerSubType: "",
      controllerTag: "",
      controllerType: "",
      controllerSubType: "",
      labels: [],
    },
  );
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const handleError = useErrorHandler();

  const saveData = async (data: MetaData) => {
    if (!address || isSaving) {
      return;
    }
    setIsSaving(true);
    try {
      const response = await axios.put<MetaData>(`/api/address/${address}/metadata/`, data);
      toast("Address metadata saved successfully.");
      onMetadataUpdate(response.data);
      setMetadata(response.data);
    } catch (error) {
      handleError("Something went wrong when updating the address metadata.");
    } finally {
      setEditMode(false);
      setIsSaving(false);
    }
  };

  const deleteMetada = async () => {
    try {
      await axios.delete(`/api/address/${address}/metadata/`);
      toast("Address metadata deleted successfully.");
      setMetadata({
        ownerTag: "",
        ownerType: "",
        ownerSubType: "",
        controllerTag: "",
        controllerType: "",
        controllerSubType: "",
        labels: [],
      });
    } catch (error) {
      handleError("Something went wrong when deleting the address metadata.");
    } finally {
      setIsDeleteConfirmOpen(false);
    }
  };

  const createEntity = async (entity: EntityFormType) => {
    try {
      const { tag, type, subtype } = entity;
      await axios.post("/api/entities/new", {
        name: tag,
        type: type,
        subtype,
      });

      toast("Entity created successfully");
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        toast.error(error.response.data.message);
      } else {
        handleError("Something went wrong when updating the address metadata.");
      }
    }
  };

  const updateEntity = async (entity: EntityFormType) => {
    try {
      const { id, tag, type, subtype } = entity;
      const response = await axios.put("/api/entities/metadata", {
        databaseID: id,
        name: tag,
        type: type,
        subtype: subtype || "",
      });
      const updatedEntity = response.data;
      const updatedMetadata = { ...metadata };
      if (updatedEntity.id === metadata.ownerID) {
        updatedMetadata.ownerTag = updatedEntity.name;
        updatedMetadata.ownerType = updatedEntity.type;
        updatedMetadata.ownerSubType = updatedEntity.subtype;
      }

      if (updatedEntity.id === metadata.controllerID) {
        updatedMetadata.controllerTag = updatedEntity.name;
        updatedMetadata.controllerType = updatedEntity.type;
        updatedMetadata.controllerSubType = updatedEntity.subtype;
      }
      setMetadata(updatedMetadata);
      toast("Entity updated successfully");
    } catch (error) {
      handleError("Something went wrong when updating the address metadata.");
    }
  };

  return (
    <Grid container item xs={12} direction="column">
      <Grid container item flexWrap="nowrap" alignItems="center">
        <Grid item>
          <Subtitle>Metadata</Subtitle>
        </Grid>
        <Grid container item justifyContent="flex-end">
          <IconButton>
            <EditIcon color="primary" onClick={() => setEditMode(true)} />
          </IconButton>
          <IconButton>
            <DeleteIcon color="primary" onClick={() => setIsDeleteConfirmOpen(true)} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item>
        {editMode ? (
          <MetadataForm saveData={saveData} onCancel={() => setEditMode(false)} metadata={metadata} />
        ) : (
          <MetadataDetail
            metadata={metadata}
            onEditMode={() => setEditMode(true)}
            onDeleteMetadata={() => setIsDeleteConfirmOpen(true)}
            onCreateEntity={createEntity}
            onUpdateEntity={updateEntity}
          />
        )}
      </Grid>
      {isDeleteConfirmOpen && <DeleteMetadata onCancel={() => setIsDeleteConfirmOpen(false)} onDelete={deleteMetada} />}
    </Grid>
  );
};

export default Metadata;
