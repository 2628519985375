import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { Subtitle, Title, Grid } from "ui";
import { Emphasis, Section, Container } from "./style";

const Guide = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <Grid container maxWidth={1000} marginX="auto" marginTop={5}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="User Guide" />
          <Tab label="Glossary" />
        </Tabs>
      </Box>
      <Container>
        <div hidden={tab !== 0}>
          <Section>
            <Title color="primary">Welcome to the User Guide Page!</Title>
          </Section>
          <Section>
            <b>Who are we?</b>
            <p>
              Hoptrail is a crypto analytics company. We build blockchain-based risk intelligence tools for institutions
              involved in or exposed to the digital asset sector. We use a blend of off-chain and on-chain data to
              identify and assess risks attached to everything across the ecosystem - from entities and events to
              cryptocurrency addresses and tokens – helping businesses to make informed and timely decisions about
              partners, clients, and investments.
            </p>
            <b>What does the platform do?</b>
            <p>
              Hoptrail flagship product is our crypto diligence platform. The platform allows users to search, identify
              and examine risks across the ecosystem, and to scrutinise counterparty activities on-chain – whether its
              due diligence on an exchange or a source of funds check on a prospective client. The platform is purpose
              built to be intuitive, modelled on a Google-style search experience.
            </p>
            <p>
              The platform comprises more than 300 million tagged wallets across the Bitcoin and Ethereum blockchains,
              covering more than 2,000 entities and events from exchanges and payment services to darknet marketplaces,
              sanctions, and high-profile hacks. In addition, we profile, score and rank more than 800 virtual asset
              service providers using our proprietary risk scoring system. This draws on 23 AML-CFT criteria to assess
              how VASPs are responding to and dealing with financial crime risks.
            </p>
            <p>The four core components of the platform are:</p>
            <ul>
              <li>
                <Emphasis>Profiles:</Emphasis> Concise risk reviews on entities and events, covering KYC/AML controls,
                security, licensing, regulation, and litigation.
              </li>
              <li>
                <Emphasis>Leaderboards:</Emphasis> Real-time scoring and ranking of VASPs across 11 categories,
                including exchanges, DEXs, custody, gambling, and financial services, among others.
              </li>
              <li>
                <Emphasis>Explorer:</Emphasis> Dedicated source of funds and onboarding tool for Bitcoin and Ethereum
                assets.
              </li>
              <li>
                <Emphasis>Portfolio:</Emphasis> Monitor a customer’s crypto portfolio, including key meta data and
                high-risk transactions.
              </li>
            </ul>
          </Section>
          <Subtitle color="primary">How to interpret the Explorer</Subtitle>
          <p>
            Our Bitcoin and Ethereum Explorer provides three key things:
            <ul>
              <li>
                Where funds have come from <Emphasis>(Sources)</Emphasis>
              </li>
              <li>
                Where money has gone to <Emphasis>(Destinations)</Emphasis>
              </li>
              <li>
                What the red flags are <Emphasis>(High-Risk Transactions)</Emphasis>
              </li>
            </ul>
          </p>
          <p>
            Each of these three components is clearly displayed when searching a crypto address, regardless of whether
            its Bitcoin, Ethereum, or even ERC-20 (Ethereum compatible tokens).
          </p>
          <p>
            Many entities in the tables are clickable. Clicking on one will produce a pop-up window which shows the
            detailed AML profile on that entity. This is designed to provide the user with instant insights into
            counterparties and services in the ecosystem.
          </p>
          <p>
            Profiles are updated regularly by our team of analysts. We review data to ensure our profiles reflect
            emerging trends and developments within the cryptocurrency space.
          </p>
          <Subtitle color="primary">Key Platform Functions</Subtitle>

          <p>
            <b>Toggle Buttons</b>
            <br />
            The Bitcoin Explorer allows the user to see direct counterparties (1 Hop), and indirect counterparties (2-3
            Hops). You can toggle between these options using the Direct / Indirect switch above the Sources and
            Destinations tables.
          </p>
          <ul>
            <li>
              <Emphasis>All Entities</Emphasis>: switching this button allows you to see all counterparties including
              unknown addresses, unknown clusters, and unknown smart contracts.
            </li>
            <li>
              <Emphasis>Breakdown</Emphasis>: switching this button on will display all underlying addresses for the
              sources and destinations. This will allow you to navigate backwards and forward along the blockchain and
              perform simple tracing work on the flow of funds.
              <br />
              In the case of Bitcoin, every input and output (i.e. every sender and recipient within a transaction) will
              be displayed. This provides a high-level of detail when seeking to isolate or find sources and
              destinations, particularly when piecing together a customer’s sources of funds.
            </li>
          </ul>
          <b>Tags, Types, and Labels</b>
          <p>At the top of the search pages will be the address and any information tied to that address, including:</p>
          <ul>
            <li>
              <Emphasis>Tag</Emphasis>: the service or entity to which that address is tied
            </li>
            <li>
              <Emphasis>Type</Emphasis>: the type of service that tag performs (i.e. exchange, mixing, darknet
              marketplace)
            </li>
            <li>
              <Emphasis>Labels</Emphasis>: any additional descriptors we have found that are relevant to that address
              and are helpful in explaining its functions (i.e. deposit address, fiat gateway, casino, etc)
            </li>
          </ul>
          <p>Check out our Glossary to find out more about some of the terminology we use on the platform.</p>
          <b>Large address loading issues</b>
          <p>
            Hoptrail’s platform is built to scrutinise customer addresses rather than high-volume hot wallets.
            Nonetheless, you may come into contact with addresses that have high transaction counts. This may result in
            a longer load time as we gather all the data for the sources and destinations table.
          </p>
          <p>
            For Ethereum, where large addresses are encountered, we display direct counterparty data only. This does not
            stop you from tracing forwards or backwards. It just does not come with indirect hops analysis.
          </p>
          <p>We are working on performance improvements that will resolve these issues in most areas. Stay tuned.</p>
          <b>High-Risk Transactions</b>
          <p>
            The platform isolates any red flags to which an address has been exposed in the High-Risk Transactions
            section. This is designed to allow for greater scrutiny of potential risks to assess whether they are
            material or not. Users can click through on the high-risk addresses, see the underlying entity profiles, and
            explore hash data.
          </p>
          <p>
            Only high-risk addresses – such as those tied to darknet marketplaces, hacks, sanctions, or mixing services
            – will display in the High Risk Transactions table. Medium risk addresses will not.
          </p>
          <b>Portfolio</b>
          <p>
            At any time, the user can add cryptocurrency addresses to a portfolio. The purpose of this is to enable
            users to create a dashboard of relevant addresses to monitor or compile into a downloadable document.
          </p>
          <p>
            The portfolio builder button is in the top right corner. You can create your own portfolio from there and
            begin adding addresses. To add an address, search for it in the search bar and then click ‘Add to
            Portfolio’. You will then see an option to add it to the relevant portfolio.
          </p>
          <b>Technical issues?</b>
          <p>
            For any issues, technical support, or questions with the platform, you can reach us here:{" "}
            <a href="mailto:info@hoptrail.io">info@hoptrail.io</a>
          </p>
        </div>
        <div hidden={tab !== 1}>
          <Section>
            <Title color="primary">Glossary</Title>
            <p>
              <Emphasis>VASP</Emphasis> – stands for Virtual Asset Service Provider, any business or person who provides
              services related to virtual assets (also known as digital assets or cryptocurrencies). These services can
              include but are not limited to the exchange between virtual assets and fiat currencies, the exchange
              between one or more forms of virtual assets, the transfer of virtual assets, and the safekeeping or
              administration of virtual assets or instruments enabling control over virtual assets.
            </p>
            <p>
              <Emphasis>Hops</Emphasis> – hops are transactions between addresses. A transaction between two addresses
              is one hop, whereas a transaction chain involving five separate addresses is four hops from start to
              finish.
            </p>
            <p>
              <Emphasis>Clusters</Emphasis> – clusters are groups of associated addresses. They are formed when an
              entity or individual uses multiple addresses to transact. Clusters are typically identified where
              addresses are seen as co-inputs (or senders) in a transaction). Untagged clusters are often referenced as
              ‘unknown’ or ‘unnamed’ clusters.
            </p>
            <p>
              <Emphasis>ERC-20</Emphasis> – ERC-20 is a technical standard for all smart contracts in the Ethereum
              ecosystem. ERC-20 tokens are Ethereum-compatible assets that are issued and traded on the Ethereum
              blockchain, and include their own set of trading rules as governed by their smart contract. There are
              thousands of protocols run on ERC-20, including some of the world’s largest token projects: Maker,
              ChainLink, Tether, and Basic Attention Token, among others.
            </p>
            <p>
              <Emphasis>ERC-721</Emphasis> – ERC-721 is an Ethereum-compatible non-fungible token standard, commonly
              referred to as NFTs. Each NFT is unique; its non-fungible status means that it cannot be swapped. NFTs are
              frequently used for things such as digital art to lottery tickets and collectibles.
            </p>
            <p>
              <Emphasis>Unknown Addresses</Emphasis> – Unknown Addresses are addresses yet to be formally tagged (i.e.
              found to be associated with a known exchange, service, or event). Unknown addresses are not linked to a
              cluster, known or unknown.
            </p>
            <p>
              <Emphasis>Unknown Clusters</Emphasis> – Unknown Clusters are affiliated addresses yet to be formally
              tagged (i.e. found to be associated with a known exchange, service, or event).
            </p>
            <p>
              <Emphasis>Hops Exceeded</Emphasis> – Hops Exceeded denotes funds that have been identified as deposits or
              withdrawals, but which are not tied to a known entity when screening at hops one, two, or three.
            </p>
            <p>
              <Emphasis>CoinJoin</Emphasis> – CoinJoin is a technology created to anonymise Bitcoin transfers by masking
              multiple transactions as one single transaction. In this way, senders within a transaction can appear to
              derive from multiple sources, rendering a definitive assessment of the source of funds challenging.
            </p>
            <p>
              <Emphasis>Insignificant Amounts</Emphasis> – Insignificant amounts are identified when funds are
              transferred between wallets which fall below a set threshold. For Bitcoin this is BTC 0.0001 ($1.80) and
              for Ethereum this is ETH 0.001 ($1.40). Any known <i>direct</i> sources or destinations that fall below
              these thresholds will be displayed on the Explorer.
            </p>
            <p>
              <Emphasis>High-Risk Entities</Emphasis> – high-risk entity or event categories can vary but typically
              include: dark web marketplaces, hacks, scams, ransomware, mixing services, sanctions, child abuse, and
              terrorist groups.
            </p>
            <p>
              <Emphasis>Low-Risk Entities</Emphasis> – the low-risk entity / events category can change, but typically
              includes: exchanges (centralised), payment services, wallet providers, mining pools, gambling, donations,
              smart contracts, and ICOs.
            </p>
            <p>
              <Emphasis>External Transaction Monitoring</Emphasis> – External transaction monitoring occurs when an
              entity uses a third-party platform or service provider to bolster and structure processes for transaction
              monitoring across the entity.
            </p>
            <p>
              <Emphasis>Internal Transaction Monitoring</Emphasis> – Internal transaction monitoring occurs when an
              entity uses its own processes or self-developed platform to monitor transactions, which is typically done
              through a risk-based approach.
            </p>
            <p>
              <Emphasis>Leaderboard</Emphasis> – the displayed results of Platform’s proprietary AML composite risk
              index showing the relative rankings of entities drawing on four variables developed from 15 AML criteria.
            </p>
            <p>
              <Emphasis>Privacy Coins</Emphasis> – also referred to as &quot;privacy tokens&quot;, are cryptocurrencies
              designed to provide users with enhanced privacy and anonymity for their transactions. Unlike many
              cryptocurrencies that have transparent blockchain transactions, privacy coins use various cryptographic
              techniques to obscure the transaction details, such as the identity of the sender and receiver, and the
              transaction amount. This makes it much harder for third parties to trace transactions or determine the
              balance of a wallet. Examples of privacy coins include Monero (XMR), Zcash (ZEC), and Dash (DASH).
            </p>
          </Section>
        </div>
      </Container>
    </Grid>
  );
};

export default Guide;
