import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import type { Entity } from "types";
import EntityProfile from "components/EntityProfile";
import { useParams } from "react-router-dom";

type EntityProfileDetailProps = {
  entityName?: string;
};

export const EntityProfileDetail: React.FC<EntityProfileDetailProps> = ({ entityName }) => {
  const appContext = useContext(AppContext);

  const params = useParams();
  const entityToLoad = entityName || params.entityName;

  const entityDetail = appContext.entities.find((entity) => entity.name === entityToLoad) as Entity;

  return <div>{entityDetail && <EntityProfile entity={entityDetail} />}</div>;
};

export default EntityProfileDetail;
