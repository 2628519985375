import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styled from "styled-components";

type Props = {
  columns: {
    field: string;
    headerName?: string;
    align?: "left" | "center" | "right" | "justify" | "inherit";
    width?: number | string;
    renderCell?: (param: any) => any;
  }[];
  rows: Record<string, any>[];
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "4px 16px",
  [`&.${tableCellClasses.head}`]: {
    color: theme.colors.primary,
    fontWeight: 800,
    whiteSpace: "nowrap",
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  "& .MuiChip-label": {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const BasicTable = ({ columns, rows }: Props) => {
  return (
    <TableContainer component={Paper} elevation={0} sx={{ border: "1px solid #ccc" }}>
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell key={column.headerName} align={column.align}>
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              {columns.map((column) => (
                <StyledTableCell key={column.headerName} align={column.align}>
                  <Grid container maxWidth={column.width}>
                    {column.renderCell ? column.renderCell({ row, value: row[column.field] }) : row[column.field]}
                  </Grid>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;
