import React from "react";
import { useTheme } from "@mui/material/styles";
import { Title, ArrowLinkForward, Grid, Button } from "ui";
import { LabelValue } from "components";
import { formatDate } from "utils";
import * as S from "./styled";
import { Hack, Sanction } from "types";
import EventBadge from "components/EventBadge";
import Jurisdictions from "components/Jurisdictions";
import { HackDetail, SanctionDetail } from "pages";
import { useModal } from "contexts/ModalContext";

type EventPopupProps = {
  data: Hack | Sanction;
  content: React.ReactNode;
};

const isSanction = (event: Hack | Sanction): event is Sanction => {
  return event.Type === "Sanction";
};

const isHackEvent = (event: Hack | Sanction): event is Hack => {
  return event.Type === "Hack";
};

export const EventPopup: React.FC<EventPopupProps> = ({ data, content }) => {
  const { openModal } = useModal();
  const theme = useTheme();

  const isHackType = isHackEvent(data);
  const name = "Event Description" in data ? data["Event Description"] : data.Subject;
  const eventDetails = isHackType ? <HackDetail hackId={data.id} /> : <SanctionDetail sanctionId={data.id} />;
  return (
    <S.ProfileTooltip
      placement="bottom-start"
      title={
        <Grid display="grid" alignItems="center">
          <S.Body>
            <EventBadge type={isHackType ? "Hack" : "Sanction"} theme={theme} />

            <Title variant="regular">
              <S.Title>{name}</S.Title>
              <S.Subtitle fontSize="12px">{formatDate(data.Date, "long")}</S.Subtitle>
            </Title>

            <S.InfoSection>
              <LabelValue labelLineHeight="12px" label="Type" value={data["Sub-Type"]} labelColor="darkGray" print />
              <LabelValue
                labelLineHeight="12px"
                label="Jurisdiction"
                value={<Jurisdictions jurisdictions={data.Jurisdiction} />}
                labelColor="darkGray"
                width={280}
              />
              {isSanction(data) && (
                <LabelValue
                  labelLineHeight="12px"
                  label="Enforcement Body"
                  value={data["Enforcement Body"]}
                  labelColor="darkGray"
                />
              )}
            </S.InfoSection>

            <Button
              color={"secondary"}
              variant={"outlined"}
              onClick={() => openModal(eventDetails)}
              style={{
                boxShadow: "none",
                padding: "4px 20px",
                height: "40px",
                marginTop: "16px",
                maxWidth: "unset",
                textTransform: "unset",
                fontSize: "14px",
              }}
            >
              <S.ProfileButtonText>
                Read more <ArrowLinkForward color={theme.colors.darkGray} size="24" />
              </S.ProfileButtonText>
            </Button>
          </S.Body>
        </Grid>
      }
    >
      <div>{content}</div>
    </S.ProfileTooltip>
  );
};
