import Login from "pages/Login";
import { useRoutes } from "react-router-dom";
import AppRoutes from "./app";

const Routes = () =>
  useRoutes([
    {
      path: "/login",
      element: <Login />,
    },
    ...AppRoutes,
  ]);

export default Routes;
