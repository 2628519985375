import { Grid } from "ui";
import HighRiskTable from "./HighRiskTable";
import PortfolioTable from "./PortfolioTable";
import { Report as EthReport } from "types/ethereum";

import type { Report, Transaction } from "types";

type Props = {
  portfolioData: (Report | EthReport)[];
  highRiskTransactions: Transaction[];
  handleAddressDelete: (address: string) => void;
};

export const PortfolioDetail = ({ portfolioData, highRiskTransactions, handleAddressDelete }: Props) => {
  return (
    <Grid container direction="column" rowSpacing={4}>
      {portfolioData.length > 0 ? (
        <>
          <Grid item>
            <PortfolioTable data={portfolioData} onAddressDelete={handleAddressDelete} />
          </Grid>
          <Grid item>
            <HighRiskTable transactions={highRiskTransactions} />
          </Grid>
        </>
      ) : (
        <Grid item marginTop="16px">
          Please add an address using the search bar
        </Grid>
      )}
    </Grid>
  );
};

export default PortfolioDetail;
