import React from "react";
import { Tooltip, Link as UILink } from "ui";

type HashFieldProps = {
  value?: string;
  coin: string;
  row: { hash: string };
};

const HashField: React.FC<HashFieldProps> = ({ value, coin, row }) => {
  const { hash } = row;
  const isGenesisHash = hash.includes("GENESIS");
  const shortHash = `${hash.slice(0, 5)}...`;

  const linkHref =
    coin === "btc" ? `https://www.blockchain.com/${coin}/tx/${value}` : `https://etherscan.io/tx/${value}`;

  return (
    <Tooltip title={hash} arrow>
      {isGenesisHash ? (
        <span>{shortHash}</span>
      ) : (
        <UILink href={linkHref} target="_blank" rel="noreferrer">
          {shortHash}
        </UILink>
      )}
    </Tooltip>
  );
};

export default HashField;
