import { Grid, Subtitle, Table } from "ui";
import getColumns from "./columns";
import type { Transaction } from "types";
import { useContext } from "react";
import AuthContext from "contexts/Auth0Context";

type Props = {
  transactions: Transaction[];
};

const HighRiskTable = ({ transactions }: Props) => {
  const columns = getColumns();
  const { user } = useContext(AuthContext);

  return (
    <Grid container rowSpacing={2} direction="column">
      <Grid item container justifyContent="left">
        <Subtitle>High Risk Transactions</Subtitle>
      </Grid>
      <Grid item>
        <Table
          allowExport={user.isAdmin}
          columns={columns}
          rows={transactions.map((tx, index) => {
            return {
              ...tx,
              id: index,
            };
          })}
          initialState={{
            sorting: {
              sortModel: [{ field: "amount", sort: "desc" }],
            },
          }}
          fontSize="small"
        />
      </Grid>
    </Grid>
  );
};

export default HighRiskTable;
