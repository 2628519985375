import getColumns from "./columns";

import { Table } from "ui";
import styled from "styled-components";
import { Transaction } from "types";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { TokenTags } from "pages/Events";

type Props = {
  coin: "eth" | "btc" | "erc20";
  originAddress: string;
  originEntityName: string;
  transactions: Transaction[];
  loading: boolean;
  rowCount: number;
  onPageChange: (page: number) => void;
};

const StyleTable = styled(Table)`
  .highlight {
    background: rgba(103, 103, 103, 0.1);
  }
`;

export type TransactionWithSenderReceiver = Transaction & {
  sender: string;
  receiver: string;
};

export const TransactionTable = ({
  transactions,
  coin,
  originAddress,
  originEntityName,
  rowCount,
  onPageChange,
  loading,
}: Props) => {
  const columns = getColumns(coin, originAddress, originEntityName).map((column) => ({
    ...column,
    sortable: false,
  }));

  columns.splice(2, 0, {
    field: "symbol",
    sortable: false,
    headerName: "Asset",
    align: "left",
    width: 120,
    renderCell: (params: GridRenderCellParams<string, Transaction>) => {
      return <TokenTags tokens={params.value ? [params.value] : []} />;
    },
  });

  const enrichedTransactions = transactions.map<TransactionWithSenderReceiver>((tx: Transaction) => {
    let fromAddress, toAddress;
    if (tx.direction === "in") {
      fromAddress = tx.address;
      toAddress = originAddress;
    } else {
      fromAddress = originAddress;
      toAddress = tx.address;
    }

    return {
      ...tx,
      sender: fromAddress,
      receiver: toAddress,
    };
  });

  return (
    <StyleTable
      columns={columns}
      paginationMode="server"
      rowCount={rowCount}
      loading={loading}
      onPageChange={(page) => {
        onPageChange(page);
      }}
      rows={enrichedTransactions.map<TransactionWithSenderReceiver & { id: number }>((tx, index) => {
        return {
          ...tx,
          id: index,
        };
      })}
    />
  );
};

export default TransactionTable;
