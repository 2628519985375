import { useState } from "react";

const useErrorHandler = (givenError?: unknown): ((error: unknown) => void) => {
  const [error, setError] = useState<unknown>(null);
  if (givenError != null) throw givenError;
  if (error != null) throw error;
  return setError;
};

export default useErrorHandler;
