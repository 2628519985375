import React from "react";
import * as S from "./styled";

type Props = {
  children: React.ReactNode[] | React.ReactNode | string;
  color?: keyof Theme["colors"];
  size?: "small" | "normal";
  // Include other props for forwarding
  [x: string]: any;
};

const Label = React.forwardRef<HTMLDivElement, Props>(
  ({ children, color = "darkGray", size = "small", fontWeight = 500, ...otherProps }, ref) => (
    <S.Label ref={ref} color={color} size={size} fontWeight={fontWeight} {...otherProps}>
      {children}
    </S.Label>
  ),
);

Label.displayName = "Label";

export default Label;
