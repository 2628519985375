import React from "react";
import { EventStar } from "ui";
import * as S from "./styled";

type EventBadgeProps = {
  type: "Hack" | "Sanction" | "Scam";
  theme: Theme;
};

const EventBadge: React.FC<EventBadgeProps> = ({ type, theme }) => {
  const isHack = type === "Hack";
  const isSanction = type === "Sanction";

  const getBadgeColor = () => {
    if (isHack) return theme.colors.orange;
    return theme.colors.red;
  };

  const getBadgeBackground = () => {
    if (isHack) return theme.colors.lightOrange;
    return theme.colors.lightRed;
  };

  const getBadgeText = () => {
    if (isHack) return "HACK";
    if (isSanction) return "SANCTION";
    return "SCAM";
  };

  return (
    <S.Badge color={getBadgeColor()} backgroundColor={getBadgeBackground()}>
      <EventStar color={getBadgeColor()} />
      {getBadgeText()}
    </S.Badge>
  );
};

export default EventBadge;
