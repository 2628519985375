import { useMediaQuery, useTheme } from "@mui/material";
import { Logo } from "components";
import { AppContext } from "contexts/AppContext";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "ui";
import { TopBar, TopBarSmall } from "./TopBar";

const AppBar: React.FC = () => {
  const appContext = useContext(AppContext);
  const entities = appContext.entities.filter((entity) => entity.description !== undefined);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  return (
    <Grid
      container
      flexWrap="nowrap"
      padding="24px"
      sx={{
        background: "white",
        position: "fixed",
        zIndex: 10,
      }}
    >
      <Grid container item xs="auto" zIndex={1}>
        <Logo variant="small" />
      </Grid>
      {isSmallScreen ? (
        <TopBarSmall isLandingPage={isLandingPage} entities={entities} />
      ) : (
        <TopBar isLandingPage={isLandingPage} entities={entities} />
      )}
    </Grid>
  );
};

export default AppBar;
