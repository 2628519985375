import { Colors } from "styles";

export const getScoreColors = (score: number, isWallet: boolean, colors: Colors, ignoreScoreColor?: boolean) => {
  const { lightGreen, orange, green, lightOrange, darkGreen, red, darkGray, lightGray, white } = colors;
  if (ignoreScoreColor) {
    return {
      backgroundColor: lightGreen,
      color: green,
    };
  }
  type ScoreColors = {
    color: string;
    backgroundColor: string;
    threshold: number;
  };

  const thresholdFactor = isWallet ? 1 : 10;
  const thresholds: ScoreColors[] = [
    {
      threshold: 0,
      backgroundColor: lightGray,
      color: darkGray,
    },
    {
      threshold: 2 * thresholdFactor,
      color: white,
      backgroundColor: red,
    },
    {
      threshold: 4 * thresholdFactor,
      color: white,
      backgroundColor: red,
    },
    {
      threshold: 6 * thresholdFactor,
      color: orange,
      backgroundColor: lightOrange,
    },
    {
      threshold: 8 * thresholdFactor,
      backgroundColor: lightGreen,
      color: darkGreen,
    },
    {
      threshold: 10 * thresholdFactor,
      backgroundColor: green,
      color: white,
    },
  ];
  const scoreColors =
    thresholds.find(({ threshold }) => {
      return score <= threshold;
    }) || thresholds[thresholds.length - 1];

  const { backgroundColor, color } = scoreColors;

  return {
    color,
    backgroundColor,
  };
};
