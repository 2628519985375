import { validateAddress } from "./address";

export const convertSatoshiToBitcoin = (satoshi: number): number => {
  return satoshi / 100000000;
};

export const roundToSignificantDecimalsNumbers = (num: number, points = 1000): number => {
  if (num === 0) {
    return 0;
  }

  const trimmed = Math.round(num * points) / points;

  if (trimmed !== 0) {
    return trimmed;
  }

  return roundToSignificantDecimalsNumbers(num, points * 100);
};

export const convertPenniesToPounds = (value: number) => {
  return value / 10000000000;
};

export const convertWeiToEth = (value: number) => value / 1000000000000000000;

export const convertTokenToDecimal = (value: number, decimals: number) => value / Math.pow(10, decimals);

export const getAddressCoin = (address: string) => {
  return validateAddress(address, "btc") ? "btc" : validateAddress(address, "eth") ? "eth" : undefined;
};

export const formatNumberWithSeparators = (num: number, decimalPoints = 2) => {
  if (num === 0) {
    return "0";
  }
  const cutOff = Number(Math.pow(10, -decimalPoints).toFixed(decimalPoints));
  if (num < cutOff) {
    return `> ${cutOff}`;
  } else {
    return Number(num).toLocaleString("en-GB", {
      maximumFractionDigits: decimalPoints,
    });
  }
};

const convertCryptoCurrency = (value: number, coin: "btc" | "eth" | "erc20", maxDecimals = 2) => {
  // Set 4 decimals for BTC and 3 for ETH
  const exponent = maxDecimals || coin === "btc" ? 4 : 3;
  const decimalPoints = Math.pow(10, exponent);

  let result;

  if (coin === "btc") {
    result = roundToSignificantDecimalsNumbers(convertSatoshiToBitcoin(value), decimalPoints);
  } else if (coin === "eth") {
    result = roundToSignificantDecimalsNumbers(convertWeiToEth(value), decimalPoints);
  } else if (coin === "erc20") {
    result = roundToSignificantDecimalsNumbers(value, decimalPoints);
  } else {
    throw new Error("Invalid coin");
  }
  return result;
};

export const formatFiatValue = (value: number, chain?: "btc" | "eth" | "erc20") => {
  if (chain) {
    value = convertCryptoCurrency(value, chain);
  }

  return Number(value).toLocaleString("en-GB", {
    maximumFractionDigits: 2,
  });
};

export const formatCryptoCurrencyValue = (value: number, coin: "btc" | "eth" | "erc20", maxDecimals = 2) => {
  if (value === 0) {
    return "0";
  }
  const maxDecimalNumbers = coin === "btc" ? 4 : 3;
  const result = convertCryptoCurrency(value, coin, maxDecimals);
  return formatNumberWithSeparators(result, maxDecimalNumbers);
};
