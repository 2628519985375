import styled from "styled-components";
import { Grid } from "ui";

export const PortfolioMenuWrapper = styled(Grid)`
  cursor: pointer;
  height: 42px;

  &:hover {
    background-color: ${(props) => props.theme.colors.lightGray};
  }
`;
