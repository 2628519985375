import React from "react";
import { ErrorDialog } from "ui";

type Props = {
  children?: React.ReactNode;
};

class ErrorBoundary extends React.Component<Props> {
  state: {
    hasError: false;
    showErrorDialog: false;
  };
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, showErrorDialog: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({
      showErrorDialog: true,
    });
  }

  render() {
    if (this.state.showErrorDialog) {
      return <ErrorDialog actionName="GO HOME" onAction={() => location.replace("/")} />;
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default ErrorBoundary;
