import styled from "styled-components";
import { Button as ButtonUI } from "ui";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`;

export const AddressManager = styled.div``;

export const ActionsRow = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const AddressCount = styled.div`
  margin-bottom: 24px;
  width: 300px;
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  width: 300px;
`;

export const AddressInfo = styled.div`
  padding: 30px 0px;
`;

export const PopUpActionsRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
`;

export const SearchButton = styled(ButtonUI)`
  margin-left: 10px;
`;

export const Address = styled.div`
  font-size: 18px;
`;

export const EntitySearchWrapper = styled.div`
  width: 430px;
`;
