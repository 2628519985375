import { ConfirmPopup } from "ui";

type Props = {
  onUnlink: () => void;
  onCancel: () => void;
};

export const UnlinkCluster = ({ onUnlink, onCancel }: Props) => (
  <ConfirmPopup title="Unlink Cluster" confirmText="Unlink" onCancel={() => onCancel()} onConfirm={onUnlink}>
    Do you really want to unlink cluster from the entity?
  </ConfirmPopup>
);

export default UnlinkCluster;
