import React from "react";
import { Avatar, Title, ArrowLinkForward, RisktrailScore, Tag, Button } from "ui";
import { EntityProfile, LabelValue } from "components";
import * as S from "./styled";
import { Entity } from "types";
import { useTheme } from "@mui/material";
import { useModal } from "contexts/ModalContext";

type EntityPopupProps = {
  data: Entity;
  content: React.ReactNode;
};

export const EntityPopup: React.FC<EntityPopupProps> = ({ data, content }) => {
  const { openModal } = useModal();
  const theme = useTheme();

  const yearEstablished = data.founded || "Unknown";
  const keyFigures = data.keyFigures?.split(";") || ["Unknown"];
  const isInActive = data.status == "Inactive";

  return (
    <S.ProfileTooltip
      placement="bottom-start"
      itemType="entity"
      title={
        <>
          <S.Body padding="24px 24px 16px">
            <S.Header>
              <Avatar src={`/images/entities/${data.name}.png`} size="normal" />
              <S.HeaderContainer>
                <Title variant="small">
                  <S.Title width="200px">
                    <span> {data.name}</span>
                    {isInActive && (
                      <Tag label={"Inactive"} color={"darkGray"} size="medium" backgroundColor={"lightGray"} />
                    )}
                  </S.Title>
                </Title>

                <S.Subtitle>
                  <S.Type>
                    <span>{data.tag}</span>
                  </S.Type>
                </S.Subtitle>
              </S.HeaderContainer>
            </S.Header>

            {!isInActive && (
              <div>
                <S.Subtitle fontSize="12px" paddingBottom="8px">
                  AML Score
                </S.Subtitle>
                {data.riskScore ? (
                  <RisktrailScore score={data.riskScore} value={data.riskScore.toFixed(2)} justifyContent="center" />
                ) : (
                  <S.Subtitle color="lightGray" fontSize="12px">
                    Unknown
                  </S.Subtitle>
                )}
              </div>
            )}

            <S.InfoColumn>
              <S.RowDetails>
                <LabelValue
                  labelLineHeight="12px"
                  label="Headquarters"
                  value={data.headquarters || "Unknown"}
                  labelColor="darkGray"
                  valueLineHeight="22px"
                />
                <LabelValue
                  labelLineHeight="12px"
                  label="Established"
                  value={yearEstablished}
                  labelColor="darkGray"
                  valueLineHeight="22px"
                />
              </S.RowDetails>
              <S.RowDetails>
                <LabelValue
                  labelLineHeight="12px"
                  label="Domicile"
                  value={data.jurisdiction || "Unknown"}
                  labelColor="darkGray"
                  valueLineHeight="22px"
                  print
                />
                <LabelValue
                  labelLineHeight="12px"
                  label="Key Figure(s)"
                  value={keyFigures}
                  labelColor="darkGray"
                  valueLineHeight="22px"
                  width={240}
                />
              </S.RowDetails>
            </S.InfoColumn>

            <Button
              color={"secondary"}
              variant={"outlined"}
              onClick={() => openModal(<EntityProfile entity={data} />)}
              style={{
                boxShadow: "none",
                padding: "4px 20px",
                height: "40px",
                marginTop: "16px",
                maxWidth: "unset",
                textTransform: "unset",
                fontSize: "14px",
              }}
            >
              <S.ProfileButtonText>
                Read more <ArrowLinkForward color={theme.colors.darkGray} size="24" />
              </S.ProfileButtonText>
            </Button>
          </S.Body>
        </>
      }
    >
      <div>{content}</div>
    </S.ProfileTooltip>
  );
};
