import React, { useEffect } from "react";
import { Grid, Loading, Title } from "ui";
import axios from "axios";
import { SearchHistoryList } from "./SearchHistoryList";
import { useErrorHandler } from "hooks";

const SearchHistory = () => {
  const [loading, setLoading] = React.useState(true);
  const [history, setHistory] = React.useState([]);
  const handleError = useErrorHandler();

  useEffect(() => {
    try {
      axios.get("/api/history").then((res) => {
        setHistory(res.data);
      });
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Grid container alignItems="center" flexDirection="column" rowGap={4} maxWidth={800} margin="auto">
      <Title>Search History</Title>

      {loading ? <Loading /> : <SearchHistoryList history={history} />}
    </Grid>
  );
};

export default SearchHistory;
