import SelectMD from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { InputLabel } from "@mui/material";

type Props = {
  items: {
    value: string | number;
    label: string;
  }[];
  label: string;
  value: string | number;
  required?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
  onChange: (value: string | number) => void;
};

const Select = ({ label, value, onChange, items, displayEmpty = true, disabled = false }: Props) => (
  <FormControl fullWidth>
    <InputLabel>{label}</InputLabel>
    <SelectMD
      disabled={disabled}
      MenuProps={{
        disableScrollLock: true,
      }}
      label={label}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      displayEmpty={displayEmpty}
    >
      {items.map((item) => (
        <MenuItem style={{ zIndex: 100000 }} key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </SelectMD>
  </FormControl>
);

export default Select;
