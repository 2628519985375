import { Colors } from "styles";
import { Tag, TagProps } from "ui";

type Props = Omit<TagProps, "backgroundColor" | "color"> & {
  label: string | boolean;
  backgroundColor?: keyof Colors;
  color?: keyof Colors;
};

export const ProfileTag = (props: Props) => {
  const { label, backgroundColor, color } = props;
  const greenTags = ["Full", "External", "Extensive", "true", "Daily", "SOC1", "SOC2"];
  const bgColor = backgroundColor ? backgroundColor : greenTags.includes(label) ? "lightGreen" : "amber";
  const fontColor = color ? color : greenTags.includes(label) ? "green" : "white";
  return <Tag {...props} backgroundColor={bgColor} color={fontColor} />;
};

export default ProfileTag;
