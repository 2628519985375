import React from "react";
import Button from "../Button";

import * as S from "./styled";

type Props = {
  title?: string;
  actionName?: string;
  onAction?: () => void;
};

const ErrorDialog: React.FC<Props> = ({ title, onAction, actionName }) => (
  <S.Wrapper>
    <S.Dialog>
      <S.DialogContent>
        {title ? title : "There was an unexpected error. Please refresh a browser and try again."}
        {onAction ? (
          <Button buttonTheme="cancel" size="medium" onClick={() => onAction()}>
            {actionName}
          </Button>
        ) : null}
      </S.DialogContent>
    </S.Dialog>
  </S.Wrapper>
);

export default ErrorDialog;
