import styled from "styled-components";

export const ProgressBarContainer = styled.div`
  height: 8px;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
`;

export const FilledProgressBar = styled.div<{ percentage: number }>`
  height: 100%;
  width: ${(props) => props.percentage}%;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  transition: width 0.3s ease-in-out;
`;
