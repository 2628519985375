import { EntityName, Metadata, Tags } from "components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  BitcoinIcon,
  Button,
  CheckCircleIcon,
  ContentCopyIcon,
  EthereumIcon,
  Grid,
  StarBorderIcon,
  StarIcon,
  Tag,
} from "ui";
import Address from "./Address";
import RiskSummary from "./RiskSummary";
import { ReportMeta } from "types";
import * as S from "../styled";
import { AppContext } from "contexts";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { PortfolioMenu } from "./PortfolioMenu";
import { NotificationsPopover } from "./NotificationsPopover";
import { EntityOrEventResult, getKnownEntityOrEvent } from "utils/profile";
import { useCopyText } from "hooks/useCopyText";

type Props = Pick<
  ReportMeta,
  "entityName" | "entityTag" | "entitySubTag" | "controllerTag" | "controllerType" | "controllerSubType" | "address"
> & {
  reportType: "btc" | "eth";
  balanceCrypto: number;
  balanceFiat: number;
  highRiskCount: number;
  labels: string[];
  addToPortfolio: (portfolioId: number) => void;
  removeFromPortfolio: (portfolioId: number, address: string) => void;
  isInPortfolio: boolean;
};

export const ReportTitle = ({
  entityName,
  entityTag,
  entitySubTag,
  controllerTag,
  controllerType,
  controllerSubType,
  address,
  reportType,
  highRiskCount,
  labels,
  addToPortfolio,
  removeFromPortfolio,
}: Props) => {
  const { entityTypes, portfolios, events, entities } = useContext(AppContext);

  const { copyText, animate, handleCopy, color } = useCopyText({
    initialText: "Copy",
    successText: "Address Copied",
    resetDelay: 1500,
  });

  const [animateAddToPortfolio, setAnimateToPortfolio] = useState(false);
  const [portfolioButtonText, setPortfolioButtonText] = useState<string>("Added to Portfolio");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isPortfolioMenuOpen, setIsPortfolioMenuOpen] = useState(false);
  const intialPortfolioWithAddress = portfolios.filter((portfolio) =>
    portfolio.data.some((data) => data.addressInfo.address === address),
  );

  const [portfoliosWithAddress, setPortfoliosWithAddress] = useState(intialPortfolioWithAddress);
  const portfolio = portfoliosWithAddress.length > 0 ? "in-portfolio" : "no-portfolio";
  const [portfioButtonStatus, setPortfioButtonStatus] = useState<
    "no-portfolio" | "added-to-portfolio" | "removed-from-portfolio" | "in-portfolio"
  >(portfolio);
  const [addressPortfolioName, setAddressPortfolioName] = useState<string | null>(null);
  const [portolioMenuAnchor, setPortolioMenuAnchor] = useState<null | HTMLElement>(null);
  const anyRiskyTag = entityTypes.some((type) => {
    return (
      [entityTag, entitySubTag, controllerType, controllerSubType].includes(type.name) &&
      ["HIGH", "VERY_HIGH"].includes(type.risk)
    );
  });

  const ownerTags = [];
  if (entityTag) {
    ownerTags.push({ type: entityTag });
  }
  if (entitySubTag) {
    ownerTags.push({ type: entitySubTag });
  }
  const controllerTags = [];
  if (controllerType) {
    controllerTags.push({ type: controllerType });
  }
  if (controllerSubType) {
    controllerTags.push({ type: controllerSubType });
  }

  const knownEntityOrEventOwner = useMemo<EntityOrEventResult | null>(() => {
    return getKnownEntityOrEvent(entityName, events, entities);
  }, [entityName, events.sanctions, events.hacks, entities]);

  const knownEntityOrEventController = useMemo<EntityOrEventResult | null>(() => {
    return getKnownEntityOrEvent(controllerTag, events, entities);
  }, [controllerTag, events.sanctions, events.hacks, entities]);

  // Check if either has a known entity/event or non-"Unknown" name
  const hasNameOrImage = knownEntityOrEventOwner?.type == "entity" || knownEntityOrEventController?.type == "entity";

  const handleAddAddressToPortfolio = (portfolioID: number, portfolioName: string) => {
    setIsPortfolioMenuOpen(false);
    setPortfioButtonStatus("added-to-portfolio");
    const portfolio = portfolios.find((portfolio) => portfolio.id === portfolioID)!;
    const updatedPortfoliosWithAddress = [...portfoliosWithAddress, portfolio];
    setPortfoliosWithAddress(updatedPortfoliosWithAddress);
    addToPortfolio(portfolioID);
    setAddressPortfolioName(portfolioName);
  };

  const handleRemoveAddressFromPortfolio = (portfolioID: number, portfolioName: string) => {
    setIsPortfolioMenuOpen(false);
    setPortfioButtonStatus("removed-from-portfolio");
    const updatedPortfoliosWithAddress = portfoliosWithAddress.filter((portfolio) => portfolio.id !== portfolioID);
    setPortfoliosWithAddress(updatedPortfoliosWithAddress);
    setAddressPortfolioName(portfolioName);
    removeFromPortfolio(portfolioID, address);
  };

  useEffect(() => {
    if (portfioButtonStatus === "added-to-portfolio") {
      setPortfolioButtonText(`Added to "${addressPortfolioName}"`);
      setAnimateToPortfolio(true);
      const timeout = setTimeout(() => {
        setPortfioButtonStatus("in-portfolio");
      }, 1500);

      return () => clearTimeout(timeout);
    }
    if (portfioButtonStatus === "removed-from-portfolio") {
      setPortfolioButtonText(`Removed from "${addressPortfolioName}"`);
      setAnimateToPortfolio(true);
      const timeout = setTimeout(() => {
        if (portfoliosWithAddress.length === 0) {
          setPortfioButtonStatus("no-portfolio");
        } else {
          setPortfioButtonStatus("in-portfolio");
        }
      }, 1500);

      return () => clearTimeout(timeout);
    }

    if (portfioButtonStatus === "in-portfolio" || portfioButtonStatus === "no-portfolio") {
      setPortfolioButtonText("Add to Portfolio");
    }
  }, [portfioButtonStatus]);

  const getPortfolioButtonIcon = () => {
    switch (portfioButtonStatus) {
      case "no-portfolio":
        return <StarBorderIcon />;
      case "in-portfolio":
        return <StarIcon color="primary" />;
      case "added-to-portfolio":
        return <CheckCircleIcon color="success" />;
      case "removed-from-portfolio":
        return <CheckCircleIcon color="warning" />;
    }
  };

  const getPortfolioButtonColor = () => {
    switch (portfioButtonStatus) {
      case "no-portfolio":
        return "secondary";
      case "in-portfolio":
        return "primary";
      case "added-to-portfolio":
        return "success";
      case "removed-from-portfolio":
        return "warning";
    }
  };

  return (
    <Grid item container flexWrap="nowrap" rowGap={5} direction="column">
      <Grid container flexWrap="nowrap">
        <Grid xs="auto" paddingRight={1.5}>
          <S.IconWrapper>{reportType === "btc" ? <BitcoinIcon /> : <EthereumIcon />}</S.IconWrapper>
        </Grid>
        <Grid container direction="column" flexWrap="nowrap" rowGap={1}>
          <Address address={address} truncate={address !== "genesis"} />
          <Grid container columnGap={1}>
            {labels.map((label) => (
              <Tag key={label} label={label} backgroundColor="lightGray" color="darkGray" />
            ))}
          </Grid>
        </Grid>
        <Grid item container flexWrap="nowrap" xs="auto" columnGap={1} alignItems={"center"}>
          <NotificationsPopover address={address} />
          <Grid>
            <CopyToClipboard text={address}>
              <Button
                icon={<ContentCopyIcon />}
                buttonTheme="cancel"
                variant="outlined"
                color={color}
                onClick={handleCopy}
                animateOnChange={animate}
              >
                {copyText}
              </Button>
            </CopyToClipboard>
          </Grid>
          <Grid>
            <Button
              ref={buttonRef}
              icon={getPortfolioButtonIcon()}
              variant="outlined"
              color={getPortfolioButtonColor()}
              animateOnChange={animateAddToPortfolio}
              onClick={(e) => {
                setIsPortfolioMenuOpen(true);
                setPortolioMenuAnchor(e.currentTarget);
              }}
            >
              {portfolioButtonText}
            </Button>
            <PortfolioMenu
              reportAddress={address}
              removeFromPortfolio={(portfolioID: number, portfolioName: string) => {
                handleRemoveAddressFromPortfolio(portfolioID, portfolioName);
              }}
              onAddedToPortfolio={(portfolioID: number, portfolioName: string) => {
                handleAddAddressToPortfolio(portfolioID, portfolioName);
              }}
              open={isPortfolioMenuOpen}
              anchor={portolioMenuAnchor}
              onClose={() => setIsPortfolioMenuOpen(false)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container rowGap={"40px"} direction="column">
        <Grid item>
          <Metadata
            columnGap={6}
            list={[
              {
                title: "Address Owner",
                width: "auto",
                value: (
                  <Grid container columnGap={1} style={{ paddingTop: hasNameOrImage ? 12 : 0 }} alignItems={"center"}>
                    <EntityName name={entityName || "Unknown"} />
                    <Grid>{ownerTags.length > 0 && <Tags tags={ownerTags} short />}</Grid>
                  </Grid>
                ),
              },
              {
                title: "Address Controller",
                width: "auto",
                value: (
                  <Grid container columnGap={1} style={{ paddingTop: hasNameOrImage ? 12 : 0 }} alignItems={"center"}>
                    <EntityName name={controllerTag || "Unknown"} />
                    <Grid>{controllerTags.length > 0 && <Tags tags={controllerTags} short />}</Grid>
                  </Grid>
                ),
              },
            ]}
          />
        </Grid>
        <Grid>
          <RiskSummary highRiskCount={highRiskCount} mediumRiskCount={0} addressRisk={anyRiskyTag} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReportTitle;
