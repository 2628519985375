import styled from "styled-components";
import CheckboxMD from "@mui/material/Checkbox";
import FormControlLabelMD from "@mui/material/FormControlLabel";

export const Checkbox = styled(CheckboxMD)`
  svg {
    color: ${(p) => p.theme.colors.secondary};
  }
`;

export const Label = styled.div`
  color: ${(p) => p.theme.palette.text.primary};
`;

export const FormControlLabel = styled(FormControlLabelMD)``;
