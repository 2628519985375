import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid, CloseIcon, Title, IconButton } from "ui";
import { Grow } from "@mui/material";

type ModalContextType = {
  openModal: (content: React.ReactNode, title?: string, options?: ModalOptions) => void;
  closeModal: () => void;
  isOpen: boolean;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
};

type ModalOptions = {
  fitContent?: boolean;
  onClose?: () => void;
  minLoadingTime?: number; // New option for minimum loading time
};

type ModalState = {
  content: React.ReactNode | null;
  title: string;
  options: ModalOptions;
};

const ModalContext = createContext<ModalContextType>({
  openModal: () => {
    // opens modal
  },
  closeModal: () => {
    // closes modal
  },
  isOpen: false,
  isLoading: false,
  setIsLoading: () => {
    // handles modal loading state
  },
});

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalState, setModalState] = useState<ModalState>({
    content: null,
    title: "",
    options: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [overflow, setOverflow] = useState("hidden");
  const [height, setHeight] = useState<number | string>(0);
  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const loadingTimerRef = useRef<NodeJS.Timeout>();
  const loadingStartTimeRef = useRef<number>(0);

  useEffect(() => {
    setOverflow("hidden");
    if (contentRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      if (modalRef.current && !isLoading) {
        setHeight(contentHeight + 80);
        setTimeout(() => {
          setOverflow("auto");
        }, 650);
      }
    }
  }, [isLoading, modalState.content]);

  // setIsLoading with minimum time logic
  const handleLoading = useCallback(
    (loading: boolean) => {
      const minLoadingTime = 500;

      if (loading) {
        loadingStartTimeRef.current = Date.now();
        setIsLoading(true);
      } else {
        const elapsedTime = Date.now() - loadingStartTimeRef.current;
        const remainingTime = Math.max(0, minLoadingTime - elapsedTime);

        // Clear any existing timer
        if (loadingTimerRef.current) {
          clearTimeout(loadingTimerRef.current);
        }

        // If we haven't met the minimum time, wait before hiding the loader
        if (remainingTime > 0) {
          loadingTimerRef.current = setTimeout(() => {
            setIsLoading(false);
          }, remainingTime);
        } else {
          setIsLoading(false);
        }
      }
    },
    [modalState.options.minLoadingTime],
  );

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    };
  }, []);

  const openModal = useCallback((content: React.ReactNode, title = "", options: ModalOptions = {}) => {
    setModalState({ content, title, options });
  }, []);

  const closeModal = useCallback(() => {
    const { options } = modalState;
    if (options.onClose) {
      options.onClose();
    }
    setHeight(0);
    setModalState({ content: null, title: "", options: {} });
  }, [modalState]);

  const defaultPaperProps = {
    sx: {
      minHeight: "808px",
      width: modalState.options.fitContent ? "auto" : "1160px",
      maxWidth: "90vw",
      borderRadius: 4,
      paddingTop: 10,
      margin: 7,
      maxHeight: "calc(100vh - 56px)",
      transition: "height 0.6s ease",
      height: height,
    },
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        isOpen: !!modalState.content,
        isLoading,
        setIsLoading: handleLoading,
      }}
    >
      {children}
      <Dialog
        ref={modalRef}
        open={!!modalState.content}
        onClose={closeModal}
        disableScrollLock
        TransitionComponent={Grow}
        TransitionProps={{
          timeout: 600,
          onEnter: () => {
            if (contentRef.current) {
              const contentHeight = contentRef.current.scrollHeight + 80;
              setHeight(contentHeight);
            }
          },
          onExited: () => setHeight(0),
        }}
        PaperProps={
          modalState.options.fitContent
            ? {
                sx: { borderRadius: 4 },
              }
            : defaultPaperProps
        }
      >
        <DialogContent
          sx={{
            padding: 0,
            overflowY: overflow,
            scrollbarGutter: "stable",
          }}
        >
          <Grid container justifyContent="space-between" position="absolute" top="2%" width="98%" alignItems={"center"}>
            <Grid item paddingLeft={10}>
              <Title>{modalState.title}</Title>
            </Grid>
            <Grid item>
              <IconButton onClick={closeModal}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid ref={contentRef} sx={{ padding: "0 80px" }}>
            {modalState.content}
          </Grid>
        </DialogContent>
      </Dialog>
    </ModalContext.Provider>
  );
};

export default ModalContext;
