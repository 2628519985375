import { LabelValue } from "components";
import { Sanction } from "types";
import { Grid, Tag } from "ui";
import React from "react";

type Props = {
  sanction: Sanction;
};

export const SanctionItem: React.FC<Props> = ({ sanction }: Props) => (
  <Grid container item direction="column" rowGap={1} padding={2}>
    <Grid item>
      <Tag color="white" backgroundColor="red" label="Sanction" />
    </Grid>
    <Grid>
      <b>{sanction["Subject"]}</b>
    </Grid>
    <Grid container item direction="column">
      <Grid container item>
        <Grid sm={4}>
          <LabelValue labelColor="darkGray" label="Entity Type" value={sanction.Type} valueBold={true} width={250} />
        </Grid>
        <Grid sm={4}>
          <LabelValue label="Date" labelColor="darkGray" value={sanction.Date} valueBold={true} width={250} />
        </Grid>
        <Grid sm={4}>
          <LabelValue
            label="Enforcement Body"
            labelColor="darkGray"
            value={sanction["Enforcement Body"]}
            valueBold={true}
            width={250}
          />
        </Grid>

        {/* <Grid item style={{ textOverflow: "ellipsis" }}>
          {shortenSynopsis(sanction.Synopsis)}
        </Grid> */}
      </Grid>
    </Grid>
  </Grid>
);

export default SanctionItem;
