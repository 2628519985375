import AppContext from "contexts/AppContext";
import { useContext } from "react";

import * as S from "../styled";
import { Table } from "ui";
import getColumns from "./hacksColumns";
import HackDetails from "./HackDetail";
import { useModal } from "contexts/ModalContext";

const Hacks = () => {
  const { events } = useContext(AppContext);
  const { openModal } = useModal();
  const rows = events.hacks;
  const columns = getColumns();

  return (
    <S.EntitiesTableWrapper>
      <Table
        rowHeight={50}
        columns={columns}
        pageSize={10}
        rows={rows}
        pagination
        autoHeight={false}
        onRowClick={(params) => {
          openModal(<HackDetails hackId={params.id} />);
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "Date", sort: "desc" }],
          },
        }}
      />
    </S.EntitiesTableWrapper>
  );
};

export default Hacks;
