import styled from "styled-components";

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  padding: 32px 24px;
  cursor: pointer;

  img {
    width: 108px;
    height: 56px;
    float: left;
    margin-right: 80px;
  }
`;

export const Profile = styled.div``;
