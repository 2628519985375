import User from "types/User";
import { Grid, Select, IconButton, DeleteIcon, EditIcon, AddIcon, FileDownloadIcon, Divider } from "ui";
import { Report as EthReport } from "types/ethereum";
import { Report } from "types";
import portfolioToXLS from "./exportToExcel";

import type { Portfolio, Transaction } from "types";
import { useContext } from "react";
import AppContext from "contexts/AppContext";

type Props = {
  user: User;
  highRiskTransactions: Transaction[];
  activePortfolio: Portfolio | null | undefined;
  portfolios: Portfolio[];
  portfolioData: (Report | EthReport)[];
  onCreatePortfolio: () => void;
  onRenamePortfolio: () => void;
  onDeletePortfolio: () => void;
  onPortfolioChange: (id: number) => void;
  onExportToPdf: () => void;
};

export const PortfolioActionBar = ({
  highRiskTransactions,
  portfolios,
  activePortfolio,
  onCreatePortfolio,
  onRenamePortfolio,
  onDeletePortfolio,
  onPortfolioChange,
  portfolioData,
}: Props) => {
  const { entityTypes } = useContext(AppContext);
  return (
    <Grid container item marginTop="16px" alignItems="center">
      <Grid item xs={3} marginRight={2}>
        <Select
          disabled={portfolios.length === 0}
          displayEmpty={false}
          items={portfolios.map((portfolio) => {
            return {
              label: portfolio.name,
              value: portfolio.id,
            };
          })}
          label={portfolios.length === 0 ? "No portfolios" : "Portfolio"}
          onChange={(portfolioId: number | string) => {
            onPortfolioChange(portfolioId as number);
          }}
          value={activePortfolio ? activePortfolio.id : ""}
        ></Select>
      </Grid>
      <Grid item>
        <IconButton onClick={() => onCreatePortfolio()} tooltip={"Create New Portfolio"}>
          <AddIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={() => onRenamePortfolio()} tooltip="Edit">
          <EditIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={() => onDeletePortfolio()} tooltip="Delete" disabled={!activePortfolio}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Divider sx={{ height: "24px" }} orientation="vertical" />
      <Grid item>
        <IconButton
          disabled={!activePortfolio}
          tooltip="Download as Excel"
          onClick={() =>
            activePortfolio
              ? portfolioToXLS({ ...activePortfolio, data: portfolioData }, highRiskTransactions, entityTypes)
              : null
          }
          text="Excel"
        >
          <FileDownloadIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default PortfolioActionBar;
