import { Footer } from "components";
import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Grid } from "ui";
import * as S from "./styled";

const DetailLayout: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <S.Header />
      <Grid container flex={1} marginTop="108px" justifyContent="center">
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          marginTop={5}
          paddingTop={5}
          paddingRight={4}
          paddingLeft={4}
          sx={{ minHeight: "900px", width: "1000px" }}
        >
          <Outlet />
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default DetailLayout;
