import styled from "styled-components";

export const SearchButton = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-right: 2px;
  }

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const IconWrapper = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;
