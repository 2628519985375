import { useContext, useEffect, useState } from "react";
import { Box, FileDownloadIcon, Grid, PopUpDialog } from "ui";
import TransactionTable from "./TransactionTable";
import Tabs from "../../Tabs";
import { LabelValue } from "components";
import { formatCryptoCurrencyValue, formatDate, formatFiatValue } from "utils";
import axios from "axios";
import { useErrorHandler } from "hooks";
import { Transaction } from "../../../../types";
import AuthContext from "contexts/Auth0Context";

type Props = {
  counterPartyAddress: string[];
  originAddress: string;
  originEntityName: string;
  counterPartyName: string;
  defaultTab: "Sources" | "Destinations";
  coin: "btc" | "eth" | "erc20";
  tokenAddress?: string;
  summary: {
    txCount: {
      in: number;
      out: number;
    };
    amount: {
      in: number;
      out: number;
    };
    value: {
      in: number;
      out: number;
    };
    firstDate: string;
    lastDate: string;
  };
  onClose: () => void;
};

export const TransactionsList = ({
  originAddress,
  counterPartyAddress,
  originEntityName,
  counterPartyName,
  defaultTab,
  coin,
  tokenAddress,
  summary,
  onClose,
}: Props) => {
  const tabs = ["Sources", "Destinations", "All"];
  const tabsOrder: Array<"in" | "out" | "all"> = ["in", "out", "all"];
  const [directionTab, setDirectionTab] = useState(tabs.indexOf(defaultTab));
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const handleError = useErrorHandler();
  const { user } = useContext(AuthContext);

  const loadTransactions = async (
    originAddress: string,
    counterPartyAddresses: string[],
    direction: "in" | "out" | "all",
    page: number,
    type: "btc" | "eth" | "erc20",
    tokenAddress?: string,
  ) => {
    setLoading(true);
    try {
      const response = await axios.post<Transaction[]>("/api/reports/eth/txs", {
        originAddress,
        counterPartyAddresses,
        direction,
        page,
        type,
        tokenAddress,
      });
      if (type === "erc20") {
        setTransactions(
          response.data.map((tx: Transaction) => {
            return {
              ...tx,
            };
          }),
        );
      } else {
        setTransactions(response.data);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDirectionTabChange = (newTabIndex: number) => {
    setDirectionTab(newTabIndex);
    loadTransactions(originAddress, counterPartyAddress, tabsOrder[newTabIndex], 0, coin, tokenAddress);
  };

  useEffect(() => {
    loadTransactions(originAddress, counterPartyAddress, tabsOrder[directionTab], 0, coin, tokenAddress);
  }, []);

  const transactionForTable = transactions;

  const getAmount = () => {
    if (directionTab === 0) {
      return summary.amount.in;
    } else if (directionTab === 1) {
      return summary.amount.out;
    } else {
      return summary.amount.in + summary.amount.out;
    }
  };

  const getValue = () => {
    if (directionTab === 0) {
      return summary.value.in;
    } else if (directionTab === 1) {
      return summary.value.out;
    } else {
      return summary.value.in + summary.value.out;
    }
  };

  const getRowCount = () => {
    if (directionTab === 0) {
      return summary.txCount.in;
    } else if (directionTab === 1) {
      return summary.txCount.out;
    } else {
      return summary.txCount.in + summary.txCount.out;
    }
  };

  const downloadTransactions = async () => {
    // Download API which downloas zip file
    const newTabIndex = directionTab;
    const direction = tabsOrder[newTabIndex];
    const res = await axios.post(
      `/api/reports/eth/${originAddress}/txs/export/`,
      {
        type: coin,
        direction,
        tokenAddress,
        counterPartyAddresses: counterPartyAddress.length > 0 ? counterPartyAddress.join(",") : undefined,
      },
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "transactions.zip");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <PopUpDialog title={`Transactions with ${counterPartyName}`} onCancel={() => onClose()}>
      <Box width={1200} height={650}>
        <Grid container rowSpacing={2} direction="column">
          <Grid item>
            <Tabs labels={tabs} activeTabIndex={directionTab} onTabChange={handleDirectionTabChange} />
          </Grid>
          <Grid container item columnSpacing={2} flexWrap="nowrap">
            <Grid item>
              <LabelValue
                value={formatCryptoCurrencyValue(getAmount(), coin) || "0.000"}
                label="Total Amount:"
                direction="row"
                justifyContent="flex-start"
              />
            </Grid>
            <Grid item>
              <LabelValue
                value={formatFiatValue(getValue(), coin)}
                label="Total Value ($):"
                direction="row"
                justifyContent="flex-start"
              />
            </Grid>
            <Grid item>
              {summary.firstDate && (
                <LabelValue
                  value={formatDate(summary.firstDate)}
                  label="First Transaction:"
                  direction="row"
                  justifyContent="flex-start"
                />
              )}
            </Grid>
            <Grid item>
              {summary.lastDate && (
                <LabelValue
                  value={formatDate(summary.lastDate)}
                  label="Last Transaction:"
                  direction="row"
                  justifyContent="flex-start"
                />
              )}
            </Grid>
            <Grid item>{user.isAdmin && <FileDownloadIcon onClick={downloadTransactions} />}</Grid>
          </Grid>
          <Grid item>
            <TransactionTable
              coin={coin}
              originAddress={originAddress}
              originEntityName={originEntityName}
              transactions={transactionForTable}
              loading={loading}
              rowCount={getRowCount()}
              onPageChange={(number: number) => {
                loadTransactions(
                  originAddress,
                  counterPartyAddress,
                  tabsOrder[directionTab],
                  number,
                  coin,
                  tokenAddress,
                );
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </PopUpDialog>
  );
};

export default TransactionsList;
