import styled from "styled-components";
import React from "react";

type Props = {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  variant: "primary" | "secondary";
};
export const Text = styled.div<Props>`
  font-size: 14px;
  font-weight: 600;
  color: ${(p) => (p.variant === "primary" ? p.theme.colors.primary : "gray")};
  cursor: pointer;
`;
