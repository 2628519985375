import { useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import AppContext from "contexts/AppContext";
import { Entity } from "types";
import EntityProfile from "../EntityProfile";
import * as S from "./styled";
import { HackDetail, SanctionDetail } from "pages";
import type { Hack, Sanction } from "types/Events";
import { Avatar } from "ui";
import ProfilePopup from "components/ProfilePopup";
import { EntityOrEventResult, EntityOrEventType, getKnownEntityOrEvent } from "utils/profile";
import { useModal } from "contexts/ModalContext";

type Props = {
  name?: string;
  showImage?: boolean;
};

const EntityName = ({ name, showImage = true }: Props) => {
  const { entities, events } = useContext(AppContext);
  const { openModal } = useModal();

  const theme = useTheme();

  const showDetail = (item: Hack | Sanction | Entity, type: EntityOrEventType) => {
    switch (type) {
      case "sanction":
        return openModal(<SanctionDetail sanctionId={item.id} />);
      case "hack":
        return openModal(<HackDetail hackId={item.id} />);
      case "entity":
        return openModal(<EntityProfile entity={item as Entity} />);
    }
  };

  const knownEntityOrEvent = useMemo<EntityOrEventResult | null>(() => {
    return getKnownEntityOrEvent(name, events, entities);
  }, [name, events.sanctions, events.hacks, entities]);

  if (!knownEntityOrEvent) {
    return <S.EntityName>{name || "Unknown"}</S.EntityName>;
  }
  const profileType = knownEntityOrEvent.type === "entity" ? "entity" : "event";

  const renderContent = () => (
    <S.EntityName>
      <S.MuiLink
        onClick={() => showDetail(knownEntityOrEvent.item, knownEntityOrEvent.type)}
        color={theme.colors.black}
        isUnderLine={true}
      >
        {showImage && profileType == "entity" && (
          <Avatar src={`/images/entities/${name}.png`} size="average" shape="circle" />
        )}
        <strong>{name || "Unknown"}</strong>
      </S.MuiLink>
    </S.EntityName>
  );
  // * * Event used to test events PopUp with
  // const hackEvent = events.hacks[5];
  return <ProfilePopup data={knownEntityOrEvent.item} type={profileType} content={renderContent()} />;
};

export default EntityName;
