import styled from "styled-components";

export const LinkIcon = styled.div`
  svg {
    path {
      transition: fill 1s;
      fill: ${(p) => p.theme.colors.primary};
    }
  }
`;

export const UnlinkIcon = styled.div`
  svg {
    path {
      transition: fill 1s;
      fill: ${(p) => p.theme.colors.secondary};
    }
  }
`;
