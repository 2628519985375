import React, { useState, useEffect } from "react";
import { Autocomplete, Grid } from "ui";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Entity } from "types";
import { useErrorHandler } from "hooks";
type Props = {
  label: string;
  name: string;
  value: string;
  onEntitySelected: (entityName: string) => void;
};

const EntityField: React.FC<Props> = ({ value, label, onEntitySelected, name }: Props) => {
  const { control, getValues, setValue, watch } = useForm({
    defaultValues: {
      [name]: value,
    },
  });
  const entityWatch = watch(name); // watch the entityName field
  const [entities, setEntities] = useState<Entity[]>([]); // state to store the search results
  const [isLoading, setIsLoading] = useState(false); // state to store the loading status of the search
  const handleError = useErrorHandler();

  const searchEntities = async (query: string) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/entities?q=${query}`);
      const data = response.data;
      setEntities(data);
    } catch (error) {
      handleError("Something when wrong when searching for entities.");
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect hook to debounce the search
  useEffect(() => {
    // set up a timeout to perform the search
    const timeoutId = setTimeout(() => {
      // only perform the search if the query is long enough and there is no pending search and the entityName value has changed
      if (getValues()[name].length >= 2 && !isLoading) {
        searchEntities(getValues()[name]);
      }
    }, 150);

    // clear the timeout when the component is unmounted
    return () => {
      clearTimeout(timeoutId);
    };
  }, [entityWatch]);

  return (
    <Grid container xs={12}>
      <Grid container item xs={12}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Autocomplete
              loading={isLoading}
              options={entities.map((entity) => entity.name)}
              label={label}
              {...field}
              onChange={(value) => {
                onEntitySelected(value);
                setValue(name, value);
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default EntityField;
