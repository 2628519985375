import styled from "styled-components";
import ChipMUI, { ChipProps as ChipPropsMUI } from "@mui/material/Chip";

export type ChipProps = ChipPropsMUI & {
  darkText?: boolean;
  customColor?: string;
  customBackground?: string;
};

export const Chip = styled(ChipMUI).withConfig({
  shouldForwardProp: (prop) => !["customBackground", "customColor"].includes(prop),
})<ChipProps>`
  ${(props) => (props.customBackground ? `background: ${props.customBackground};` : null)}
  ${(props) => (props.customColor ? `color: ${props.customColor};` : null)}
  ${(props) => (props.customColor ? `border-color: ${props.customColor};` : null)}
`;
