type Props = {
  color?: string;
};

const LightBulb = ({ color }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clipPath="url(#clip0_2106_3011)">
      <path d="M11 29H21" stroke={color || "#DA016D"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.8375 20.874C8.64872 19.9454 7.68583 18.7594 7.02118 17.4052C6.35653 16.051 6.00739 14.5638 6 13.0553C5.97 7.63528 10.34 3.12403 15.7588 2.99903C17.8588 2.94816 19.9215 3.5599 21.6542 4.74745C23.3869 5.935 24.7017 7.63805 25.412 9.61496C26.1222 11.5919 26.1919 13.7423 25.6111 15.761C25.0303 17.7797 23.8285 19.5643 22.1763 20.8615C21.8118 21.1441 21.5165 21.5059 21.3127 21.9196C21.1089 22.3333 21.002 22.7879 21 23.249V23.999C21 24.2642 20.8946 24.5186 20.7071 24.7061C20.5196 24.8937 20.2652 24.999 20 24.999H12C11.7348 24.999 11.4804 24.8937 11.2929 24.7061C11.1054 24.5186 11 24.2642 11 23.999V23.249C10.9995 22.7908 10.8946 22.3388 10.6931 21.9272C10.4917 21.5157 10.1991 21.1555 9.8375 20.874Z"
        strokeWidth="1.5"
        stroke={color || "#DA016D"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7C19.5 7.42125 21.5762 9.5 22 12"
        strokeWidth="1.5"
        stroke={color || "#DA016D"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2106_3011">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LightBulb;
