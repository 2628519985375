import { Grid, Button } from "ui";
import { useForm } from "react-hook-form";

import * as S from "./styled";
import { Cluster } from "types";
import { EntityField } from "components";

type Props = {
  cluster: Cluster;
  onSubmit: (data: Cluster) => void;
  onCancel: () => void;
};

export const ClusterForm = ({ cluster, onSubmit, onCancel }: Props) => {
  const { setValue, handleSubmit, watch } = useForm<Cluster>({
    defaultValues: {
      tag: cluster.tag,
      type: cluster.type,
      id: cluster.id,
    },
  });

  const tagWatch = watch("tag");

  return (
    <S.Form onSubmit={handleSubmit(onSubmit)}>
      <Grid item>
        <S.EntitySearchWrapper>
          <EntityField
            value={cluster.tag || ""}
            label="Controller Tag"
            name="tag"
            onEntitySelected={(value) => {
              setValue("tag", value);
            }}
          />
          <Grid container item spacing={2} flexWrap="nowrap">
            <Grid item>
              <Button type="button" size="medium" buttonTheme="cancel" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" size="medium" disabled={!tagWatch}>
                Save
              </Button>
            </Grid>
          </Grid>
        </S.EntitySearchWrapper>
      </Grid>
    </S.Form>
  );
};

export default ClusterForm;
