import * as React from "react";

const useIsOverflow = (ref: React.RefObject<HTMLElement> | null, callback?: (isOverflowing: boolean) => void) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    const checkOverflow = () => {
      const current = ref?.current;

      if (!current) {
        return;
      }

      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    // Check immediately
    checkOverflow();

    // Set up resize observer
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    if (ref?.current) {
      resizeObserver.observe(ref.current);
    }

    // Clean up
    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, callback]);

  return isOverflow;
};

export default useIsOverflow;
