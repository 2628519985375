import styled from "styled-components";
import { Colors } from "styles/variables";

type ScoreProps = {
  color: keyof Colors;
};

export const Scorechange = styled.span<ScoreProps>`
  color: ${({ theme, color }) => theme.colors[color]};
  font-size: 12px;
  font-weight: 600;
`;
