import { useState, useEffect } from "react";

type UseCopyTextOptions = {
  /**
   * Initial text to show on the copy button
   */
  initialText?: string;
  /**
   * Text to show when copy is successful
   */
  successText?: string;
  /**
   * Duration in milliseconds to show the success text
   */
  resetDelay?: number;
};

type ButtonColor = "secondary" | "success";

export const useCopyText = ({
  initialText = "Copy",
  successText = "Copied",
  resetDelay = 1500,
}: UseCopyTextOptions = {}) => {
  const [copyText, setCopyText] = useState(initialText);
  const [animate, setAnimate] = useState(false);
  const [color, setColor] = useState<ButtonColor>("secondary");

  const getCopyColor = (copyText: string) => {
    if (copyText == "Copy") {
      return "secondary";
    } else {
      return "success";
    }
  };

  const handleCopy = () => {
    setCopyText(successText);
    setAnimate(true);
    setColor(getCopyColor(successText));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (copyText === successText) {
      timeout = setTimeout(() => {
        setCopyText(initialText);
        setAnimate(false);
        setColor(getCopyColor(initialText));
      }, resetDelay);
    }
    return () => clearTimeout(timeout);
  }, [copyText, initialText, successText, resetDelay]);

  return {
    copyText,
    animate,
    color,
    handleCopy,
  };
};
