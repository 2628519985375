import { EntityLicense } from "types";
import { Grid } from "ui";
import License from "./License";
import * as S from "./styled";

type Props = {
  licences: EntityLicense[];
  print: boolean;
};

export const Licensing = ({ licences, print }: Props) => (
  <Grid container item columnGap={3} rowGap={3} sm={10} md={12}>
    {licences.length === 0 ? (
      <S.Header>This entity is not licensed or registered with any financial regulators.</S.Header>
    ) : (
      licences.map((licence, index) => <License print={print} key={index} licence={licence} />)
    )}
  </Grid>
);

export default Licensing;
