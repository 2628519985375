import type { EntityTypeRisk } from "types";
import * as S from "./styled";

type Props = {
  value: EntityTypeRisk;
};

export const Flag = ({ value }: Props) => <S.Flag value={value.toLowerCase()} />;

export default Flag;
