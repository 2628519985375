import styled from "styled-components";
import { Divider as DividerUI } from "ui";

export const Menu = styled.div`
  width: 240px;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

export const MenuItem = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  padding: 6px 0px;
  display: flex;
  justify-content: left;
  text-transform: none;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  }
`;

export const MenuIcon = styled.div`
  margin: 0 6px;
  display: flex;
  align-items: center;
`;

export const MenuLabel = styled.div``;

type ActiveMenuFlagProps = {
  isActive: boolean;
  theme: Theme;
};

export const ActiveMenuFlag = styled.div`
  height: 100%;
  width: 6px;
  background-color: ${(props: ActiveMenuFlagProps) => (props.isActive ? props.theme.colors.primary : "")};
  border-radius: 0px 4px 4px 0px;
`;

export const Divider = styled(DividerUI)`
  margin: 15px;
`;
