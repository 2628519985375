import { Colors } from "styles/variables";

type Props = {
  color: keyof Colors;
  percentage: number;
};

import * as S from "./styled";

const ProgressBar = ({ color, percentage }: Props) => {
  return (
    <S.ProgressBarContainer>
      <S.FilledProgressBar color={color} percentage={percentage} />
    </S.ProgressBarContainer>
  );
};

export default ProgressBar;
