// Security constants
const MAX_CELL_LENGTH = 1000;
const MAX_CSV_COLUMNS = 20;
const MAX_PREVIEW_ROWS = 5;

export const trimCell = (content: string): string => {
  return content.trim().slice(0, MAX_CELL_LENGTH);
};

export const parseCSVPreview = (file: File): Promise<{ headers: string[]; rows: string[][] }> => {
  return new Promise((resolve, reject) => {
    if (!file.type && !file.name.toLowerCase().endsWith(".csv")) {
      reject(new Error("Invalid file type. Only CSV files are allowed."));
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const text = event.target?.result as string;

        if (text.includes("=") || text.includes("+") || text.includes("-")) {
          reject(new Error("Potentially dangerous CSV content detected"));
          return;
        }

        const lines = text.split(/\r?\n/).filter((line) => line.trim());

        if (lines.length === 0) {
          reject(new Error("Empty CSV file"));
          return;
        }

        const headers = lines[0]
          .split(",")
          .map((header) => trimCell(header))
          .slice(0, MAX_CSV_COLUMNS);

        const rows = lines.slice(1, MAX_PREVIEW_ROWS + 1).map((line) =>
          line
            .split(",")
            .map((cell) => trimCell(cell))
            .slice(0, MAX_CSV_COLUMNS),
        );

        resolve({ headers, rows });
      } catch (error) {
        reject(new Error("Failed to parse CSV file"));
      }
    };

    reader.onerror = () => reject(new Error("Failed to read file"));
    reader.readAsText(file);
  });
};

// Helper function to format file size
export const formatFileSize = (bytes: number): string => {
  const mb = bytes / (1024 * 1024);
  return mb >= 1 ? `${mb.toFixed(2)} MB` : `${(bytes / 1024).toFixed(2)} KB`;
};

// Helper function to extract size from validation error message
export const extractSizeLimit = (message: string): string => {
  const bytesMatch = message.match(/\d+/);
  if (bytesMatch) {
    const bytes = parseInt(bytesMatch[0]);
    return formatFileSize(bytes);
  }
  return message;
};

// Helper function to format validation error message
export const formatValidationError = (message: string): string => {
  if (message.includes("Validation failed (expected size is less than")) {
    const sizeLimit = extractSizeLimit(message);
    return `File size too large. Maximum allowed size is ${sizeLimit}`;
  }
  return message;
};
