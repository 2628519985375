import { useState, ReactNode } from "react";
import { Grid, DangerIcon, SuccessIcon, Link } from "ui";

type SummaryProps = {
  text: string;
  variant?: "text" | "list";
  limit?: number;
  showAll?: boolean;
};

const Summary = ({ text, variant = "text", limit = 0, showAll = false }: SummaryProps) => {
  const [readMore, setReadMore] = useState<boolean>(false);

  const parseText = (text: string): ReactNode[] => {
    const regex = /\[([^\]]+)\]\((https?:\/\/[^)]+)\)/g;
    const parts: ReactNode[] = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        parts.push(text.slice(lastIndex, match.index));
      }
      parts.push(
        <Link key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer">
          {match[1]}
        </Link>,
      );
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }

    return parts;
  };

  const shouldDisplay = (acc: string | string[], item: string): boolean =>
    readMore ||
    showAll ||
    limit === 0 ||
    (typeof acc === "string" ? acc.length : acc.join("").length) + item.length < limit;

  const renderTextContent = (): ReactNode => {
    const sentences = text.split(".").filter((item) => item.trim().length > 0);
    const displayedText = sentences.reduce((acc, item) => (shouldDisplay(acc, item) ? acc + item + "." : acc), "");
    return displayedText.split("\n").map((item, index) => <p key={index}>{parseText(item)}</p>);
  };

  const renderListContent = (): ReactNode => {
    const items = text.split("\n").filter((item) => item.trim().length > 0);
    const displayedItems = items.reduce((acc: string[], item) => (shouldDisplay(acc, item) ? [...acc, item] : acc), []);

    return displayedItems.map((item, index) => {
      const isPositive =
        item.includes("has not") &&
        (item.includes("penalties") || item.includes("criminal litigation") || item.includes("security breaches"));
      return (
        <Grid item container key={index} flexWrap="nowrap" columnGap={2}>
          <Grid item>{isPositive ? <SuccessIcon color="success" /> : <DangerIcon color="warning" />}</Grid>
          <Grid item>{parseText(item.replace("- ", ""))}</Grid>
        </Grid>
      );
    });
  };

  const content =
    variant === "text" ? (
      renderTextContent()
    ) : (
      <Grid container direction="column" rowGap={3}>
        {renderListContent()}
      </Grid>
    );

  return (
    <Grid item container flexDirection="column">
      <Grid container item rowGap={4}>
        <Grid>{content}</Grid>
        {text.length > limit && !showAll && (
          <Grid>
            <Link onClick={() => setReadMore((prev) => !prev)}>{readMore ? "Read Less" : "Read More"}</Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Summary;
