const UnknownFlag = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 15.2222C20 15.8116 19.7659 16.3768 19.3491 16.7936C18.9324 17.2103 18.3671 17.4444 17.7778 17.4444H2.22222C1.63285 17.4444 1.06762 17.2103 0.650874 16.7936C0.234126 16.3768 0 15.8116 0 15.2222V5.22222C0 4.63285 0.234126 4.06762 0.650874 3.65087C1.06762 3.23413 1.63285 3 2.22222 3H17.7778C18.3671 3 18.9324 3.23413 19.3491 3.65087C19.7659 4.06762 20 4.63285 20 5.22222V15.2222Z"
      fill="#DCDCE0"
    />
    <g clipPath="url(#clip0_2526_9289)">
      <path
        d="M8.1875 8.125C8.1875 7.43555 8.74805 6.875 9.4375 6.875H10.0625C10.752 6.875 11.3125 7.43555 11.3125 8.125V8.19531C11.3125 8.62109 11.0957 9.01758 10.7383 9.24609L9.91406 9.77539C9.67206 9.93099 9.473 10.1449 9.33514 10.3974C9.19727 10.6499 9.12502 10.933 9.125 11.2207V11.25C9.125 11.5957 9.4043 11.875 9.75 11.875C10.0957 11.875 10.375 11.5957 10.375 11.25V11.2227C10.375 11.0625 10.457 10.9141 10.5898 10.8281L11.4141 10.2988C12.1289 9.83789 12.5625 9.04688 12.5625 8.19531V8.125C12.5625 6.74414 11.4434 5.625 10.0625 5.625H9.4375C8.05664 5.625 6.9375 6.74414 6.9375 8.125C6.9375 8.4707 7.2168 8.75 7.5625 8.75C7.9082 8.75 8.1875 8.4707 8.1875 8.125ZM9.75 14.375C9.9572 14.375 10.1559 14.2927 10.3024 14.1462C10.4489 13.9997 10.5312 13.801 10.5312 13.5938C10.5312 13.3865 10.4489 13.1878 10.3024 13.0413C10.1559 12.8948 9.9572 12.8125 9.75 12.8125C9.5428 12.8125 9.34409 12.8948 9.19757 13.0413C9.05106 13.1878 8.96875 13.3865 8.96875 13.5938C8.96875 13.801 9.05106 13.9997 9.19757 14.1462C9.34409 14.2927 9.5428 14.375 9.75 14.375Z"
        fill="#292929"
      />
    </g>
    <defs>
      <clipPath id="clip0_2526_9289">
        <rect width="6.25" height="10" fill="white" transform="translate(6.625 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default UnknownFlag;
