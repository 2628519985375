import { styled } from "@mui/material";

export const CenterGrid = styled("div")`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
`;

export const Container = styled("div")`
  padding: 30px 20px;
  max-width: 550px;
  margin-bottom: 100px;
`;
