const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const mobileS = `(max-width: ${size.mobileS})`;
export const mobileM = `(min-width: ${size.mobileM})`;
export const mobileL = `(min-width: ${size.mobileL})`;
export const tablet = `(min-width: ${size.tablet})`;
export const laptop = `(min-width: ${size.laptop})`;
export const laptopL = `(min-width: ${size.laptopL})`;
export const desktop = `(min-width: ${size.desktop})`;
