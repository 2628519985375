import { IconButton, Grid, LinkIcon, UnlinkIcon, EditIcon } from "ui";

type Props = {
  handleEditMode: (isEditMode: boolean) => void;
  removeAddressFromCluster: () => void;
  isClusterLinked: boolean;
};

export const ClusterActions = ({ handleEditMode, isClusterLinked, removeAddressFromCluster }: Props) => (
  <Grid item>
    {isClusterLinked ? (
      <Grid container>
        <IconButton>
          <EditIcon
            color="primary"
            onClick={() => {
              handleEditMode(true);
            }}
          />
        </IconButton>

        <IconButton
          onClick={() => {
            removeAddressFromCluster();
          }}
        >
          <UnlinkIcon color="primary" />
        </IconButton>
      </Grid>
    ) : (
      <IconButton>
        <LinkIcon
          onClick={() => {
            handleEditMode(true);
          }}
        />
      </IconButton>
    )}
  </Grid>
);

export default ClusterActions;
