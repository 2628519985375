type Props = {
  size?: number;
};

const Ethereum = ({ size }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" width={size || 16} height={size || 16}>
    <ellipse fill="#687ce3" ry="11.99991" rx="11.99991" cy="11.99991" cx="11.99991" />
    <path
      fill="white"
      d="m11.96193,16.05913l-5.00694,-2.95766l5.00626,7.05758l5.01102,-7.05758l-5.01238,2.95766l0.00204,0l-0.00001,0zm0.07615,-12.21819l-5.0083,8.31068l5.00762,2.96038l5.00762,-2.95766l-5.00694,-8.3134z"
    />
  </svg>
);

export default Ethereum;
