import { Divider, Link } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Grid, Label, Loading, Title } from "ui";
import { Metadata, NationFlag, Summary } from "components";
import { Sanction } from "../../../types/Events";
import { TokenTags } from "../TokensTags";
import EventBadge from "components/EventBadge";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useErrorHandler } from "hooks";
import { useModal } from "contexts/ModalContext";

const SanctionDetail = ({ sanctionId }: { sanctionId?: string | number }) => {
  const handleError = useErrorHandler();
  const { setIsLoading, isLoading } = useModal();
  const theme = useTheme();
  const [details, setDetails] = useState<Sanction>();

  const params = useParams();

  const sanctionIDToLoad = sanctionId || params.sanctionId;

  useEffect(() => {
    const fetchHackDetails = async () => {
      setIsLoading(true);

      try {
        const { data } = await axios.get(`/api/entities/sanctions/${sanctionIDToLoad}`);
        setDetails(data);
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (sanctionIDToLoad) {
      fetchHackDetails();
    }

    return () => {
      setIsLoading(false);
    };
  }, [sanctionIDToLoad]);
  if (isLoading || !details) {
    return <Loading />;
  }
  return (
    <Grid container direction="column" rowGap={3} paddingBottom={8}>
      <Grid container rowGap={2} direction="column">
        <Grid container justifyContent={"space-between"}>
          <EventBadge type={"Sanction"} theme={theme} />
        </Grid>
        <Grid container rowGap={1}>
          <Title>{details?.["Subject"]}</Title>
          <Label fontWeight={600}>
            {details &&
              new Date(details.Date).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </Label>
        </Grid>
      </Grid>
      <Grid container>
        <Metadata
          spacing={3}
          list={[
            {
              title: "Type",
              value: details?.["Type"],
            },
            {
              title: "Sub-Type",
              value: details?.["Sub-Type"],
            },

            {
              title: "Jurisdiction",
              value: details?.["Jurisdiction"] && (
                <>
                  {details?.["Jurisdiction"].map((jurisdiction) => (
                    <Grid item container flexWrap="nowrap" key={jurisdiction} columnGap={1}>
                      <Grid item>
                        <NationFlag countryName={jurisdiction} />
                      </Grid>
                      <Grid item>{jurisdiction}</Grid>
                    </Grid>
                  ))}
                </>
              ),
            },
            {
              title: "Enforcement Body",
              value: details?.["Enforcement Body"],
            },
          ]}
        />
      </Grid>
      <Grid container>
        <Grid container direction="column">
          <Grid>
            <Label>Tokens</Label>
          </Grid>
          <Grid container>
            <TokenTags tokens={details?.Tokens} />
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      <Summary text={details?.Synopsis ?? ""} showAll={true} />
      {details?.Link && (
        <Link href={details?.Link} target="_blank">
          View Source
        </Link>
      )}
    </Grid>
  );
};

export default SanctionDetail;
