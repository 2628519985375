import EntityPopup, { EntityFormType } from "pages/Address/Metadata/CreateEntity";
import { useState } from "react";
import { EditIcon, Grid, IconButton, Label } from "ui";

import type { MetaData } from "./MetadataForm";
type Props = {
  metadata: MetaData;
  onEditMode: () => void;
  onDeleteMetadata: () => void;
  onCreateEntity: (entity: EntityFormType) => void;
  onUpdateEntity: (entity: EntityFormType) => void;
};

export const MetadataDetail = ({ metadata, onCreateEntity, onUpdateEntity }: Props) => {
  const { ownerTag, ownerType, ownerSubType, controllerTag, controllerType, controllerSubType, labels = [] } = metadata;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState<string | null>(null);

  const handleEditEntity = (type: "owner" | "controller") => {
    setEntityToEdit(metadata[`${type}Tag`] || null);
    setIsEditOpen(true);
  };

  return (
    <Grid container item xs={12} direction="column" rowSpacing={2}>
      <Grid container item flexWrap="nowrap">
        <Grid item container flexWrap="nowrap">
          <Grid container item direction="column">
            <Grid item>
              <Label>Owner Tag</Label>
            </Grid>
            <Grid item>{ownerTag || "-"}</Grid>
          </Grid>
          <Grid item>
            <IconButton>
              <EditIcon color="primary" onClick={() => handleEditEntity("owner")} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <Label>Owner Type</Label>
          </Grid>
          <Grid item>{ownerType || "-"}</Grid>
        </Grid>
        <Grid container item direction="column">
          <Grid item>
            <Label>Owner Subtype</Label>
          </Grid>
          <Grid item>{ownerSubType || "-"}</Grid>
        </Grid>
      </Grid>

      <Grid container item flexWrap="nowrap">
        <Grid item container flexWrap="nowrap">
          <Grid container item direction="column">
            <Grid item>
              <Label>Controller Tag</Label>
            </Grid>
            <Grid item>{controllerTag || "-"}</Grid>
          </Grid>
          <Grid item>
            <IconButton>
              <EditIcon color="primary" onClick={() => handleEditEntity("controller")} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container item direction="column">
          <Grid item>
            <Label>Controller Type</Label>
          </Grid>
          <Grid item>{controllerType || "-"}</Grid>
        </Grid>

        <Grid container item direction="column">
          <Grid item>
            <Label>Controller Subtype</Label>
          </Grid>
          <Grid item>{controllerSubType || "-"}</Grid>
        </Grid>
      </Grid>

      <Grid container item flexWrap="nowrap">
        {labels.map((label, index) => (
          <Grid key={label} container item direction="column" xs={4}>
            <Grid item>
              <Label>Label {++index}</Label>
            </Grid>
            <Grid item>{label}</Grid>
          </Grid>
        ))}
      </Grid>
      {isEditOpen && (
        <EntityPopup
          onCancel={() => setIsEditOpen(false)}
          onCreate={(entity) => {
            setIsEditOpen(false);
            onCreateEntity(entity);
          }}
          onUpdate={(entity) => {
            setIsEditOpen(false);
            onUpdateEntity(entity);
          }}
          entityName={entityToEdit}
        />
      )}
    </Grid>
  );
};
