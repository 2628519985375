import { Avatar, Grid, Label } from "ui";

type Props = {
  name: string;
  tag: string;
  onClick?: (name: string) => void;
};

export const EntityDetail = ({ name, tag, onClick }: Props) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    flex-wrap="nowrap"
    onClick={() => {
      onClick && onClick(name);
    }}
  >
    <Grid container columnGap={2} xs={6} flexWrap="nowrap">
      <Grid container item width="fit-content">
        <Avatar size="large" src={`/images/entities/${name}.png`} />
      </Grid>
      <Grid container item direction="column">
        <Grid>
          <b>{name}</b>
        </Grid>
        <Grid>
          <Label>{tag}</Label>
        </Grid>
      </Grid>
    </Grid>
    <Grid container item justifyContent="flex-end" xs={6}></Grid>
  </Grid>
);

export default EntityDetail;
