import { BasicTable, Box, Title } from "ui";
import getPorfolioColumns from "./PortfolioTable/columns";
import getHighRiskColumns from "./HighRiskTable/columns";
import { SubTitle } from "../../components/EntityProfile/styled";
import { PDFContainer, PDFWatermark } from "./styled";
import { Report as EthReport } from "types/ethereum";

import type { Report, Transaction } from "types";
type Props = {
  portfolioData: (Report | EthReport)[];
  highRiskTransactions: Transaction[];
  name: string;
};

export const PortfolioDetailPrint = ({ portfolioData, name, highRiskTransactions }: Props) => {
  const portfolioColumns = getPorfolioColumns(() => null, []);
  const highRiskColumns = getHighRiskColumns({ isForPDF: true });

  return (
    <PDFContainer>
      <PDFWatermark id="pageHeader" style={{ textAlign: "right" }}>
        <img src="/images/logo.png" alt="Hoptrail" height="46" />
      </PDFWatermark>
      <Box>
        <Title color="primary">{name}</Title>
      </Box>
      <Box>
        <SubTitle style={{ marginBottom: 8 }}>Client Cryptocurrency Addresses</SubTitle>
        <BasicTable columns={portfolioColumns.slice(0, portfolioColumns.length - 1)} rows={portfolioData} />
      </Box>
      <Box pt={2}>
        <SubTitle style={{ marginBottom: 8 }}>High Risk Transactions</SubTitle>
        <BasicTable
          columns={highRiskColumns}
          rows={highRiskTransactions.sort((a, b) => b.amount - a.amount).slice(0, 100)}
        />
      </Box>
    </PDFContainer>
  );
};

export default PortfolioDetailPrint;
