import { Grid, Input, TextButton } from "ui";
import { useForm } from "react-hook-form";
import React from "react";

type Props = {
  onSavePortfolio: (portfolioName: string) => void;
  existingPortfolioNames: string[];
};

export const AddPortfolioItem = ({ onSavePortfolio, existingPortfolioNames }: Props) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  const { watch, handleSubmit, clearErrors, setError, formState, register } = useForm<{
    name: string;
  }>({
    defaultValues: {
      name: "",
    },
  });
  const nameField = watch("name");

  const validate = () => {
    const nameFieldValue = nameField.replace(/\s/g, "");
    const isNameEmpty = nameFieldValue === "";
    const isNameDuplicate = !!existingPortfolioNames.find((name: string) => {
      return name.toLowerCase() === nameFieldValue.toLowerCase();
    });
    clearErrors();
    if (isNameEmpty) {
      setError("name", {
        message: "Name Is Mandatory",
      });
      return false;
    } else if (isNameDuplicate) {
      setError("name", {
        message: "Portfolio with the name already exists",
      });
      return false;
    }
    return true;
  };

  const submit = () => {
    validate() && onSavePortfolio(nameField);
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container justifyContent="space-between" padding={1}>
        <Grid container item justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <Grid item>
            <Input
              sx={{
                width: "160px",
              }}
              error={!!formState.errors["name"]}
              helperText={formState.errors["name"]?.message}
              {...register("name", {
                required: true,
              })}
              variant="standard"
              value={nameField}
              placeholder="Add Title"
              label=""
              InputProps={{
                disableUnderline: false,
                onKeyDown,
              }}
            />
          </Grid>
          <Grid item>
            <TextButton
              text="Save"
              onClick={() => {
                submit();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddPortfolioItem;
