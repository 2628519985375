import {
  GridActionsCellItem,
  GridCallbackDetails,
  GridInitialState,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  GridToolbarContainer,
  MuiEvent,
  useGridApiContext,
} from "@mui/x-data-grid";
import React from "react";
import * as S from "./styled";
import type { GridColDef, GridColumns, GridColumnHeaderParams, GridSelectionModel } from "@mui/x-data-grid";
import { FileDownloadIcon, IconButton } from "ui";

type Props = {
  rows: any[];
  columns: GridColDef[];
  pageSize?: number;
  rowsPerPageOptions?: number[];
  checkboxSelection?: boolean;
  className?: string;
  autoHeight?: boolean;
  rowHeight?: number;
  onRowClick?: (params: GridRowParams, event: MuiEvent<React.MouseEvent>, details: GridCallbackDetails) => void;
  initialState?: GridInitialState;
  sortModel?: GridSortModel;
  onPageSizeChange?: (newPageSize: number) => void;
  pagination?: boolean;
  getRowClassName?: (params: GridRowParams) => string;
  fontSize?: "small" | "normal";
  allowExport?: boolean;
  paginationMode?: "client" | "server";
  rowCount?: number;
  columnVisibilityModel?: { [column: string]: boolean };
  onPageChange?: (page: number, details: GridCallbackDetails) => void;
  loading?: boolean;
  hideFooter?: boolean;
  onSelectionModelChange?: (selectionModel: GridSelectionModel) => void;
  selectionModel?: string[];
};

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleExport = () => apiRef.current.exportDataAsCsv();

  return (
    <GridToolbarContainer>
      <IconButton onClick={handleExport}>
        <FileDownloadIcon />
      </IconButton>
    </GridToolbarContainer>
  );
};

const Table = ({
  rows,
  columns,
  pageSize = 10,
  rowsPerPageOptions,
  checkboxSelection = false,
  className,
  rowHeight = 40,
  onRowClick,
  initialState,
  columnVisibilityModel,
  autoHeight = true,
  pagination = false,
  sortModel,
  onPageSizeChange,
  getRowClassName,
  allowExport = false,
  paginationMode = "client",
  fontSize = "normal",
  rowCount,
  onPageChange,
  hideFooter = false,
  loading = false,
  onSelectionModelChange,
  selectionModel,
}: Props) => (
  <S.DataGrid
    rows={rows}
    columnVisibilityModel={columnVisibilityModel}
    columns={columns.map((column) => {
      if (!column.renderCell) return column;

      return {
        ...column,
        renderCell: (params: any) => {
          if (params.value === null || params.value === "") {
            return <span style={{ textAlign: "center", fontSize: "20px" }}>-</span>;
          } else {
            return column.renderCell ? column.renderCell(params) : params.value;
          }
        },
      };
    })}
    components={
      allowExport
        ? {
            Toolbar: CustomToolbar,
          }
        : {}
    }
    disableColumnSelector
    disableColumnFilter
    disableColumnMenu
    hideFooter={hideFooter}
    pageSize={pageSize}
    rowsPerPageOptions={rowsPerPageOptions || []}
    checkboxSelection={checkboxSelection}
    className={className}
    autoHeight={autoHeight}
    rowHeight={rowHeight}
    disableSelectionOnClick={false}
    onRowClick={onRowClick}
    initialState={initialState}
    sortModel={sortModel}
    sortingOrder={["asc", "desc"]}
    onPageSizeChange={onPageSizeChange || undefined}
    pagination={pagination || undefined}
    getRowClassName={getRowClassName}
    fontSize={fontSize}
    paginationMode={paginationMode}
    rowCount={rowCount}
    loading={loading}
    onPageChange={onPageChange}
    onSelectionModelChange={onSelectionModelChange}
    selectionModel={selectionModel}
    //https://github.com/mui/mui-x/issues/4393#issuecomment-1092580029
    sx={{
      "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
        display: "none",
      },
    }}
  />
);

export default Table;
export type Columns = GridColumns;
export type Cell<T> = GridRenderCellParams<T>;
export type RowParams = GridRowParams;
export type ColumnHeaderParams = GridColumnHeaderParams;
export { GridActionsCellItem as ActionCell };
