import { Tooltip, IconButton } from "@mui/material";
import { useState } from "react";
import { Grid } from "ui";
import * as S from "./styled";
type Props = {
  text: string;
  hideText?: boolean;
  alwaysVisible?: boolean;
};

export const Clipboard = ({ text, hideText = false, alwaysVisible = false }: Props) => {
  const [toolTipText, setToolTipText] = useState<string>("Copy");
  const [clipboardVisible, setClipBoardVisible] = useState(alwaysVisible);

  return (
    <Grid
      container
      alignItems="flex-end"
      flexWrap="nowrap"
      columnSpacing={1}
      onMouseEnter={() => setClipBoardVisible(true)}
      onMouseLeave={() => {
        !alwaysVisible && setClipBoardVisible(false);
        setToolTipText("Copy");
      }}
    >
      <Grid item>
        <S.ClipboardIconWrapper>{!hideText && text}</S.ClipboardIconWrapper>
      </Grid>
      <Grid item container justifyContent="end">
        <Tooltip placement="right" title={toolTipText}>
          <IconButton
            size="small"
            style={{ padding: "0px" }}
            onClick={() => {
              navigator.clipboard.writeText(text);
              setToolTipText("Copied!");
            }}
          >
            <S.ClipboardIcon $visible={clipboardVisible} />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default Clipboard;
