import styled from "styled-components";
import { Chip as UIChip } from "ui";
import { getTextColorByContrast } from "utils";

export const EntitiesTableWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  height: 660px;
  width: 100%;

  & .MuiTable-root {
    table-layout: fixed;
    min-width: 1192px;
  }

  & .MuiTableCell-head,
  .MuiTableCell-body {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .MuiDataGrid-cell {
    font-size: 14px;
  }
  & .MuiDataGrid-columnHeaderTitle {
    color: ${(p) => p.theme.colors.secondary};
    padding: 15px 0 15px 5px;
    font-size: 14px;
  }

  & .MuiTableCell-head .Mui-active {
    color: ${(p) => p.theme.colors.primary};
  }

  & .MuiTableCell-body {
    padding: 10px 26px 10px 5px;
  }

  & .MuiTableCell-body,
  & .MuiTableCell-head {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }
`;

export const Chip = styled(UIChip)<{ backgroundColor: string }>`
  background: ${(p) => p.backgroundColor};
  color: ${(p) => (p.backgroundColor ? getTextColorByContrast(p.backgroundColor) : null)};
`;
