import { Avatar, Box, Chip, Grid } from "ui";

type Props = {
  tag: string;
  isNew: boolean;
};

const EntityTag = ({ tag = "Unknown", isNew = false }: Props) => (
  <Grid container columnGap={1} wrap="nowrap">
    <Avatar src={`/images/entities/${tag.trim()}.png`} size="small"></Avatar>
    <Grid container item overflow="hidden" textOverflow="ellipsis" flexWrap="nowrap" columnGap={1} alignItems="center">
      <Box
        sx={{ fontSize: "14px" }}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
        paddingRight={2}
        width="100%"
      >
        <b>{tag}</b>
      </Box>
      <Grid item>
        {isNew && (
          <Chip
            label=" NEW"
            sx={(theme) => ({
              borderRadius: "4px",
              backgroundColor: "white",
              border: `1px solid ${theme.colors.primary}`,
              color: theme.colors.primary,
              fontWeight: "bold",
              padding: "1px 0px 1px 0px",
              height: "20px",
              fontSize: "12px",
            })}
            size="small"
          />
        )}
      </Grid>
    </Grid>
  </Grid>
);

export default EntityTag;
