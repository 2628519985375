import { DangerIcon } from "ui";
import { PriorityHigh } from "@mui/icons-material";
import React from "react";
import * as S from "./styled";
import { EntityCompliance, PrivacyToken } from "types";

export type CheckmarkIcon = "checkmark" | "danger" | "amberDanger" | "important" | "volume" | "none" | "partial";

const Icons: Record<string, JSX.Element> = {
  checkmark: <S.CheckCircle />,
  partial: <S.CheckCircle overrideColor="amber" />,
  danger: <DangerIcon color="primary" />,
  amberDanger: <S.DangerIcon overrideColor="amber" />,
  important: <PriorityHigh color="primary" />,
  none: <S.HighlightOffIcon overrideColor="gray" />,
};

type Props = {
  value: EntityCompliance | PrivacyToken[];
  icon?: keyof typeof Icons;
};

const valueIconsMapping: {
  [key in EntityCompliance | "true" | "false"]: string;
} = {
  Full: "checkmark",
  Partial: "partial",
  External: "checkmark",
  Extensive: "checkmark",
  SOC1: "checkmark",
  SOC2: "checkmark",
  Internal: "partial",
  true: "checkmark", // boolean true as a string
  false: "none", // boolean false as a string
  "": "none",
  Daily: "checkmark",
  Weekly: "partial",
  Monthly: "partial",
  Quaterly: "partial",
  Yearly: "partial",
  "One Time": "partial",
};

const Checkmark: React.FC<Props> = ({ value, icon }) => {
  let iconName;
  if (icon) {
    iconName = icon;
  } else {
    iconName = valueIconsMapping[value as EntityCompliance] || "none";
  }
  return iconName ? Icons[iconName] : <DangerIcon color="primary" />;
};

export default Checkmark;
