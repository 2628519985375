import { ConfirmPopup } from "ui";

type Props = {
  onDelete: () => void;
  onCancel: () => void;
};

export const DeleteMetadata = ({ onDelete, onCancel }: Props) => (
  <ConfirmPopup title="Delete Metadata" confirmText="Delete" onCancel={() => onCancel()} onConfirm={onDelete}>
    Do you really want to delete metadata for the address?
  </ConfirmPopup>
);

export default DeleteMetadata;
