import { Entity, Hack, Sanction } from "types";

export type EntityOrEventType = "sanction" | "hack" | "entity";

export type EntityOrEventResult = {
  item: Hack | Sanction | Entity;
  type: EntityOrEventType;
} | null;

export const getKnownEntityOrEvent = (
  name: string | undefined,
  events: { sanctions: Sanction[]; hacks: Hack[] },
  entities: Entity[],
): EntityOrEventResult => {
  if (!name) return null;

  const knownSanctionsEvent = events.sanctions.find((sanction) => {
    const sanctionsTagsProperty = sanction["On-Chain Tags"];
    if (!sanctionsTagsProperty) {
      return false;
    }
    const sanctionsTags = sanctionsTagsProperty.split(";").map((tag: string) => tag.trim());
    return sanctionsTags.includes(name);
  });

  if (knownSanctionsEvent) {
    return { item: knownSanctionsEvent, type: "sanction" };
  }

  const knownHackEvent = events.hacks.find((hack) => hack["Event Description"] === name);

  if (knownHackEvent) {
    return { item: knownHackEvent, type: "hack" };
  }

  const knowEntity = entities.find((entity) => entity.name === name);
  if (knowEntity) {
    return { item: knowEntity, type: "entity" };
  }

  return null;
};
