import { Grid } from "ui";
import { useNavigate } from "react-router-dom";

type Props = {
  variant: "default" | "small";
};

export const Logo = ({ variant }: Props) => {
  const navigate = useNavigate();

  return (
    <Grid container alignItems="flex-end" width="fit-content" sx={{ cursor: "pointer" }}>
      {variant === "default" ? (
        <Grid item xs={12}>
          <img src="/images/logo.png" alt="Hoptrail" height="56" />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <img src="/images/logo-small.png" alt="Hoptrail" height="46" onClick={() => navigate("/")} />
        </Grid>
      )}
    </Grid>
  );
};

export default Logo;
