import { useEffect, useState } from "react";
import { AddIcon, Autocomplete, Grid, IconButton } from "ui";
import { Chip, ChipProps, Stack } from "@mui/material";

type Props = {
  defaultValue?: string[];
  options?: string[];
  onChange?: (value: string[]) => void;
};

export default function DeletableChips({ label, onDelete }: { label: string; onDelete?: ChipProps["onDelete"] }) {
  return <Chip label={label} variant="outlined" onDelete={onDelete} />;
}

export const LabelsEditor = ({ options = [], defaultValue = [], onChange }: Props) => {
  const [labels, setLabels] = useState<string[]>(defaultValue);
  const [label, setLabel] = useState<string>("");
  const MAX_LABELS = 3;

  const handleAddLabel = (pickedLabel?: string) => {
    const labelToAdd = pickedLabel || label;
    if (labels.length === MAX_LABELS) {
      return;
    }
    labelToAdd && !labels.includes(labelToAdd) && setLabels((prev) => [...prev, labelToAdd]);
    setLabel("");
  };

  const handleRemoveLabel = (label: string) => {
    const indexOfLabel = labels.indexOf(label);
    indexOfLabel !== undefined &&
      setLabels((prev) => {
        prev.splice(indexOfLabel, 1);
        return [...prev];
      });
  };

  useEffect(() => {
    onChange && onChange(labels);
  }, [labels]);

  return (
    <Grid container direction="column" rowSpacing={2}>
      <Grid container item flexWrap="nowrap" justifyContent="center" alignItems="center">
        <Autocomplete
          label="Label"
          allowFreeText
          options={options}
          value={label}
          onChange={(value) => {
            setLabel(value);
          }}
        />
        <Grid item>
          <IconButton>
            <AddIcon onClick={() => handleAddLabel()} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item>
        <Stack direction="row" spacing={1}>
          {labels.map((item) => (
            <DeletableChips key={item} label={item} onDelete={handleRemoveLabel.bind(null, item)} />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};
