import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import { Paper, Popover, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconButton, Box, Grid, Label, Divider, Subtitle } from "ui";
import { Link } from "react-router-dom";
import axios from "axios";
import { useErrorHandler } from "hooks";

type Props = {
  entityID: string;
};

export const NotificationsPopover = ({ entityID }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isWatched, setIsWatched] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const handleError = useErrorHandler();
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    const fetchServerState = async () => {
      try {
        const response = await axios.get(`/api/notifications/entities/${entityID}`);
        setIsWatched(response.data);
      } catch (error) {
        handleError(error);
      }
    };
    fetchServerState();
  }, [entityID]);

  const handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsWatched(e.target.checked);
      await axios.post("/api/notifications/entities", { entity_id: entityID, enable: e.target.checked });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && !isWatched ? (
          <NotificationAddOutlinedIcon />
        ) : isWatched ? (
          <NotificationsActiveIcon color="primary" />
        ) : (
          <NotificationsOffOutlinedIcon />
        )}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          marginTop: "10px",
        }}
      >
        <Paper>
          <Box p={2}>
            <Subtitle>Set Alert</Subtitle>
            <Typography pb={1}>
              Send me an <strong>email</strong> notification if this profile:
            </Typography>
            <Grid container direction={"row"} alignItems={"center"}>
              AML score changes
              <Box ml={"auto"}>
                <Switch checked={isWatched} onChange={handleSwitchChange} />
              </Box>
            </Grid>
          </Box>
          <Divider />
          <Box p={2}>
            <Link to={"/alerts"}>
              <Label>Manage all alerts</Label>
            </Link>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
export default NotificationsPopover;
