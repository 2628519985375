import styled from "styled-components";
import { Chip as UIChip } from "ui";
import type { EntityTypeRisk } from "types";
import { default as ErrorIconUI } from "@mui/icons-material/Error";
type ChipProps = {
  $profile: EntityTypeRisk;
  theme: Theme;
  $noColor?: boolean;
  label?: string;
};

type DangerIconProps = {
  theme: Theme;
  $noColor?: boolean;
};

const getChipStyles = ({ theme }: ChipProps) => {
  return {
    background: "none",
    color: theme.colors.darkGray,
    fontWeight: "400 !important",
    fontSize: "14px",
    gap: "6px",
  };
};

export const Chip = styled(UIChip)`
  ${(props: ChipProps) => getChipStyles(props)};
  font-weight: bold;
  height: 24px;
  span {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const ErrorIcon = styled(ErrorIconUI)<DangerIconProps>`
  fill: ${(props) => (props.$noColor ? props.theme.colors.darkGray : (props.theme.colors.red as string))};
`;
