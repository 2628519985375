import styled, { keyframes } from "styled-components";

const show = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const Dialog = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-radius: 8px;
  width: 100%;
  height: 100vh;
  z-index: 1;
  animation: ${show} 0.3s linear;
`;

export const DialogContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.primary};
  ${(p) => p.theme.fonts.h3};
  color: white;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${(p) => p.theme.colors.black};
  opacity: 0.4;
`;

export const Close = styled.div`
  position: absolute;
  top: 45px;
  right: 45px;
  cursor: pointer;
`;
