import { Table, Grid, Subtitle, Columns } from "ui";
import getColumns from "./columns";
import { Report } from "types";
import { AppContext } from "contexts";
import { useContext } from "react";
import { Report as EthReport } from "types/ethereum";

type Props = {
  data: (Report | EthReport)[];
  onAddressDelete: (address: string) => void;
};

const PortfolioTable = ({ data, onAddressDelete }: Props) => {
  const { entityTypes } = useContext(AppContext);
  const columns: Columns = getColumns(onAddressDelete, entityTypes);

  return (
    <Grid container rowSpacing={2} direction="column">
      <Grid item container justifyContent="left">
        <Subtitle>Client Cryptocurrency Addresses</Subtitle>
      </Grid>
      <Grid item>
        <Table
          columns={columns}
          rows={data.map((address, index) => {
            return {
              ...address,
              id: index,
            };
          })}
          initialState={{
            sorting: {
              sortModel: [{ field: "amount", sort: "desc" }],
            },
          }}
          fontSize="small"
        />
      </Grid>
    </Grid>
  );
};
export default PortfolioTable;
