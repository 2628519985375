import styled from "styled-components";
import TabsMUI from "@mui/material/Tabs";

export const Tabs = styled(TabsMUI)`
  .MuiTab-root {
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
  }
`;
