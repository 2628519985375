import styled from "styled-components";
import { Colors } from "styles";
import { Grid, BarChart as BarChartUI } from "../../ui";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 50px 20px 0px;
`;

export const Name = styled.div`
  ${(p) => p.theme.fonts.h2};
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.primary};
  font-size: 34px;
  font-weight: bold;
`;

export const Tag = styled.div`
  margin-right: 30px;
`;

export const Properties = styled.div``;

export const Description = styled.div`
  margin: 40px 0px;
`;

export const EntityInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const titleFontSize = {
  small: "16px",
  medium: "24px",
};

export const InfoLabel = styled.div<{ fontSize: "small" | "medium" }>`
  ${(p) => p.theme.fonts.h3};
  font-size: ${(p) => titleFontSize[p.fontSize]};
  margin-right: 10px;
  width: ${(p) => (p.fontSize === "small" ? "100px" : "200px")};
`;

export const InfoValue = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

export const SubTitle = styled.div`
  ${(p) => p.theme.fonts.h2};
`;

export const EntityParameter = styled(Grid)`
  @media print {
    width: 200px;
  }
`;

export const Section = styled.div<{ hidePrint?: boolean }>`
  ${(p) =>
    p.hidePrint &&
    `
    @media print {
      display: none;
    }  
  `};
`;

export const FormContainer = styled.form`
  min-width: 400px;
`;

export const Rank = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${(p) => p.theme.colors.primary};
  background: ${(p) => p.theme.colors.primary};
  width: 60px;
  border-radius: 5px;
  margin-right: 8px;
  font-weight: bold;
`;

export const EntityProfilePDF = styled(Grid)`
  position: absolute;
  top: 10000px;
  @media print {
    top: 0px;
    @page {
      margin: 20mm;
    }
  }
  padding: 40px;
`;

export const BarChartIcon = styled(BarChartUI)<{
  overrideColor?: keyof Colors;
}>`
  fill: ${(props) => props.theme.colors[props.overrideColor || "green"]};
`;

export const PrintHeader = styled.div`
  @media print {
    display: block;
    position: fixed;
    counter-increment: page;
    width: 100%;
    top: 0; /* Position at the top */
    height: 60px; /* Adjust height as needed to fit the logo */
    padding-right: 10px;
    box-sizing: border-box;

    img {
      position: absolute;
      top: 0;
      right: 40px;
      margin: 10px; /* Add margin to keep it from the edges */
    }
  }
`;
