import { useState } from "react";
import { Box, Grid, PopUpDialog } from "ui";
import TransactionTable from "./TransactionTable";
import Tabs from "../../Tabs";
import { LabelValue } from "components";
import { formatCryptoCurrencyValue, formatDate, formatFiatValue } from "utils";

type Props = {
  transactions: any[];
  originAddress: string;
  originEntityName: string;
  counterPartyName: string;
  defaultTab: "Sources" | "Destinations";
  coin: "btc" | "eth" | "erc20";
  onClose: () => void;
};

export const TransactionsList = ({
  transactions,
  originAddress,
  originEntityName,
  counterPartyName,
  defaultTab,
  coin,
  onClose,
}: Props) => {
  const tabs = ["Sources", "Destinations", "All"];
  const [directionTab, setDirectionTab] = useState(tabs.indexOf(defaultTab));
  const handleDirectionTabChange = (newTab: number) => {
    setDirectionTab(newTab);
  };

  const filterTransactionByDirection = (transactions: any[]) => {
    return transactions
      .filter((tx: any) => {
        if (directionTab === 0) {
          return tx.direction === "in";
        } else if (directionTab === 1) {
          return tx.direction === "out";
        } else {
          return true;
        }
      })
      .map((tx: any) => {
        let fromAddress, toAddress;
        if (tx.direction === "in") {
          fromAddress = tx.address;
          toAddress = originAddress;
        } else {
          fromAddress = originAddress;
          toAddress = tx.address;
        }

        return {
          ...tx,
          sender: fromAddress,
          receiver: toAddress,
        };
      });
  };

  let transactionForTable = filterTransactionByDirection(transactions);
  if (coin === "erc20") {
    transactionForTable = transactionForTable.map((tx: any) => {
      return {
        ...tx,
        symbol: counterPartyName,
      };
    });
  }
  const firstTransaction = transactionForTable.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  )[0];

  const lastTransaction = transactionForTable.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  )[0];

  const { amountSum, valueSum } = transactionForTable.reduce(
    (previousValue, currentValue) => {
      return {
        amountSum: previousValue.amountSum + currentValue.amount,
        valueSum: previousValue.valueSum + currentValue.value,
      };
    },
    {
      amountSum: 0,
      valueSum: 0,
    },
  );

  return (
    <PopUpDialog title={`Transactions with ${counterPartyName}`} onCancel={() => onClose()}>
      <Box width={1200} height={650}>
        <Grid container rowSpacing={2} direction="column">
          <Grid item>
            <Tabs labels={tabs} activeTabIndex={directionTab} onTabChange={handleDirectionTabChange} />
          </Grid>
          <Grid container item columnSpacing={2} flexWrap="nowrap">
            <Grid item>
              <LabelValue
                value={formatCryptoCurrencyValue(amountSum, coin) || "0.000"}
                label="Total Amount:"
                direction="row"
                justifyContent="flex-start"
              />
            </Grid>
            <Grid item>
              <LabelValue
                value={formatFiatValue(valueSum, coin)}
                label="Total Value ($):"
                direction="row"
                justifyContent="flex-start"
              />
            </Grid>
            <Grid item>
              {firstTransaction && (
                <LabelValue
                  value={formatDate(firstTransaction.date)}
                  label="First Transaction:"
                  direction="row"
                  justifyContent="flex-start"
                />
              )}
            </Grid>
            <Grid item>
              {lastTransaction && (
                <LabelValue
                  value={formatDate(lastTransaction.date)}
                  label="Last Transaction:"
                  direction="row"
                  justifyContent="flex-start"
                />
              )}
            </Grid>
          </Grid>
          <Grid item>
            <TransactionTable
              coin={coin}
              originAddress={originAddress}
              originEntityName={originEntityName}
              transactions={transactionForTable}
            />
          </Grid>
        </Grid>
      </Box>
    </PopUpDialog>
  );
};

export default TransactionsList;
