import NotificationAddOutlinedIcon from "@mui/icons-material/NotificationAddOutlined";
import { Paper, Popover, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconButton, Box, Grid, Label, Divider, Subtitle } from "ui";
import { Link } from "react-router-dom";
import axios from "axios";
import { useErrorHandler } from "hooks";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";

type Props = {
  address: string;
};

export const Notifications = ({ address }: Props) => {
  const handleError = useErrorHandler();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const [isWatchedForTransfers, setIsWatchedForTransfers] = useState<boolean>(false);
  const [isWatchedForHighRisk, setIsWatchedForHighRisk] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    const fetchServerState = async (
      address: string,
      type: string,
      handler: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      try {
        const response = await axios.get(`/api/notifications/addresses/${address}/${type}`);
        handler(response.data);
      } catch (error) {
        handleError(error);
      }
    };

    fetchServerState(address, "transfer", setIsWatchedForTransfers);
    fetchServerState(address, "high-risk", setIsWatchedForHighRisk);
  }, []);

  const handleSwitchChange = async (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    try {
      if (type === "transfer") {
        setIsWatchedForTransfers(e.target.checked);
      } else {
        setIsWatchedForHighRisk(e.target.checked);
      }

      await axios.post("/api/notifications/addresses", {
        address: address,
        type: type,
        enable: e.target.checked,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <IconButton
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && !isWatchedForHighRisk && !isWatchedForTransfers ? (
          <NotificationAddOutlinedIcon />
        ) : isWatchedForHighRisk || isWatchedForTransfers ? (
          <NotificationsActiveIcon color="primary" />
        ) : (
          <NotificationsOffOutlinedIcon />
        )}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        disableScrollLock={true}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          marginTop: "10px",
        }}
      >
        <Paper>
          <Box p={2}>
            <Subtitle>Set Alert</Subtitle>
            <Typography pb={1}>Send me an email notification if this profile:</Typography>
            <Grid container direction={"row"} alignItems={"center"}>
              Receives or sends funds
              <Box ml={"auto"}>
                <Switch checked={isWatchedForTransfers} onChange={(e) => handleSwitchChange(e, "transfer")} />
              </Box>
            </Grid>
            <Grid container direction={"row"} alignItems={"center"}>
              Interacts with a high risk wallet
              <Box ml={"auto"}>
                <Switch checked={isWatchedForHighRisk} onChange={(e) => handleSwitchChange(e, "high-risk")} />
              </Box>
            </Grid>
          </Box>
          <Divider />
          <Box p={2}>
            <Link to={"/alerts"}>
              <Label>Manage all alerts</Label>
            </Link>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};
export default Notifications;
