import styled from "styled-components";
import { Table as TableUI, DangerIcon as DangerIconUI, InfoIcon as InfoIconUI, Box as BoxUI } from "ui";
import TabsMUI from "@mui/material/Tabs";

export const DangerIcon = styled(DangerIconUI)`
  fill: ${(props) => props.theme.colors.darkRed as string};
`;

export const IconWrapper = styled.div`
  svg {
    width: 56px;
    height: 56px;
  }
`;

export const RiskScoreLabel = styled.div`
  ${(props) => props.theme.fonts.subTextDarkGray};
  margin-right: 5px;
`;

export const InfoIcon = styled(InfoIconUI)`
  fill: ${(props) => props.theme.colors.darkGray};
  width: 16px;
  height: 16px;
`;

export const AddressInfo = styled(BoxUI)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 24px 120px 32px 32px;
`;

export const Tabs = styled(TabsMUI)`
  .MuiTab-root {
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
  }

  .MuiTabs-flexContainer {
    width: max-content;
  }
`;

export const Table = styled(TableUI)`
  &,
  & .MuiDataGrid-cell,
  & .MuiDataGrid-columnHeaders,
  & .MuiDataGrid-footerContainer {
    border-color: ${(p) => p.theme.colors.gray};
  }
`;
