import { Footer } from "components";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid } from "ui";
import * as S from "./styled";

type Props = {
  children: React.ReactNode;
};
const MainLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/");
    }
  }, []);

  return (
    <Grid
      style={{
        minHeight: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid item>
        <S.Header />
      </Grid>
      <Grid
        style={{
          flexGrow: 1, // This allows the content area to expand
          display: "flex",
          flexDirection: "column", // Stack content and footer
          paddingTop: "108px", // Adjust this value to match the header's height
        }}
      >
        <Grid item container justifyContent="center" height="100%" paddingLeft={4} paddingRight={4} paddingTop={4}>
          {children}
        </Grid>
        <Grid item>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainLayout;
