const VariousIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.99994" cy="10.9766" r="10" fill="#292929" />
    <g clipPath="url(#clip0_2526_9277)">
      <path
        d="M8.7 11.9922C8.7 12.8207 10.043 13.4922 11.7 13.4922C13.357 13.4922 14.7 12.8207 14.7 11.9922C14.7 11.1637 13.357 10.4922 11.7 10.4922C10.043 10.4922 8.7 11.1637 8.7 11.9922Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7 11.9922V13.9922C8.7 14.8202 10.043 15.4922 11.7 15.4922C13.357 15.4922 14.7 14.8202 14.7 13.9922V11.9922"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.7 8C5.7 8.536 6.272 9.031 7.2 9.299C8.128 9.567 9.272 9.567 10.2 9.299C11.128 9.031 11.7 8.536 11.7 8C11.7 7.464 11.128 6.969 10.2 6.701C9.272 6.433 8.128 6.433 7.2 6.701C6.272 6.969 5.7 7.464 5.7 8Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.7 8V13C5.7 13.444 6.086 13.725 6.7 14"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.7 10.4922C5.7 10.9362 6.086 11.2172 6.7 11.4922"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>

    <defs>
      <clipPath id="clip0_2526_9277">
        <rect width="12" height="12" fill="white" transform="translate(4.2 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default VariousIcon;
