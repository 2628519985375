import Logo from "components/Logo";
import { format } from "date-fns";
import React from "react";
import { Grid } from "ui";

export const Footer: React.FC = () => {
  return (
    <Grid
      container
      justifyContent="space-between"
      marginTop="120px"
      padding="0 32px"
      height="72px"
      sx={(theme) => ({ backgroundColor: theme.colors.lightGray })}
    >
      <Grid container item alignItems="center" columnGap={2} xs={6}>
        <Logo variant="small" />
        <Grid item>Hoptrail | {format(new Date(), "yyyy")}</Grid>
      </Grid>
      <Grid container item alignItems="center" columnGap={2} xs={2} justifyContent="flex-end">
        info@hoptrail.io
      </Grid>
    </Grid>
  );
};

export default Footer;
