import { Tag } from "ui";

type Props = {
  licence: string;
};

export const LicenceTag = ({ licence }: Props) => {
  const isRevoked = licence.toLowerCase() === "revoked";
  const backgroundColor = isRevoked ? "lightRed" : "lightGreen";
  const color = isRevoked ? "red" : "green";

  return <Tag label={licence} backgroundColor={backgroundColor} color={color} />;
};

export default LicenceTag;
