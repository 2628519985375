const Notification = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#DA016D" fillOpacity="0.06" />
    <circle cx="24" cy="24.0031" r="16.8" fill="#DA016D" fillOpacity="0.11" />
    <path
      d="M27.634 17.5651C27.75 17.4318 27.9141 17.35 28.0904 17.3376C28.2666 17.3252 28.4406 17.3833 28.574 17.4991C29.4362 18.2476 30.1289 19.1712 30.606 20.2084C30.6799 20.3692 30.687 20.5527 30.6256 20.7186C30.5642 20.8845 30.4394 21.0192 30.2787 21.0931C30.118 21.167 29.9345 21.1741 29.7686 21.1127C29.6027 21.0513 29.4679 20.9265 29.394 20.7658C28.9965 19.9007 28.4193 19.1303 27.7007 18.5058C27.5673 18.3899 27.4853 18.2258 27.4728 18.0496C27.4603 17.8733 27.5183 17.6986 27.634 17.5651Z"
      fill="#DA016D"
    />
    <path
      d="M19.424 17.4994C19.5575 17.3834 19.7316 17.3252 19.908 17.3376C20.0844 17.35 20.2487 17.4319 20.3647 17.5654C20.4806 17.6989 20.5389 17.873 20.5265 18.0494C20.5141 18.2258 20.4322 18.3901 20.2987 18.5061C19.5801 19.1305 19.0028 19.901 18.6053 20.7661C18.5698 20.8472 18.5185 20.9204 18.4543 20.9815C18.3902 21.0425 18.3145 21.0902 18.2318 21.1217C18.149 21.1532 18.0608 21.1679 17.9723 21.1649C17.8838 21.1619 17.7968 21.1413 17.7163 21.1044C17.6358 21.0674 17.5635 21.0147 17.5036 20.9495C17.4437 20.8843 17.3974 20.8078 17.3674 20.7245C17.3374 20.6412 17.3243 20.5527 17.3289 20.4643C17.3334 20.3758 17.3556 20.2892 17.394 20.2094C17.8704 19.1721 18.5625 18.2482 19.424 17.4994Z"
      fill="#DA016D"
    />
    <path
      d="M25.49 28.6719C26.0667 28.6719 26.3714 29.3545 25.9867 29.7839C25.7368 30.0635 25.4307 30.2872 25.0883 30.4402C24.7459 30.5932 24.3751 30.6722 24 30.6719C23.625 30.6722 23.2542 30.5932 22.9118 30.4402C22.5694 30.2872 22.2633 30.0635 22.0134 29.7839C21.6454 29.3732 21.908 28.7312 22.436 28.6765L22.5094 28.6725L25.49 28.6719Z"
      fill="#DA016D"
    />
    <path
      d="M24 17.3359C24.9054 17.3359 25.6707 17.9379 25.9167 18.7633L25.9474 18.8773L25.9527 18.9059C26.6876 19.3205 27.3139 19.9033 27.7802 20.6066C28.2465 21.3099 28.5395 22.1136 28.6354 22.9519L28.654 23.1433L28.6667 23.3359V25.2899L28.6807 25.3806C28.772 25.8718 29.0438 26.3111 29.4427 26.6119L29.554 26.6899L29.662 26.7559C30.2354 27.0806 30.0354 27.9333 29.4107 27.9986L29.3334 28.0026H18.6667C17.9814 28.0026 17.742 27.0933 18.338 26.7559C18.5921 26.6122 18.8115 26.4146 18.9811 26.177C19.1506 25.9394 19.2661 25.6676 19.3194 25.3806L19.3334 25.2853L19.334 23.3053C19.3747 22.4345 19.6282 21.587 20.0722 20.8369C20.5163 20.0868 21.1375 19.457 21.8814 19.0026L22.0467 18.9053L22.0534 18.8766C22.1477 18.478 22.362 18.1178 22.6674 17.8448C22.9729 17.5718 23.3547 17.3991 23.7614 17.3499L23.8827 17.3386L24 17.3359Z"
      fill="#DA016D"
    />
  </svg>
);

export default Notification;
