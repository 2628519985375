import { Grid, Label } from "ui";
import { Cluster } from "types";
import { Tags } from "components";

type Props = {
  cluster: Cluster;
};

export const ClusterDetail = ({ cluster }: Props) => (
  <Grid container item direction="column" rowSpacing={2}>
    <Grid container item direction="column">
      <Grid item>
        <Label>
          <b>Category</b>
        </Label>
      </Grid>
      <Grid item>
        <Tags tags={[{ type: cluster.type }]} />
      </Grid>
    </Grid>
    <Grid container item flexWrap="nowrap">
      <Grid container item direction="column">
        <Grid item>
          <Label>Cluster - ID</Label>
        </Grid>
        <Grid item>{`${cluster.tag || "Unknown"} - ${cluster.id}`}</Grid>
      </Grid>

      <Grid container item direction="column">
        <Grid item>
          <Label>Cluster Size</Label>
        </Grid>
        <Grid item>{cluster.size}</Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default ClusterDetail;
