import { ButtonProps } from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import CSS from "csstype";

export const ButtonThemes = ["primary", "secondary", "cancel"] as const;
type ButtonType = "button" | "submit" | "reset" | undefined;

import * as S from "./styled";
export type Theme = (typeof ButtonThemes)[number];

type Props = {
  id?: string;
  fluid?: boolean;
  type?: ButtonType;
  buttonTheme?: Theme;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  to?: string;
  externalRedirect?: boolean;
  openNewTab?: boolean;
  children: React.ReactNode[] | React.ReactNode | string;
  className?: string;
  style?: CSS.Properties;
  icon?: React.ReactNode;
  size?: "small" | "medium" | "large";
  ref?: React.Ref<HTMLButtonElement>;
  width?: string;
  variant?: "contained" | "outlined" | "text";
  color?: ButtonProps["color"];
  animateOnChange?: boolean; // New prop to trigger animation
  component?: React.ElementType;
};
const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = "contained",
      disabled,
      icon,
      style,
      size = "small",
      color = "primary",
      children,
      onClick,
      animateOnChange,
      type = "button",
      component,
    },
    ref,
  ) => {
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const textRef = useRef<HTMLSpanElement>(null);

    // Effect to trigger animation when animateOnChange prop changes
    useEffect(() => {
      if (textRef.current) {
        const currentTextWidth = textRef.current.scrollWidth;
        if (currentTextWidth > 150 && animateOnChange) {
          setShouldAnimate(true);
        }
      }
    }, [children]);

    useEffect(() => {
      // After the animation duration (1s), reset shouldAnimate to false
      if (shouldAnimate) {
        const animationTimeout = setTimeout(() => {
          setShouldAnimate(false);
        }, 1500);

        return () => clearTimeout(animationTimeout);
      }
    }, [shouldAnimate]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) onClick(e);
    };

    return (
      <S.ButtonUI
        ref={ref}
        shouldAnimate={shouldAnimate}
        color={color}
        variant={variant}
        size={size}
        disabled={disabled}
        startIcon={icon}
        style={style}
        onClick={handleClick}
        type={type}
        component={component}
      >
        <S.Content ref={textRef}>{children}</S.Content>
      </S.ButtonUI>
    );
  },
);

Button.displayName = "Button";
export default Button;
