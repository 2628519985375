import { forwardRef } from "react";

import * as S from "./styled";

const Grid = forwardRef<HTMLDivElement, S.GridPropsExtended>((props, ref) => {
  return <S.StyledGrid ref={ref} {...props} />;
});
Grid.displayName = "Grid";

export default Grid;
