import { styled } from "@mui/material";

import { DataGrid as DataGridMD } from "@mui/x-data-grid";

export const DataGrid = styled(DataGridMD)<{ fontSize: "small" | "normal" }>`
  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    padding-left: 16px;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
    text-transform: uppercase;
    color: black;
  }
  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0;
  }

  font-size: ${(p) => (p.fontSize === "normal" ? undefined : "12.5px")};

  & .MuiChip-root {
    font-size: ${(p) => (p.fontSize === "normal" ? undefined : "12.5px")};
  }
`;
