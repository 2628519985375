type Props = {
  color?: string;
  size?: string;
};

const ArrowLinkForward = ({ color, size }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size ? size : "24"} height={size ? size : "24"} fill="none">
    <mask
      id="mask0_3316_1204"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={size ? size : "25"}
      height={size ? size : "24"}
    >
      <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_3316_1204)">
      <path
        d="M6.79425 17.6443L5.75 16.6L15.5905 6.75H6.64425V5.25H18.1443V16.75H16.6443V7.80375L6.79425 17.6443Z"
        fill={color ? color : "#DA016D"}
      />
    </g>
  </svg>
);

export default ArrowLinkForward;
