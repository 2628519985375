import AppContext from "contexts/AppContext";
import { useContext } from "react";

import * as S from "../styled";
import { Table } from "ui";
import getColumns from "./scamsColumns";
import ScamDetail from "./ScamDetail";
import { useModal } from "contexts/ModalContext";

const Scams = () => {
  const { events } = useContext(AppContext);
  const { openModal } = useModal();
  const rows = events.scams;
  const columns = getColumns();

  return (
    <S.EntitiesTableWrapper>
      <Table
        rowHeight={50}
        columns={columns}
        pageSize={10}
        rows={rows}
        pagination
        autoHeight={false}
        onRowClick={(params) => {
          openModal(<ScamDetail scamId={params.id} />);
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "Start Date", sort: "desc" }],
          },
        }}
      />
    </S.EntitiesTableWrapper>
  );
};

export default Scams;
