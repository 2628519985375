import { Grid } from "ui";
import LabelValue from "../LabelValue";
import * as S from "./styled";

export type MetadataItem = {
  title: string;
  value?: string | number | JSX.Element | string[];
  width?: number | "auto";
};

type MetadataProps = {
  list: MetadataItem[];
  columnGap?: number;
  columns?: number;
  spacing?: number;
};

function Metadata({ list, columnGap, columns = 3, spacing = 2 }: MetadataProps) {
  return (
    <Grid container spacing={spacing} columnGap={columnGap ? columnGap : 0}>
      {list?.map((item) => (
        <S.EntityParameter key={item.title} item xs={item.width ? item.width : 12 / columns}>
          <LabelValue label={item.title} value={item.value} valueBold={true} print={true} />
        </S.EntityParameter>
      ))}
    </Grid>
  );
}

export default Metadata;
