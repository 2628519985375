import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./styled";
import { Badge, Grid } from "@mui/material";

type Props = {
  path: string;
  label: string | React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  notifications?: number;
  showActiveFlag?: boolean;
};

export const MenuItem = ({ path, label, icon, onClick, notifications = 0, showActiveFlag = false }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === path;
  const handleClick = (path: string): void => {
    navigate(path);
  };

  return (
    <S.MenuItem
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        } else {
          handleClick(path);
        }
      }}
    >
      <Grid container direction={"row"}>
        {showActiveFlag && (
          <Grid>
            <S.ActiveMenuFlag isActive={isActive} />
          </Grid>
        )}
        <Grid>
          <S.MenuIcon>{icon}</S.MenuIcon>
        </Grid>
        <Grid>
          <Badge variant={"dot"} badgeContent={notifications} color="primary">
            <S.MenuLabel>{label}</S.MenuLabel>
          </Badge>
        </Grid>
      </Grid>
    </S.MenuItem>
  );
};

export default MenuItem;
