import React, { useEffect } from "react";
import CSS from "csstype";

import { Button } from "ui";

import * as S from "./styled";

type Props = {
  title: React.ReactNode[] | React.ReactNode | string;
  confirmText?: string;
  cancelText?: string;
  children: React.ReactNode[] | React.ReactNode | string;
  onCancel: () => void;
  onConfirm?: () => void;
  className?: string;
  style?: CSS.Properties;
  titleStyle?: CSS.Properties;
  dialogContentStyle?: CSS.Properties;
  loading?: boolean;
};

const ConfirmPopup = ({
  title,
  confirmText = "Confirm",
  cancelText = "Cancel",
  children,
  onCancel,
  onConfirm,
  className = "",
  style,
  titleStyle,
  dialogContentStyle,
  loading,
}: Props) => {
  useEffect(() => {
    const close = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        onCancel();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <S.Wrapper>
      <S.Overlay />
      <S.Dialog className={className} style={style}>
        <S.DialogTitle style={titleStyle}>{title}</S.DialogTitle>
        <S.DialogContent style={dialogContentStyle}>{children}</S.DialogContent>
        <S.DialogActions>
          {onConfirm && (
            <Button buttonTheme="primary" onClick={onConfirm} disabled={loading}>
              {confirmText}
            </Button>
          )}
          <Button buttonTheme="secondary" onClick={onCancel}>
            {cancelText}
          </Button>
        </S.DialogActions>
      </S.Dialog>
    </S.Wrapper>
  );
};

export default ConfirmPopup;
