import { TableCell } from "@mui/material";
import styled from "styled-components";

export const Emphasis = styled("span")`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary};
`;

export const Section = styled("div")`
  margin: 32px 0;
`;

export const Heading = styled("div")`
  margin-top: 24px;
  margin-bottom: 16px;
  text-decoration: underline;
`;

export const StyledTableCell = styled(TableCell)`
  & {
    background-color: ${(p) => p.theme.colors.primary};
    color: white;
    font-weight: 600;
  }
  // hide last border
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`;

export const Container = styled("div")`
  max-width: 800px;
`;
