import styled from "styled-components";

export const RisktrailScore = styled.div`
  text-align: center;
  color: white;
  background: #cd7005;
  width: 60px;
  padding: 3px;
  border-radius: 5px;
`;
