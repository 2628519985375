import { Colors } from "styles/variables";
import { Chip } from "ui";
import React from "react";

export type TagProps = {
  label: string;
  backgroundColor: keyof Colors;
  color: keyof Colors;
  borderColor?: keyof Colors;
  size?: "small" | "medium";
  icon?: React.ReactElement;
};

export const Tag = ({ label, backgroundColor, color, borderColor, size = "small", icon }: TagProps) => {
  return (
    <Chip
      label={label.toUpperCase()}
      icon={icon}
      sx={(theme) => ({
        borderRadius: size === "small" ? "4px" : "16px",
        backgroundColor: theme.colors[backgroundColor],
        color: theme.colors[color],
        border: borderColor ? `1px solid ${theme.colors[borderColor]}` : "none",
        fontWeight: "bold",
        padding: "4px",
        height: size === "small" ? "20px" : "24px",
        fontSize: "12px",
        width: "fit-content",
      })}
      size="small"
    />
  );
};

export default Tag;
