import { Footer } from "components";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Grid } from "ui";
import * as S from "./styled";

const MainLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/");
    }
  }, []);

  return (
    <Box
      id="landing-page"
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "absolute",
      }}
    >
      <S.Header />
      <Grid
        container
        sx={{
          marginTop: "100px",
        }}
      >
        <Outlet />
      </Grid>
      <Footer />
    </Box>
  );
};

export default MainLayout;
