import { Grid, BitcoinIcon, EthereumIcon } from "ui";
import type { Columns } from "ui";
import { Address, EntityName, HashField, Tags } from "components";
import { getTypeToDisplay, formatCryptoCurrencyValue, formatFiatValue, formatUTCDateWithTimezone } from "utils";
import { TokenTags } from "pages";
import { useContext } from "react";
import AppContext from "contexts/AppContext";
import { validateAddress } from "utils/address";
const getAddressColumns = (): Columns => {
  const { entityTypes } = useContext(AppContext);

  return [
    {
      field: "timestamp",
      type: "date",
      headerAlign: "left",
      headerName: "Transaction Date",
      width: 175,
      valueFormatter: ({ value }) => {
        return formatUTCDateWithTimezone(value);
      },
    },
    {
      field: "address",
      headerAlign: "left",
      headerName: "Address",
      type: "string",
      width: 135,
      renderCell: ({ value }) => (
        <Grid container columnGap={1}>
          <Grid item>{validateAddress(value, "eth") ? <EthereumIcon /> : <BitcoinIcon />}</Grid>
          <Grid item alignItems="center">
            <Address
              link={`/report/${validateAddress(value, "eth") ? "eth" : "btc"}/${value}`}
              address={value}
              variant="short"
            />
          </Grid>
        </Grid>
      ),
    },
    {
      field: "token",
      headerName: "Token",
      width: 80,
      renderCell: ({ value }) => <TokenTags tokens={[value]} />,
    },
    {
      field: "amount",
      headerAlign: "left",
      type: "number",
      headerName: "Amount",
      flex: 0.3,
      renderCell: (params) => {
        const chain = params.row.token === "BTC" ? "btc" : params.row.token === "ETH" ? "eth" : "erc20";
        return `${formatCryptoCurrencyValue(params.value, chain)} ($${formatFiatValue(params.row.value, chain)})`;
      },
    },

    {
      field: "direction",
      headerName: "Direction",
      headerAlign: "left",
      width: 105,
      renderCell: ({ value }) => <span>{value === "in" ? "Incoming" : "Outgoing"}</span>,
    },
    {
      field: "hash",
      headerName: "Hash",
      headerAlign: "left",
      width: 74,
      type: "string",
      valueFormatter: ({ value }) => value?.toString().slice(0, 5),
      renderCell: ({ value, row }) => {
        const chain = validateAddress(row.address, "eth") ? "eth" : "btc";
        return <HashField value={value} coin={chain} row={row} />;
      },
    },
    {
      field: "counterPartyAddress",
      headerAlign: "left",
      type: "string",
      headerName: "Counterparty",
      width: 145,
      renderCell: ({ value }) => (
        <Grid container columnGap={0.5} alignItems="center">
          {validateAddress(value, "eth") ? <EthereumIcon /> : <BitcoinIcon />}
          <Address
            link={`/report/${validateAddress(value, "eth") ? "eth" : "btc"}/${value}`}
            address={value}
            variant="short"
          />
        </Grid>
      ),
    },
    {
      field: "tag",
      headerAlign: "left",
      headerName: "Tag",
      flex: 0.4,
      renderCell: ({ value }) => {
        return <EntityName name={value} />;
      },
    },
    {
      field: "type",
      headerAlign: "left",
      headerName: "Type",
      flex: 0.4,
      renderCell: ({ value }) => {
        const typeToDisplay = getTypeToDisplay(entityTypes, value);
        return typeToDisplay !== "Unknown" ? (
          <Tags tags={[{ type: typeToDisplay }]} showRelevantTag short />
        ) : (
          "Unknown"
        );
      },
    },
  ];
};

export default getAddressColumns;
