import React from "react";
import AutocompleteMD from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Popper from "@mui/material/Popper";
import { InputAdornment } from "@mui/material";
import { InputBaseComponentProps } from "@mui/material/InputBase";

type Props = {
  options: string[];
  label: string;
  onChange?: (value: string, reason?: string) => void;
  className?: string;
  name?: string;
  defaultValue?: string;
  value: string;
  required?: boolean;
  getOptionLabel?: (option: string) => string;
  onValuePick?: (option: string, reason: string) => void;
  allowFreeText?: boolean;
  Icon?: React.FunctionComponent;
  loading?: boolean;
};

const Autocomplete = ({
  label,
  options,
  className,
  value,
  name,
  onChange,
  getOptionLabel,
  onValuePick,
  allowFreeText,
  required = false,
  loading = false,
  Icon,
}: Props) => {
  const startAdornment = Icon ? (
    <InputAdornment position="start">
      <Icon />
    </InputAdornment>
  ) : null;

  return (
    <AutocompleteMD
      PopperComponent={(props) => (
        <Popper
          placement="bottom-start"
          {...props}
          style={{
            minWidth: "300px",
            maxWidth: "fit-content",
          }}
        />
      )}
      className={className}
      options={options}
      inputValue={value || ""}
      getOptionLabel={getOptionLabel ? (option) => getOptionLabel(option) : (option) => option}
      clearOnBlur={true}
      clearOnEscape={true}
      onChange={(event, value, reason: string) => {
        onValuePick && onValuePick(value || "", reason);
        onChange && onChange(value || "");
      }}
      loading={loading}
      renderInput={(params) => {
        const { InputProps, inputProps, ...otherParams } = params;

        const mergedInputProps: InputBaseComponentProps = {
          ...inputProps,
          onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
            inputProps.onBlur?.(event);
            if (!options.includes(value || "")) {
              onChange && !allowFreeText && onChange("");
            }
          },
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            inputProps.onChange?.(event);
            onChange && onChange(event.target.value || "");
          },
        };

        return (
          <TextField
            {...otherParams}
            fullWidth
            required={required}
            label={label}
            placeholder={label}
            margin="normal"
            name={name}
            InputProps={{
              ...InputProps,
              startAdornment,
            }}
            inputProps={mergedInputProps}
          />
        );
      }}
    />
  );
};

export default Autocomplete;
