import React, { useState, useEffect } from "react";
import { Loading, Button, Title, Grid, AddIcon } from "ui";
import { ToastContainer, toast } from "react-toastify";
import ApiKeyModal from "./ApiKeyModal";
import ApiKeyItem from "./ApiKeyItem";
import axios from "axios";
import DeleteApiKey from "./DeleteApi";

export type ApiKey = {
  id: string;
  name: string;
  userId: string;
  expiresAt?: string | null;
  isRevoked: boolean;
  apiKey?: string;
};

const Api: React.FC = () => {
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [currentKey, setCurrentKey] = useState<ApiKey | null>(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [newlyCreatedKey, setNewlyCreatedKey] = useState<ApiKey | null>(null);

  useEffect(() => {
    const fetchApiKeys = async () => {
      setLoading(true);
      try {
        const response = await axios.get<ApiKey[]>("/api/api-keys");
        setApiKeys(response.data);
      } catch (err) {
        setError("Failed to fetch API keys. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchApiKeys();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentKey(null);
  };

  const showNotification = (message: string, severity: "success" | "error") => {
    severity === "error" ? toast.error(message) : toast(message);
  };

  const handleSaveKey = async (newKey: Partial<ApiKey>) => {
    try {
      const response = await axios.post<ApiKey>("/api/api-keys", newKey);
      const createdKey = response.data;
      setApiKeys((prev) => [createdKey, ...prev]);
      setNewlyCreatedKey(createdKey);
      showNotification("API key created successfully", "success");

      setTimeout(() => setNewlyCreatedKey(null), 5 * 60 * 1000); // 5 min

      handleCloseModal();
    } catch (err) {
      showNotification("Failed to save API key", "error");
    }
  };

  const handleDeleteKey = async () => {
    try {
      await axios.delete(`/api/api-keys/${idToDelete}`);
      setApiKeys((prev) => prev.filter((key) => key.id !== idToDelete));
      showNotification("API key deleted successfully", "success");
    } catch (err) {
      showNotification("Failed to delete API key", "error");
    } finally {
      setIsDeleteConfirmOpen(false);
    }
  };

  const handleCopyKey = (key: string) => {
    navigator.clipboard.writeText(key);
    showNotification("API key copied to clipboard", "success");
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Title color="error">{error}</Title>;
  }

  return (
    <Grid container justifyContent="center" xs={6}>
      <ToastContainer />
      <Grid item xs={12} md={10} lg={8}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
              <Grid item>
                <Title variant="regular" textAlign="left">
                  API Keys
                </Title>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ minWidth: "max-content" }}
                  size="medium"
                  icon={<AddIcon />}
                  color="primary"
                  onClick={handleOpenModal}
                >
                  New API Key
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {apiKeys.length === 0 ? (
            <Grid item xs={12} sx={{ p: 3, textAlign: "center" }}>
              <Title variant="small" color="darkGray">
                No API keys found. Create a new one to get started.
              </Title>
            </Grid>
          ) : (
            <Grid container item rowGap={2} xs={12}>
              {apiKeys.map((apiKey) => (
                <Grid item xs={12} key={apiKey.id}>
                  <ApiKeyItem
                    apiKey={apiKey}
                    newlyCreatedKey={newlyCreatedKey}
                    onDelete={(id) => {
                      setIdToDelete(id);
                      setIsDeleteConfirmOpen(true);
                    }}
                    onCopy={handleCopyKey}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>

      {openModal && (
        <ApiKeyModal open={openModal} onClose={handleCloseModal} onSave={handleSaveKey} currentKey={currentKey} />
      )}
      {isDeleteConfirmOpen && (
        <DeleteApiKey onCancel={() => setIsDeleteConfirmOpen(false)} onDelete={handleDeleteKey} />
      )}
    </Grid>
  );
};

export default Api;
