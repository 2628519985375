import styled from "styled-components";

const colorMapping: { [key: string]: string } = {
  high: "darkRed",
  low: "green",
  potential: "darkGray",
};

type Props = {
  value: string;
};

export const Flag = styled.div<Props>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${(p) => p.theme.colors[colorMapping[p.value]]};
`;
