import { ConfirmPopup } from "ui";

type Props = {
  onDelete: () => void;
  onCancel: () => void;
};

export const DeletePortfolio = ({ onDelete, onCancel }: Props) => (
  <ConfirmPopup title="Delete portfolio" confirmText="Delete" onCancel={() => onCancel()} onConfirm={onDelete}>
    Do you really want to delete the portfolio?
  </ConfirmPopup>
);

export default DeletePortfolio;
