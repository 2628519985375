import { Entity, Hack, Sanction } from "types";
import { Grid, Label } from "ui";

type AddressResult = {
  address: string;
  type: "eth" | "btc";
};

export type SearchHistoryItem = {
  id: string;
  name: string;
  type: SearchResultTypes;
};

type Props = {
  items: Entity[] | Hack[] | Sanction[] | AddressResult[] | SearchHistoryItemType[];
  label: string;
  itemType: "address" | "entity" | "hack" | "sanction" | "search_history";
  showItem: (id: string, type: SearchResultTypes) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  isFocused: (id: string) => boolean;
};

import { AddressItem } from "../AddressItem";
import { EntityItem } from "./EntityItem";
import { HackItem } from "./HackItem";
import { SanctionItem } from "./SanctionItem";
import { SearchHistoryItem, SearchHistoryItemType } from "./SearchHistoryItem";
import type { SearchResultTypes } from "./SearchResult";
import * as S from "./styled";

const getKeyFromItem = (item: Entity | Hack | Sanction | AddressResult | SearchHistoryItem): string => {
  if ("name" in item && item.name) {
    return item.name;
  } else if ("Entity" in item && item.Entity) {
    return item.Entity;
  } else if ("Subject" in item && item.Subject) {
    return item.Subject;
  } else if ("address" in item && item.address) {
    return item.address;
  }

  throw new Error("Invalid item: no valid key found");
};

const SearchResultItem = ({ items, label, itemType, onMouseEnter, onMouseLeave, isFocused, showItem }: Props) => (
  <Grid container item direction="column" rowGap={2}>
    <Grid item>
      <Label>{label}</Label>
    </Grid>
    <Grid container item>
      {items.map((item) => (
        <S.SearchItem
          container
          key={getKeyFromItem(item)}
          padding="16px 12px"
          isFocused={isFocused(getKeyFromItem(item))}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={() => showItem(getKeyFromItem(item), itemType)}
        >
          {itemType === "search_history" && <SearchHistoryItem item={item as SearchHistoryItemType} />}
          {itemType === "address" && (
            <AddressItem address={(item as AddressResult).address} type={(item as AddressResult).type} />
          )}
          {itemType === "entity" && <EntityItem entity={item as Entity} />}
          {itemType === "hack" && <HackItem hack={item as Hack} />}
          {itemType === "sanction" && <SanctionItem sanction={item as Sanction} />}
        </S.SearchItem>
      ))}
    </Grid>
  </Grid>
);

export default SearchResultItem;
