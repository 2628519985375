import { Alert } from "@mui/material";

export default function LargeReportMessage() {
  return (
    <>
      <Alert severity="warning">
        Due to the size of the address, this page is displaying information on known direct counterparties only.
      </Alert>
    </>
  );
}
