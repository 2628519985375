const Unknown = ({ width = 18, height = 18 }) => (
  <svg
    width={width}
    height={height}
    style={{ verticalAlign: "middle" }}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.99994" cy="10.9766" r="10" fill="#DCDCE0" />
    <g clipPath="url(#clip0_2526_9276)">
      <path
        d="M8.28132 9.20833C8.28132 8.4959 8.86055 7.91667 9.57299 7.91667H10.2188C10.9313 7.91667 11.5105 8.4959 11.5105 9.20833V9.28099C11.5105 9.72096 11.2865 10.1307 10.9171 10.3668L10.0654 10.9137C9.81537 11.0745 9.60967 11.2955 9.46721 11.5565C9.32475 11.8174 9.25009 12.1099 9.25007 12.4072V12.4375C9.25007 12.7947 9.53868 13.0833 9.8959 13.0833C10.2531 13.0833 10.5417 12.7947 10.5417 12.4375V12.4092C10.5417 12.2438 10.6265 12.0904 10.7637 12.0016L11.6154 11.4546C12.3541 10.9783 12.8022 10.1609 12.8022 9.28099V9.20833C12.8022 7.78145 11.6457 6.625 10.2188 6.625H9.57299C8.1461 6.625 6.98965 7.78145 6.98965 9.20833C6.98965 9.56556 7.27826 9.85417 7.63549 9.85417C7.99271 9.85417 8.28132 9.56556 8.28132 9.20833ZM9.8959 15.6667C10.11 15.6667 10.3153 15.5816 10.4667 15.4302C10.6181 15.2788 10.7032 15.0735 10.7032 14.8594C10.7032 14.6453 10.6181 14.4399 10.4667 14.2885C10.3153 14.1371 10.11 14.0521 9.8959 14.0521C9.6818 14.0521 9.47646 14.1371 9.32506 14.2885C9.17367 14.4399 9.08861 14.6453 9.08861 14.8594C9.08861 15.0735 9.17367 15.2788 9.32506 15.4302C9.47646 15.5816 9.6818 15.6667 9.8959 15.6667Z"
        fill="#292929"
      />
    </g>
    <defs>
      <clipPath id="clip0_2526_9276">
        <rect width="6.45833" height="10.3333" fill="white" transform="translate(6.66678 5.97266)" />
      </clipPath>
    </defs>
  </svg>
);

export default Unknown;
