import * as S from "./styled";

export type AvatarProps = {
  size: "extraSmall" | "small" | "average" | "normal" | "large";
  shape?: "circle" | "square";
  src: string;
};

const Avatar = ({ src, size = "normal", shape = "circle" }: AvatarProps) => (
  <S.Avatar src={src} size={size} shape={shape} />
);

export default Avatar;
