import { Autocomplete, Button, Grid, Input, Label, Select, LightBulbIcon } from "ui";
import { FormEventHandler, useContext, useEffect, useState } from "react";
import * as S from "./styled";
import AppContext from "contexts/AppContext";
import axios from "axios";
import { useModal } from "contexts/ModalContext";

type MoreInfoProps = {
  entityName?: string;
  isSmallScreen: boolean;
};

type ModalBodyProps = {
  entityName?: string;
};

const ModalBody = ({ entityName: defaultEntity }: ModalBodyProps) => {
  const { entities } = useContext(AppContext);
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [entityName, setEntityName] = useState(defaultEntity || "");
  const [infoRequired, setInfoRequired] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");

  const resetForm = () => {
    setIsSending(false);
    setSuccess(false);
    setEntityName(defaultEntity || "");
    setInfoRequired("");
    setAdditionalInfo("");
  };

  useEffect(() => {
    resetForm();
  }, []);

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    if (entityName && infoRequired && !isSending) {
      setIsSending(true);
      const res = await axios.post("/api/users/contact", {
        entityName,
        infoRequired,
        additionalInfo,
      });

      if (res.status >= 200 && res.status < 300) {
        setSuccess(true);
        setIsSending(false);
      } else {
        setError(true);
        setIsSending(false);
      }
    }
  };

  if (error) {
    return (
      <Label size="normal">
        An error occurred while sending your request. Please close this window and try again later.
      </Label>
    );
  }

  return (
    <S.FormContainer onSubmit={onSubmit}>
      <Grid container direction="column" rowGap={2} paddingY={1} width="400px" marginTop={10} paddingBottom={6}>
        {success ? (
          <Grid item textAlign="center">
            <Label size="normal">
              Information request was sent successfully.
              <br /> We will reach out to you in the next 24hs.
            </Label>
          </Grid>
        ) : (
          <>
            <Autocomplete
              options={entities.map((entity) => entity.name)}
              label="Entity name"
              value={entityName}
              onChange={(value) => setEntityName(value)}
            />

            <Select
              items={[
                {
                  value: "Enhanced Entity Diligence",
                  label: "Enhanced Entity Diligence",
                },
                {
                  value: "Blockchain Analytics",
                  label: "Blockchain Analytics",
                },
                { value: "Tracing", label: "Tracing" },
                {
                  value: "Source of Funds Analysis",
                  label: "Source of Funds Analysis",
                },
              ]}
              label="Requirement"
              onChange={(value) => {
                setInfoRequired(String(value));
              }}
              value={infoRequired}
            ></Select>

            <Input
              label="Additional information"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              multiline
              rows={3}
            />
            <Grid container item justifyContent="flex-end" xs={2}>
              <Button type="submit">{isSending ? "Sending..." : "Send"}</Button>
            </Grid>
          </>
        )}
      </Grid>
    </S.FormContainer>
  );
};

export const MoreInfo = ({ entityName, isSmallScreen }: MoreInfoProps) => {
  const { openModal } = useModal();

  return (
    <Grid
      container
      justifyContent="space-between"
      flexWrap="nowrap"
      alignItems="center"
      padding="24px 32px"
      marginTop={10}
      flex-direction={isSmallScreen ? "column" : "row"}
      sx={(theme) => ({
        backgroundColor: theme.colors.lightGray,
        borderRadius: "8px",
      })}
    >
      <Grid item container columnGap={2} xs={10} alignItems="center">
        <Grid item>
          <LightBulbIcon />
        </Grid>
        <Grid item>
          <div>
            <b>Do you need to know more about {entityName}?</b>
          </div>
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-end" xs={2}>
        <Button
          onClick={() => openModal(<ModalBody entityName={entityName} />, "Contact Us", { fitContent: true })}
          size="small"
        >
          Contact us
        </Button>
      </Grid>
    </Grid>
  );
};

export default MoreInfo;
