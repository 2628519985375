import { css } from "styled-components";

export type Colors = {
  primary: string;
  lightPrimary: string;
  secondary: string;
  success: string;
  error: string;
  disabled: string;
  warning: string;
  white: string;
  black: string;
  gray: string;
  darkGray: string;
  mediumGray: string;
  lightGray: string;
  green: string;
  lightGreen: string;
  darkGreen: string;
  darkRed: string;
  lightRed: string;
  lightOrange: string;
  darkOrange: string;
  blue: string;
  lightBlue: string;
  orange: string;
  red: string;
  amber: string;
};

export const colors: Colors = {
  primary: "#DA016D",
  lightPrimary: "#FCEBF3",
  secondary: "#676767",
  success: "#3EAF3F",
  error: "#CF0C02",
  disabled: "#BFBFBF",
  warning: "#FC3737",
  white: "#ffffff",
  black: "#000000",
  gray: "#B2B4B2",
  darkGray: "#676767",
  mediumGray: "#E6E6E6",
  lightGray: "#F6F6F7",
  green: "#3EAF3F",
  lightGreen: "#EDF8EE",
  darkGreen: "#236224",
  darkRed: "#CF0B02",
  lightRed: "#FFEBEB",
  lightOrange: "#FBF1E6",
  darkOrange: "#CD7005",
  blue: "#0288d1",
  lightBlue: "#c5e8f7",
  orange: "#ff7300",
  red: "#FC1A1A",
  amber: "#FFC107",
};

export const fonts = {
  h1: css`
    color: ${colors.primary};
    font-size: 34px;
    font-weight: 700;
    line-height: 32px;
  `,
  h2: css`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  `,
  h3: css`
    color: ${colors.primary};
    font-size: 20px;
    font-weight: bold;
    line-height: 48px;
  `,
  h4: css`
    color: ${colors.primary};
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  `,
  p1: css`
    color: ${colors.black};
    line-height: 24px;
  `,
  p2: css`
    color: ${colors.black};
    font-size: 12px;
    line-height: 24px;
  `,
  subText: css`
    color: ${colors.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    opacity: 0.6;
  `,
  subTextDarkGray: css`
    color: ${colors.darkGray};
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  `,
  subTitleWhite: css`
    color: ${colors.white};
    font-size: 24px;
    line-height: 36px;
  `,
  text1: css`
    color: ${colors.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  `,
  label: css`
    color: ${colors.primary};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  `,
  darkLabel: css`
    color: ${colors.darkGray};
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
  `,
};
