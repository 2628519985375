import React from "react";
import * as S from "./styled";

type Props = {
  children: React.ReactNode[] | React.ReactNode | string;
  color?: keyof Theme["colors"];
  variant?: "regular" | "small";
};

const Subtitle = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, variant = "regular", color } = props;
  return (
    <S.Subtitle ref={ref} variant={variant} color={color} {...props}>
      {children}
    </S.Subtitle>
  );
});

Subtitle.displayName = "Subtitle";

export default Subtitle;
