import axios from "axios";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "types";
import { Loading } from "ui";

type Props = {
  children: React.ReactNode;
};

export type AuthContext = {
  user?: User;
  loading: boolean;
  error?: any;
  login: (username: string, password: string, redirectTo?: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContext>({} as AuthContext);

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  useEffect(() => {
    axios
      .get("/api/users/me")
      .then((user) => setUser(user.data))
      .catch((_error) => {
        navigate("/login", { state: { from: location.pathname } });
      })
      .finally(() => setLoadingInitial(false));
  }, []);

  const login = (username: string, password: string, redirectTo?: string) => {
    setLoading(true);

    axios
      .post("/api/auth/login", { username, password })
      .then((user) => {
        setUser(user.data);
        localStorage.setItem("token", user.data.access_token);
        navigate(redirectTo || "/");
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(undefined);
    navigate("/login");
  };
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout,
    }),
    [user, loading, error],
  );
  if (loadingInitial) return <Loading />;

  return <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
