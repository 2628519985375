import styled from "styled-components";
import {
  CheckCircleIcon as CheckCircleIconUI,
  DangerIcon as DangerIconUI,
  HighlightOffIcon as HighlightOffIconUI,
} from "ui";
import { Colors } from "../../styles";

export const CheckCircle = styled(CheckCircleIconUI)<{
  overrideColor?: keyof Colors;
}>`
  fill: ${(props) => props.theme.colors[props.overrideColor || "green"]};
`;

export const DangerIcon = styled(DangerIconUI)<{
  overrideColor?: keyof Colors;
}>`
  fill: ${(props) => props.theme.colors[props.overrideColor || "green"]};
`;

export const HighlightOffIcon = styled(HighlightOffIconUI)<{
  overrideColor?: keyof Colors;
}>`
  fill: ${(props) => props.theme.colors[props.overrideColor || "green"]};
`;
