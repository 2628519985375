import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Grid, Input, Title, Container } from "ui";
import { ToastContainer, toast } from "react-toastify";

import AuthContext from "contexts/Auth0Context";
import { useErrorHandler } from "hooks";

type FormData = {
  name: string;
  company: string;
  position: string;
  phone: string;
};

enum ButtonStatus {
  NONE,
  ACTIVE,
  WAITING,
  DONE,
}

const Profile: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [passwordResetButtonStatus, setPasswordResetButtonStatus] = useState<ButtonStatus>(ButtonStatus.ACTIVE);
  const handleError = useErrorHandler();

  const { register, handleSubmit, control, reset, formState } = useForm<FormData>({
    defaultValues: {
      name: user.name,
      company: user.company,
      position: user.position,
      phone: user.phone,
    },
  });

  useEffect(() => {
    reset({
      name: user.name,
      company: user.company,
      position: user.position,
      phone: user.phone,
    });
  }, [user]);

  useEffect(() => {
    setIsFormDirty(formState.isDirty);
  }, [formState.isDirty]);

  const handleUpdateUserMetadata = async (data: FormData) => {
    try {
      await axios.patch("/api/users/me", data);
      toast("User metadata updated successfully");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (passwordResetButtonStatus === ButtonStatus.ACTIVE) {
      setPasswordResetButtonStatus(ButtonStatus.WAITING);
      const res = await axios.post(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        email: user?.email,
        connection: "Username-Password-Authentication",
      });
      if (res.status === 200) {
        setPasswordResetButtonStatus(ButtonStatus.DONE);
      } else {
        setPasswordResetButtonStatus(ButtonStatus.ACTIVE);
      }
    }
  };

  return (
    <Container maxWidth="md">
      <ToastContainer />
      <form onSubmit={handleSubmit(handleUpdateUserMetadata)}>
        <Grid container paddingTop={2} paddingBottom={8}>
          <Grid item xs="auto">
            <Title>Profile</Title>
          </Grid>
        </Grid>

        <Grid container direction="column" rowGap={2}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => <Input defaultValue="" label="Full Name" {...field} disabled={true} />}
          />
          <Controller
            control={control}
            name="company"
            render={({ field }) => (
              <Input
                label="Company"
                {...register("company", {
                  required: true,
                })}
                {...field}
                disabled={true}
              />
            )}
          />
          <Controller
            control={control}
            name="position"
            render={({ field }) => (
              <Input
                {...register("position", {
                  required: true,
                })}
                label="Position"
                {...field}
                disabled={true}
              />
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <Input {...register("phone")} label="Phone number (optional)" {...field} disabled={true} />
            )}
          />
          <Grid item container columnGap={1}>
            <Button type="button" onClick={handleResetPassword} size="medium">
              {passwordResetButtonStatus === ButtonStatus.DONE && "Email Sent"}
              {passwordResetButtonStatus === ButtonStatus.WAITING && "Requesting..."}
              {passwordResetButtonStatus === ButtonStatus.ACTIVE && "Reset Password"}
            </Button>
            {isFormDirty && (
              <Button type="submit" size="medium" disabled={isLoading}>
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Profile;
