import { Columns, Grid } from "ui";

import { TokenTags } from "../TokensTags";
import { formatFiatValue } from "utils";
import EventTag from "components/EventTag/EventTag";
import { EntityTag, NationFlag } from "components";

const getColumns = (): Columns => [
  {
    field: "Date",
    headerName: "Date",
    width: 120,
  },

  {
    field: "Entity",
    headerName: "Entity",
    width: 250,
    renderCell: (params) => <EntityTag tag={params.value} isNew={false} />,
  },
  {
    field: "Type",
    headerName: "Type",
    width: 190,
  },
  {
    field: "Event",
    headerName: "Event",
    width: 90,
    renderCell: (params) => <EventTag type="Hack" label={params.value} />,
  },
  {
    field: "Est. Amount Stolen",
    headerName: "Est. Amount Stolen",
    width: 190,
    valueFormatter: (params) => (params.value ? `$${formatFiatValue(params.value)}` : ""),
  },

  {
    field: "Jurisdiction",
    headerName: "Jurisdiction",
    width: 140,
    renderCell: (params) => {
      return (
        <Grid item container flexWrap="nowrap" columnGap={1}>
          <Grid item>
            <NationFlag countryName={params.value} />
          </Grid>
          <Grid item>{params.value}</Grid>
        </Grid>
      );
    },
  },
  {
    field: "Tokens",
    headerName: "Tokens",
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      return <TokenTags tokens={params.value} />;
    },
  },
];

export default getColumns;
