import { useContext, useEffect } from "react";
import { PopUpDialog, Input, Autocomplete, Button, Grid } from "ui";
import { Controller, useForm } from "react-hook-form";
import AppContext from "contexts/AppContext";
import axios from "axios";

type Props = {
  onCreate: (data: EntityFormType) => void;
  onUpdate: (data: EntityFormType) => void;
  entityName: string | null;
  onCancel: () => void;
};

export type EntityFormType = {
  id: number | null;
  tag: string;
  type: string;
  subtype: string;
};

export const EntityPopup = ({ onCreate, onUpdate, onCancel, entityName }: Props) => {
  const appContext = useContext(AppContext);
  const entityTypes = appContext.entityTypes;
  const mode = entityName ? "update" : "create";

  const { control, setValue, handleSubmit } = useForm<EntityFormType>({
    defaultValues: {
      id: null,
      tag: "",
      type: "",
      subtype: "",
    },
  });

  useEffect(() => {
    const loadEntityData = async () => {
      const response = await axios.get(`/api/entities/${entityName}`);
      const entity = response.data;
      const { name, type, subtype } = entity;
      setValue("tag", name);
      setValue("type", type);
      setValue("subtype", subtype);
      setValue("id", entity.id);
    };
    if (entityName) {
      loadEntityData();
    }
  }, [entityName]);

  return (
    <PopUpDialog title={mode === "update" ? "Update Entity" : "Create Entity"} onCancel={() => onCancel()}>
      <Grid container spacing={2} direction="column" marginTop="8px">
        <form onSubmit={handleSubmit(mode === "update" ? onUpdate : onCreate)}>
          <Grid item>
            <Controller
              control={control}
              name="tag"
              render={({ field }) => (
                <Input label={"Entity Name"} placeholder="Enter entity name" {...field} required={true} />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Autocomplete options={entityTypes.map((type) => type.name)} label={"Type"} {...field} />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="subtype"
              render={({ field }) => (
                <Autocomplete options={entityTypes.map((type) => type.name)} label={"Subtype"} {...field} />
              )}
            />
          </Grid>
          <Grid container item columnSpacing={2}>
            <Grid item>
              <Button type="submit">Save</Button>
            </Grid>
            <Grid item>
              <Button buttonTheme="cancel" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </PopUpDialog>
  );
};

export default EntityPopup;
