import { Theme } from "@mui/material";
import ScrollContainerUnsyled from "react-indiana-drag-scroll";
import styled from "styled-components";
import type { EntityTypeRisk } from "types";
import { Table as UITable, Chip as UIChip, DangerIcon as DangerIconUI, InfoIcon as InfoIconUI, Box as BoxUI } from "ui";

export const Table = styled(UITable)`
  &,
  & .MuiDataGrid-cell,
  & .MuiDataGrid-columnHeaders,
  & .MuiDataGrid-footerContainer {
    border-color: ${(p) => p.theme.colors.gray};
  }

  /* & .MuiDataGrid-iconSeparator {
    fill: ${(p) => p.theme.colors.black};
  } */
`;

export const Address = styled.div`
  display: flex;
`;

export const AddressText = styled.div`
  width: 45px;
`;

type ChipProps = {
  profile: EntityTypeRisk;
  theme: Theme;
};

type ScrollContainerProps = {
  hidePrint: boolean;
};

const getChipStyles = ({ profile, theme }: ChipProps) => {
  if (profile === "HIGH" || profile === "VERY_HIGH") {
    return {
      background: theme.colors.lightRed,
      color: theme.colors.darkRed,
    };
  } else if (profile === "MEDIUM") {
    return {
      background: theme.colors.lightGray,
      color: theme.colors.darkGray,
    };
  } else {
    return {
      background: theme.colors.lightGreen,
      color: theme.colors.darkGreen,
    };
  }
};

export const Chip = styled(UIChip)`
  ${(props: ChipProps) => getChipStyles(props)};

  font-weight: bold;
  height: 24px;
  padding: 8px;
`;

export const DangerIcon = styled(DangerIconUI)`
  fill: ${(props) => props.theme.colors.darkRed as string};
`;

export const IconWrapper = styled.div`
  svg {
    width: 64px;
    height: 64px;
  }
`;

export const RiskScoreLabel = styled.div`
  ${(props) => props.theme.fonts.subTextDarkGray};
  margin-right: 5px;
`;

export const InfoIcon = styled(InfoIconUI)`
  fill: ${(props) => props.theme.colors.darkGray};
  width: 16px;
  height: 16px;
`;

export const AddressInfo = styled(BoxUI)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightGray};
  padding: 24px 120px 32px 32px;
`;

export const CategoriesTitle = styled.div`
  ${(props) => props.theme.fonts.subTextDarkGray};
  font-weight: bold;
`;

export const ScrollContainerPrint = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  width: 100%;
  column-gap: 16px;
  padding-right: 0;
  padding-bottom: 16px;
`;

export const ScrollContainer = styled(ScrollContainerUnsyled)`
  display: flex;
  margin-top: 16px;
  width: 100%;
  column-gap: 16px;
  padding-right: 0;
  padding-bottom: 16px;

  ${(props: ScrollContainerProps) =>
    props.hidePrint &&
    `
      @media print {
        display: none;
      }
    `}
`;
