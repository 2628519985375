import React, { forwardRef } from "react";
import type { Entity, EntityReserves, EntitySolvency, EntityLicense } from "types";
import { Grid, Title } from "ui";
import { Summary } from "..";
import Licensing from "./Licensing";
import Header from "./Header";
import { Logo } from "components";
import Overview from "./Overview";
import * as S from "./styled";

type Props = {
  entity: Entity;
  reservers: EntityReserves | null;
  solvency: EntitySolvency | null;
  regulatory: EntityLicense[];
  volume: number;
  ref: React.ForwardedRef<HTMLDivElement>;
};

export const EntityProfilePDF = forwardRef<HTMLDivElement, Props>(
  ({ entity, reservers, solvency, regulatory, volume }, ref) => {
    return (
      <S.EntityProfilePDF
        container
        direction="column"
        justifyContent="space-between"
        sx={{
          minHeight: "1000px",
          minWidth: "1000px",
        }}
        ref={ref}
      >
        {/* Adding a style tag for print orientation preference */}
        <style>
          {`@media print {
            @page {
              size: A4 portrait;
            }
          }`}
        </style>

        <S.PrintHeader>
          <Logo variant="small" />
        </S.PrintHeader>
        <Grid container direction="column" sx={{ minHeight: "1000px", minWidth: "1000px" }}>
          <Grid container direction="column">
            <Header entity={entity} />

            <Grid container rowGap={2}>
              <Grid container item rowGap={8}>
                <Title>Overview</Title>
                <Overview entity={entity} volume={volume} reservers={reservers} solvency={solvency} print={true} />
              </Grid>
              {entity.status !== "Inactive" && entity.integrityCheck && (
                <Grid container direction="column">
                  <Grid item>
                    <Title>Integrity Check</Title>
                  </Grid>
                  <Grid width="650px" paddingTop={2}>
                    <Summary text={entity.integrityCheck} variant="list" showAll={true} />
                  </Grid>
                </Grid>
              )}
              <Grid container direction="column" md={10} rowGap={2} marginTop={2} sx={{ pageBreakInside: "avoid" }}>
                <Grid item>
                  <Title>Licensing</Title>
                </Grid>
                <Grid item>
                  <Licensing licences={regulatory} print={true} />
                </Grid>
              </Grid>
              {entity.description && (
                <Grid container direction="column" sx={{ pageBreakInside: "avoid" }}>
                  <Title>About</Title>
                  <Grid container width="800px">
                    <Summary text={entity.description} showAll={true} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </S.EntityProfilePDF>
    );
  },
);

EntityProfilePDF.displayName = "EntityProfilePDF";

export default EntityProfilePDF;
