import { Grid as MUIGrid, styled, GridProps } from "@mui/material";

export type GridPropsExtended = GridProps & {
  printable?: boolean;
  hidePrint?: boolean;
};

export const StyledGrid = styled(MUIGrid)<GridPropsExtended>`
  ${(props) =>
    props.printable &&
    `
      @media print {
        display: block;
        page-break-inside: avoid;
      }
    `}

  ${(props) =>
    props.hidePrint &&
    `
      @media print {
        display: none;
      }
    `}
`;
