import styled from "styled-components";

export const PDFContainer = styled.div`
  @media print {
    @page {
      size: landscape;
      margin: 0mm;
      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }
      td {
        page-break-inside: avoid;
        page-break-after: auto;
      }
    }
  }
`;

export const PDFWatermark = styled.header`
  position: fixed;
  top: 50vh;
  z-index: 9;
  page-break-after: always;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
`;
