import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div<{ style: any }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const getTextColor = (textColor: string, theme: any) => {
  if (textColor) {
    return theme.colors[textColor];
  } else {
    return theme.colors.secondary;
  }
};

// TODO reuse styles
export const Tab = styled.div<{
  active?: 1 | 0;
  textColor?: string;
  fontSize?: string;
}>`
  padding-bottom: 8px;
  color: ${(p) => (p.active ? p.theme.colors.secondary : getTextColor(p.textColor as string, p.theme))};
  text-align: center;
  transition: color 0.3s;
  position: relative;
  cursor: ${(p) => (p.active ? "default" : "pointer")};
  font-size: ${(p) => p.fontSize || "20px"};

  &:not(:last-of-type) {
    margin-right: 32px;
  }

  &:hover {
    text-decoration: none;
    color: ${(p) => p.theme.colors.secondary};
  }

  &:after {
    content: " ";
    height: 4px;
    background: ${(p) => p.theme.colors.secondary};
    border-radius: 100px;
    transform: scaleX(${(p) => (p.active ? "1" : "0")});
    opacity: ${(p) => (p.active ? "1" : "0")};
    transition: transform 0.3s, opacity 0.3s;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export const Link = styled(NavLink)<{
  active?: 1 | 0;
  textColor?: string;
}>`
  padding-bottom: 8px;
  color: ${(p) => (p.active ? p.theme.colors.secondary : getTextColor(p.textColor as string, p.theme))};
  text-align: center;
  transition: color 0.3s;
  position: relative;
  cursor: ${(p) => (p.active ? "default" : "pointer")};
  font-size: 20px;

  &:not(:last-of-type) {
    margin-right: 32px;
  }

  &:hover {
    text-decoration: none;
    color: ${(p) => p.theme.colors.secondary};
  }

  &:after {
    content: " ";
    height: 4px;
    background: ${(p) => p.theme.colors.secondary};
    border-radius: 100px;
    transform: scaleX(${(p) => (p.active ? "1" : "0")});
    opacity: ${(p) => (p.active ? "1" : "0")};
    transition: transform 0.3s, opacity 0.3s;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;
