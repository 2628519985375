type StarProps = {
  color: string;
};

const EventStar: React.FC<StarProps> = ({ color }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.08326 13.0417V8.60425L2.2291 10.8334C2.00687 10.9584 1.77424 10.9862 1.53118 10.9167C1.28812 10.8473 1.1041 10.6993 0.979096 10.4728C0.854096 10.2549 0.822777 10.0237 0.885138 9.77925C0.94736 9.5348 1.08979 9.35147 1.31243 9.22925L5.1666 7.00008L1.31243 4.79175C1.08979 4.67119 0.94736 4.48828 0.885138 4.243C0.822777 3.99772 0.854096 3.76397 0.979096 3.54175C1.1041 3.31953 1.2916 3.17369 1.5416 3.10425C1.7916 3.0348 2.02771 3.06258 2.24993 3.18758L6.08326 5.41675V0.958415C6.08326 0.70522 6.17243 0.489179 6.35076 0.31029C6.5291 0.131262 6.74437 0.041748 6.9966 0.041748C7.24882 0.041748 7.46521 0.131262 7.64576 0.31029C7.82632 0.489179 7.9166 0.70522 7.9166 0.958415V5.41675L11.7708 3.18758C11.993 3.06258 12.2256 3.0348 12.4687 3.10425C12.7117 3.17369 12.8958 3.31953 13.0208 3.54175C13.1458 3.76397 13.1757 3.99772 13.1106 4.243C13.0456 4.48828 12.9045 4.67119 12.6874 4.79175L8.83326 7.00008L12.6874 9.22925C12.9101 9.35147 13.0525 9.5348 13.1147 9.77925C13.1771 10.0237 13.1458 10.2592 13.0208 10.4857C12.8958 10.7036 12.7083 10.8473 12.4583 10.9167C12.2083 10.9862 11.9722 10.9584 11.7499 10.8334L7.9166 8.60425V13.0417C7.9166 13.2949 7.82743 13.511 7.6491 13.6899C7.47076 13.8689 7.25549 13.9584 7.00326 13.9584C6.75104 13.9584 6.53465 13.8689 6.3541 13.6899C6.17354 13.511 6.08326 13.2949 6.08326 13.0417Z"
      fill={color}
    />
  </svg>
);

export default EventStar;
