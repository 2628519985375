import { Grid, RisktrailScore } from "ui";
import { EntityTag } from "components";
import type { Columns } from "ui";
import { getScoreChange } from "pages/LandingPage/AmlRanksChange/AmlRanksChange";
import { utcToLondonTime } from "utils";

const getColumns = (): Columns => {
  return [
    {
      field: "created_at",
      type: "date",
      headerName: "Change Date",
      width: 180,
      renderCell: ({ value }) => utcToLondonTime(value),
    },
    {
      field: "entity",
      headerName: "Entity",
      type: "string",
      flex: 0.4,
      renderCell: ({ value }) => (
        <Grid container columnGap={1}>
          <EntityTag tag={value} isNew={false} />
        </Grid>
      ),
    },

    {
      field: "oldScore",
      headerName: "Score Change",
      sortable: false,
      flex: 0.6,
      renderCell: ({ value, row }) => (
        <Grid container justifyContent="flex-start" columnGap={2}>
          <Grid item>{row.oldScore ? getScoreChange(row.score, value) : null}</Grid>
          <Grid item>{row.reason}</Grid>
        </Grid>
      ),
    },
    {
      field: "score",
      headerName: "New Score",
      type: "number",
      width: 140,
      sortable: true,
      headerAlign: "left",
      renderCell: ({ value }) => (
        <Grid container justifyContent="center" columnGap={2}>
          <Grid>
            <RisktrailScore score={value} value={value} variant="compact" />
          </Grid>
        </Grid>
      ),
    },
  ];
};

export default getColumns;
