import styled from "styled-components";

const colorMapping: { [key: string]: string } = {
  norisk: "green",
  potential: "darkGray",
  medium: "darkGray",
};

type Props = {
  variant: string;
};

export const Flag = styled.div<Props>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${(p) => p.theme.colors[colorMapping[p.variant]]};
`;

export const Wrapper = styled.div``;

export const EntitiesTableWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  height: 660px;
  ".muidatagrid-cell:focus": {
    outline: "none";
  }
  ,
    // pointer cursor on all rows
    "& .muidatagrid-row:hover": {
    cursor: "pointer";
  }

  & .MuiDataGrid-columnHeaderTitle {
    color: ${(p) => p.theme.colors.secondary};
  }

  & .MuiTable-root {
    table-layout: fixed;
    min-width: 1192px;
  }

  & .MuiTableCell-head,
  .MuiTableCell-body {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .MuiTableCell-head {
    padding: 15px 0 15px 16px;
  }

  & .MuiTableCell-head .Mui-active {
    color: ${(p) => p.theme.colors.primary};
  }

  & .MuiTableCell-body {
    padding: 10px 16px 10px 16px;
  }
  & .MuiDataGrid-columnHeader {
    padding: 16px;
  }

  & .MuiTableCell-body,
  & .MuiTableCell-head {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .status-Inactive {
    color: rgba(0, 0, 0, 0.26);
  }
`;
