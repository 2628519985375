import { Tab } from "@mui/material";

import * as S from "./styled";

type Props = {
  labels: string[];
  activeTabIndex: number;
  onTabChange: (tabIndex: number) => void;
};

export const Tabs = ({ labels, activeTabIndex, onTabChange }: Props) => (
  <S.Tabs value={activeTabIndex} onChange={(evt, tabIndex) => onTabChange(tabIndex)}>
    {labels.map((label) => {
      return <Tab key={label} label={label} />;
    })}
  </S.Tabs>
);

export default Tabs;
