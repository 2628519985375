import { Metadata, NationFlag } from "components";
import BasicInfo from "./BasicInfo";
import Stats from "./Stats";
import { Entity } from "types";
import { Grid } from "ui";
import { useMediaQuery, useTheme } from "@mui/material";

type Props = {
  entity: Entity;
  handlePrint?: () => void;
};

export const Header = ({ entity, handlePrint }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid container rowGap={2} direction="column" marginBottom={isSmallScreen ? 2 : 5}>
      <BasicInfo isSmallScreen={isSmallScreen} entity={entity} handlePrint={handlePrint} />
      <Grid container direction="column" paddingLeft={isSmallScreen ? 0 : 10} rowGap={3}>
        <Stats entity={entity} />
        <Grid sm={10}>
          <Metadata
            columns={isSmallScreen ? 2 : 4}
            list={[
              { title: "Established", value: entity.founded },
              {
                title: "Headquarters",
                value: (
                  <Grid item container flexWrap="nowrap" columnGap={1}>
                    <Grid item>
                      <NationFlag countryName={entity.headquarters} />
                    </Grid>
                    <Grid item>{entity.headquarters}</Grid>
                  </Grid>
                ),
              },
              {
                title: "Domicile",
                value: (
                  <Grid item container flexWrap="nowrap" columnGap={1}>
                    <Grid item>
                      <NationFlag countryName={entity.jurisdiction} />
                    </Grid>
                    <Grid item>{entity.jurisdiction}</Grid>
                  </Grid>
                ),
              },
              { title: "Key Entity", value: entity.legalEntity },
              {
                title: "Key Figure(s)",
                value: entity.keyFigures?.split(";"),

                width: 6,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
