import { formatDistanceToNow } from "date-fns";
import React from "react";
import { DeleteIcon, Grid, IconButton, NotificationActiveIcon, Stack } from "ui";
type Props = {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  lastTriggered: Date | null;
  removeNotification: () => void;
};

const Notification = ({ icon, title, lastTriggered, removeNotification }: Props) => (
  <Grid
    container
    justifyContent="space-between"
    flexWrap="nowrap"
    padding={2}
    border={(theme) => `1px solid ${theme.colors.gray}`}
    borderRadius="12px"
    alignItems="center"
  >
    <Grid container columnGap={1}>
      <Grid item>{icon}</Grid>
      <Grid item>{title}</Grid>
    </Grid>
    <Grid container columnGap={1} justifyContent="flex-end" alignItems="center">
      <Stack>
        <NotificationActiveIcon color={lastTriggered ? "primary" : "secondary"} fontSize="small" />
      </Stack>
      <Grid item>
        {lastTriggered ? formatDistanceToNow(lastTriggered, { addSuffix: true }).replace("about", "") : "Never"}
      </Grid>
    </Grid>
    <Grid container item xs={1}>
      <IconButton aria-label="delete" onClick={() => removeNotification()}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  </Grid>
);

export default Notification;
