import styled from "styled-components";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
type ClipboardProps = {
  $visible: boolean;
};

export const ClipboardIconWrapper = styled.div`
  display: flex;
`;

export const ClipboardIcon = styled(ContentPasteIcon)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  opacity: ${(props: ClipboardProps) => (props.$visible ? 1 : 0)};
  margin-top: -2px;

  $:active {
    opacity: 0;
    box-shadow: 0 5px #666;
    background-color: #3e8e41;
  }
`;
