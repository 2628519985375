const ArrowOut = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L8.85355 7.85355C8.65829 8.04882 8.34171 8.04882 8.14645 7.85355C7.95118 7.65829 7.95118 7.34171 8.14645 7.14645L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645Z"
      fill="#DA016D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.49992C9.00004 2.22378 9.22394 1.99996 9.50008 2L13.4995 2.00062C13.7755 2.00067 13.9993 2.22447 13.9994 2.50055L14 6.49992C14 6.77606 13.7762 6.99996 13.5001 7C13.2239 7.00004 13 6.77622 13 6.50008L12.9995 3.00055L9.49992 3C9.22378 2.99996 8.99996 2.77606 9 2.49992Z"
      fill="#DA016D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4H7.5C7.77614 4 8 4.22386 8 4.5C8 4.77614 7.77614 5 7.5 5L3 5L3 13H11V8.5C11 8.22386 11.2239 8 11.5 8C11.7761 8 12 8.22386 12 8.5V13C12 13.2652 11.8946 13.5196 11.7071 13.7071C11.5196 13.8946 11.2652 14 11 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5C2 4.73478 2.10536 4.48043 2.29289 4.29289Z"
      fill="#DA016D"
    />
  </svg>
);

export default ArrowOut;
