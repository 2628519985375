import AuthContext from "contexts/Auth0Context";
import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

type ProtectedRouteProps = {
  isAdminOnly?: boolean;
  requireApiAccess?: boolean;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAdminOnly = false, requireApiAccess = false }) => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;

  if (isAdminOnly && !user?.isAdmin) {
    return <Navigate to="/" replace />;
  }

  if (requireApiAccess && !user?.api_access) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
