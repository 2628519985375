import xlsx, { IContent } from "json-as-xlsx";
import {
  formatCryptoCurrencyValue,
  formatDate,
  formatFiatValue,
  getEntityToDisplay,
  getHigherRiskType,
  getTypeToDisplay,
} from "utils";
import type { EntityType, Portfolio, Transaction } from "types";
import { validateAddress } from "utils/address";

const portfolioToXLS = (portfolio: Portfolio, highRiskTransactions: Transaction[], entityTypes: EntityType[]) => {
  const getCryptocurrency = (address: string) => {
    return validateAddress(address, "btc") ? "btc" : "eth";
  };

  const xssData = [
    {
      sheet: "Client Cryptocurrency Addresses",
      columns: [
        { label: "Address", value: "address" },
        {
          label: "Asset",
          value: (row: IContent) => {
            return getCryptocurrency(row["address"] as string).toUpperCase();
          },
        },
        {
          label: "Tag",
          value: (row: IContent) => {
            const tagToDisplay = getEntityToDisplay(
              {
                entityTag: row["entityTag"] as string,
                entityType: row["entityType"] as string,
                controllerTag: row["controllerTag"] as string,
                controllerType: row["controllerType"] as string,
              },
              entityTypes,
            ).entityTag;

            return tagToDisplay === "Unknown" ? "" : tagToDisplay;
          },
        },
        {
          label: "Type",
          value: (row: IContent) => {
            const typeToDisplay = getTypeToDisplay(entityTypes, {
              entityType: row["entityType"] as string,
              entitySubType: row["entitySubType"] as string,
              controllerType: row["controllerType"] as string,
              controllerSubType: row["controllerSubType"] as string,
            });
            return typeToDisplay === "Unknown" ? "" : typeToDisplay;
          },
        },
        {
          label: "Balance",
          value: (row: IContent) => {
            const coin = getCryptocurrency(row["address"] as string);
            return formatCryptoCurrencyValue(row["balance"] as number, coin);
          },
        },
        {
          label: "Total Received",
          value: (row: IContent) => {
            const coin = getCryptocurrency(row["address"] as string);
            return formatCryptoCurrencyValue(row["received"] as number, coin);
          },
        },

        {
          label: "First Tx",
          value: (row: IContent) => formatDate(row["firstTx"] as string),
        },
        {
          label: "Last Tx",
          value: (row: IContent) => formatDate(row["lastTx"] as string),
        },
        {
          label: "Tx Count",
          value: "txCount",
        },
        { label: "Flag", value: "flag" },
        { label: "Created At", value: "dataFrom" },
      ],
      content: portfolio.data.map((portfolio) => {
        const highRisks: Transaction[] = Array.isArray(portfolio.highRisks)
          ? portfolio.highRisks
          : [...portfolio.highRisks.incoming, ...portfolio.highRisks.outgoing];

        const flag =
          highRisks?.length === 0
            ? ""
            : [
                ...new Set(
                  highRisks.map((tx: Transaction) => (tx.curHop === 1 || tx.hop === 1 ? "Direct" : "Indirect")),
                ),
              ].join(",");

        return {
          address: portfolio.addressInfo.address,
          entityTag: portfolio.addressInfo.entityName ?? "",
          entityType: portfolio.addressInfo.entityTag ?? "",
          entitySubType: portfolio.addressInfo.entitySubTag ?? "",
          controllerTag: portfolio.addressInfo.controllerTag ?? "",
          controllerType: portfolio.addressInfo.controllerType ?? "",
          controllerSybType: portfolio.addressInfo.controllerSubType ?? "",
          balance: portfolio.addressInfo.balance,
          received: portfolio.addressInfo.received,
          firstTx: portfolio.addressInfo.firstTx,
          lastTx: portfolio.addressInfo.lastTx,
          txCount: portfolio.addressInfo.txCount,
          flag: flag,
          dataFrom: formatDate(portfolio.data_created_at as string) ?? "",
        };
      }),
    },
    {
      sheet: "High Risk Transactions",
      columns: [
        { label: "Client Address", value: "clientAddress" },
        { label: "Risky Address", value: "address" },
        {
          label: "Asset",
          value: (row: IContent) => {
            const coin = validateAddress(row["address"] as string, "btc")
              ? "btc"
              : (row["erc20"] as boolean)
              ? (row["symbol"] as string)
              : "eth";
            return coin.toUpperCase();
          },
        },
        {
          label: "Tag",
          value: (row: IContent) => {
            return getEntityToDisplay(
              {
                entityTag: row["entityTag"] as string,
                entityType: row["entityType"] as string,
                controllerTag: row["controllerTag"] as string,
                controllerType: row["controllerType"] as string,
              },
              entityTypes,
            ).entityTag;
          },
        },
        {
          label: "Direction",
          value: (row: IContent) => {
            const direction = row["direction"] as string;
            return direction.charAt(0).toUpperCase() + direction.slice(1);
          },
        },
        {
          label: "Type",
          value: (row: IContent) => {
            const entityTypesName = [
              row["entityType"] as string,
              row["entitySubType"] as string,
              row["controllerType"] as string,
              row["controllerSubType"] as string,
            ];
            return getHigherRiskType(entityTypes, entityTypesName);
          },
        },
        {
          label: "Date",
          value: (row: IContent) => {
            const value = (row["date"] as string) || (row["time"] as string);
            return formatDate(value);
          },
        },
        {
          label: "Amount",
          value: (row: IContent) => {
            const coin = validateAddress(row["address"] as string, "btc")
              ? "btc"
              : (row["erc20"] as boolean)
              ? "erc20"
              : "eth";
            return formatCryptoCurrencyValue(row["amount"] as number, coin);
          },
        },
        {
          label: "Value ($)",
          value: (row: IContent) => {
            const address = row["address"] as string;
            const isErc20 = row["erc20"] as boolean;
            const coin = validateAddress(address, "btc") ? "btc" : isErc20 ? "erc20" : "eth";
            return formatFiatValue(row["value"] as number, coin);
          },
        },
        { label: "Hash", value: "hash" },
      ],
      content: highRiskTransactions.flatMap((tx) => {
        // Backward compatibility, some transactions didnt have txs field
        if (!tx.txs) {
          tx.txs = [tx];
        }
        return tx.txs.map((subTx) => {
          return {
            entityTag: tx.entityTag,
            entityType: tx.entityType,
            clientAddress: tx.address,
            entitySubType: tx.entitySubType ?? "",
            controllerType: tx.controllerType ?? "",
            controllerSubType: tx.controllerSubType ?? "",
            direction: tx.direction,
            ...subTx,
            labels: tx.labels?.join(", ") ?? "",
          };
        });
      }),
    },
  ];

  const settings = {
    fileName: portfolio.name,
    extraLength: 3,
  };

  xlsx(xssData, settings);
};

export default portfolioToXLS;
