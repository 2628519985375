import { Grid, Table } from "ui";

import { AddressNotificationLog, AddressNotificationType } from "types";
import getAddressColumns from "./columns";

type Props = {
  addressNotificationLogs: AddressNotificationLog[];
  notificationType: AddressNotificationType;
};

const AddressNotifications = ({ addressNotificationLogs, notificationType }: Props) => {
  const rows = [];

  for (const not of addressNotificationLogs) {
    if (not.type !== notificationType) continue;
    rows.push({
      timestamp: not.additionalInfo.timestamp,
      id: not.id,
      address: not.address,
      direction: not.additionalInfo.direction,
      counterPartyAddress: not.additionalInfo?.counterPartyAddress ?? null,
      amount: not.additionalInfo?.amount ?? null,
      tag: not.additionalInfo.tags?.entityName,
      type: not.additionalInfo.tags,
      value: not.additionalInfo?.value ?? null,
      token: not.additionalInfo?.token ?? "",
      hash: not.additionalInfo?.hash ?? "",
    });
  }

  return (
    <Grid container direction="column" width="100vw">
      <Table rowHeight={50} columns={getAddressColumns()} rows={rows} />
    </Grid>
  );
};

export default AddressNotifications;
