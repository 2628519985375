import { parseISO } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";

export const utcToLondonTime = (dateString: string) => {
  const pattern = "dd/MM/yyyy HH:mm";
  const iso = parseISO(dateString);
  const maybe = new Date(Date.UTC(iso.getFullYear(), iso.getMonth(), iso.getDate(), iso.getHours(), iso.getMinutes()));

  const londonTime = utcToZonedTime(maybe, "Europe/London");

  return format(londonTime, pattern);
};

export const formatDate = (dateString: string, format: "short" | "long" = "short") => {
  if (!dateString) {
    return "";
  }

  const date = new Date(dateString);

  if (format === "short") {
    return date.toLocaleDateString("en-gb").replaceAll("/", "-");
  }

  return date.toLocaleDateString("en-GB", { day: "numeric", month: "long", year: "numeric" });
};

export const formatUTCDateWithTimezone = (dateStr: string, timeZone = "UTC") => {
  const pattern = "dd/MM/yyyy HH:mm";
  const date = parseISO(dateStr);
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, pattern);
};
