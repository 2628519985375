import { useContext } from "react";
import { Table } from "ui";
import AppContext from "contexts/AppContext";
import getColumns from "./sanctionsColumns";

import * as S from "../styled";
import SanctionDetail from "./SanctionDetail";
import { useModal } from "contexts/ModalContext";

const Sanctions = () => {
  const { events } = useContext(AppContext);
  const { openModal } = useModal();
  const rows = events.sanctions;
  const columns = getColumns();

  return (
    <S.EntitiesTableWrapper>
      <Table
        rowHeight={50}
        columns={columns}
        pageSize={10}
        rows={rows}
        pagination
        autoHeight={false}
        onRowClick={(params) => {
          openModal(<SanctionDetail sanctionId={params.id} />);
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "Date", sort: "desc" }],
          },
        }}
      />
    </S.EntitiesTableWrapper>
  );
};

export default Sanctions;
