import axios, { AxiosError } from "axios";
import React, { useState } from "react";
import { Input, Grid, Loading, Title, Button } from "ui";
import Cluster from "./Cluster";
import { toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import Metadata from "./Metadata/";
import { getAddressCoin } from "utils";
import { Coin, Cluster as ClusterMeta } from "types";
import { MetaData } from "./Metadata/MetadataForm";
import { Tab, Tabs } from "@mui/material";
import CSVFileUploader from "./CSVFileUploader";

type IFormInput = {
  address: string;
};
const validationWithMessage = (func: (input: string) => string | undefined, message: string) => {
  return (input: string) => (func(input) ? undefined : message);
};

export const Address: React.FC = () => {
  const [clusterData, setClusterData] = useState<ClusterMeta | null>(null);
  const [metadata, setMetadata] = useState<MetaData | null>(null);
  const [address, setAddress] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentCoin, setCurrentCoin] = useState<Coin>();
  const [tabIndex, setTabIndex] = useState(0);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<IFormInput>();

  const getAddressData = async (data: IFormInput) => {
    try {
      const coin = getAddressCoin(data.address);
      const address = coin === "btc" ? data.address : data.address.toLowerCase();
      setIsLoading(true);
      setAddress(address);
      setCurrentCoin(coin);
      const promises = [axios.get(`/api/address/${address}/metadata/`)];
      if (coin === "btc") {
        promises.push(axios.get(`/api/address/${address}/cluster/`));
      }
      const [metadataResponse, clusterResponse] = await Promise.all(promises);
      if (clusterResponse) {
        setClusterData(clusterResponse.data);
      }
      setMetadata(metadataResponse.data);
    } catch (error: unknown | AxiosError) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        toast.error("Address not found.");
      } else {
        throw error;
      }
      setClusterData(null);
      setMetadata(null);
      setAddress("");
    } finally {
      reset({ address: "" });
      setIsLoading(false);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Grid container maxWidth={1000} marginTop={5} height="100%" direction="column" flexWrap="nowrap" rowGap={3}>
      <ToastContainer />
      <Grid container item>
        <Title>Addresses</Title>
      </Grid>

      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Search" />
        <Tab label="Upload" />
      </Tabs>

      {tabIndex === 0 && (
        <form onSubmit={handleSubmit(getAddressData)}>
          <Grid container item rowSpacing={3} width="100%">
            <Grid container item columnSpacing={1} flexWrap="nowrap" columnGap={1}>
              <Grid item>
                <Controller
                  control={control}
                  name="address"
                  render={({ field }) => (
                    <Input
                      label="Address"
                      helperText={errors.address ? errors.address.message : ""}
                      error={errors.address !== undefined}
                      {...register("address", {
                        required: true,
                        validate: validationWithMessage(getAddressCoin, "Invalid Address Format"),
                      })}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid container alignItems="center">
                <Button type="submit" disabled={isLoading}>
                  Search
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {tabIndex === 1 && <CSVFileUploader />}

      {isLoading ? (
        <Loading variant="small" />
      ) : (
        tabIndex === 0 && (
          <Grid container item xs={12}>
            {currentCoin === "btc" && clusterData && (
              <Grid item container xs={6}>
                <Cluster
                  address={address}
                  cluster={clusterData}
                  onClusterSave={(updatedClusterData) => setClusterData(updatedClusterData)}
                />
              </Grid>
            )}
            {currentCoin && (
              <Grid item container xs={6}>
                <Metadata
                  address={address}
                  metadata={metadata}
                  onMetadataUpdate={(metadata) => setMetadata(metadata)}
                />
              </Grid>
            )}
          </Grid>
        )
      )}
    </Grid>
  );
};

export default Address;
