import { Card, CardContent, ListItem, List, Stack } from "@mui/material";
import { EventTag } from "components";
import { Grid, Label } from "ui";
import type { Event, Sanction, Hack, Scam, EventType } from "types/Events";
import { formatDate } from "utils";
import { compareAsc, parseISO } from "date-fns";

type Props = {
  hacks: Hack[];
  sanctions: Sanction[];
  scams: Scam[];
  onEventSelected: (eventType: EventType, eventID: number | string) => void;
};

export const Events = ({ hacks, scams, sanctions, onEventSelected }: Props) => {
  let events: (Event & { eventType: EventType })[] = hacks.map((hack) => {
    return {
      ...hack,
      eventType: "Hack",
    };
  });
  events = events.concat(
    scams.map((event) => {
      return {
        ...event,
        eventType: "Scam",
      };
    }),
  );

  events = events.concat(
    sanctions.map((event) => {
      return {
        ...event,
        eventType: "Sanction",
      };
    }),
  );

  const rows = events
    .sort((a, b) => {
      const dateA = a.Date ? parseISO(a.Date) : new Date(0);
      const dateB = b.Date ? parseISO(b.Date) : new Date(0);
      return compareAsc(dateB, dateA);
    })
    .slice(0, 10);
  return (
    <List sx={{ width: "100%", overflowY: "hidden" }} component={Stack} direction="row">
      {rows.map((event, index: number) => (
        <ListItem key={index} sx={{ minWidth: 400 }}>
          <Card
            sx={{
              height: 255,
              ":hover": { boxShadow: 20 },
              transform: "translateX(0)",
              transition: "transform 0.5s ease",
              cursor: "pointer",
            }}
            key={index}
            onClick={() => {
              onEventSelected(event.eventType, event.id);
            }}
          >
            <CardContent>
              <Grid container direction="column" rowGap={1.1}>
                <Grid container item justifyContent="space-between">
                  <Grid item>
                    <EventTag type={event.eventType} />
                  </Grid>
                  <Grid item>
                    <Label>{formatDate(event.Date)}</Label>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <b>{event.Subject ? event.Subject : event["Event Description"]}</b>
                  </Grid>
                  <Grid item>
                    <Label>{event.Type}</Label>
                  </Grid>
                </Grid>
                <Grid item sx={{ height: 90, overflowY: "hidden" }}>
                  {event.Synopsis?.substring(0, 150)}...
                </Grid>
                <Grid item>
                  <Label>Read More</Label>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </ListItem>
      ))}
    </List>
  );
};

export default Events;
