import { useState, useRef, useCallback } from "react";
import axios from "axios";
import { AxiosError } from "axios";
import { Grid, Button, Box, Subtitle, FileUploadOutlinedIcon, Table } from "ui";
import { toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import type { GridColDef, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid";
import { extractSizeLimit, formatFileSize, parseCSVPreview } from "utils/files";

const MAX_PREVIEW_ROWS = 5;
const MAX_FILE_SIZE = (Number(process.env.REACT_APP_MAX_FILE_SIZE) || 20) * 1024 * 1024;

type CSVPreviewData = {
  headers: string[];
  rows: string[][];
};

type AddressFromCSV = {
  address: string;
  owner_tag: string;
  labels: string[];
  controller_tag: string;
};

type UpdateTableRow = AddressFromCSV & {
  id: string;
};

const useFileUpload = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [csvPreview, setCSVPreview] = useState<CSVPreviewData | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const resetFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setFile(null);
    setCSVPreview(null);
    setUploadProgress(0);
    setUploading(false);
  }, []);

  return {
    file,
    uploading,
    uploadProgress,
    csvPreview,
    fileInputRef,
    setFile,
    setUploading,
    setUploadProgress,
    setCSVPreview,
    resetFile,
  };
};

const formatValidationError = (message: string): string => {
  if (message.includes("Validation failed (expected size is less than")) {
    const sizeLimit = extractSizeLimit(message);
    return `File size too large. Maximum allowed size is ${sizeLimit}`;
  }
  return message;
};

const getUpdateTableRows = (addresses: AddressFromCSV[]): UpdateTableRow[] =>
  addresses.map((addr) => ({ ...addr, id: addr.address }));

const getPreviewTableData = (preview: CSVPreviewData | null) => {
  if (!preview) return { columns: [], rows: [] };

  const columns = preview.headers.map((header) => ({
    field: header,
    headerName: header,
    width: 150,
  }));

  const rows = preview.rows.map((row, index) => ({
    id: index,
    ...Object.fromEntries(preview.headers.map((header, colIndex) => [header, row[colIndex] || ""])),
  }));

  return { columns, rows };
};

// Table configuration
const updateTableColumns: GridColDef[] = [
  {
    field: "address",
    headerName: "Address",
    width: 300,
    sortable: true,
  },
  {
    field: "owner_tag",
    headerName: "Owner Tag",
    width: 150,
    sortable: true,
  },
  {
    field: "controller_tag",
    headerName: "Controller Tag",
    width: 150,
    sortable: true,
  },
  {
    field: "labels",
    headerName: "Labels",
    width: 300,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => params.value.join(", "),
  },
];

export const CSVFileUploader: React.FC = () => {
  const {
    file,
    uploading,
    uploadProgress,
    csvPreview,
    fileInputRef,
    setFile,
    setUploading,
    setUploadProgress,
    setCSVPreview,
    resetFile,
  } = useFileUpload();

  const [addressesToUpdate, setAddressesToUpdate] = useState<AddressFromCSV[]>([]);
  const [isConfirmationStep, setIsConfirmationStep] = useState(false);
  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = useCallback(() => {
    resetFile();
    setIsConfirmationStep(false);
    setAddressesToUpdate([]);
    setSelectedAddresses([]);
  }, [resetFile]);

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];
      if (!selectedFile) return;

      if (selectedFile.size > MAX_FILE_SIZE) {
        toast.error(`File too large. Maximum size is ${formatFileSize(MAX_FILE_SIZE)}`);
        resetFile();
        return;
      }

      setFile(selectedFile);
      try {
        const preview = await parseCSVPreview(selectedFile);
        setCSVPreview(preview);
      } catch (error) {
        toast.error(error instanceof Error ? error.message : "Failed to preview CSV file");
        resetFile();
      }
    },
    [resetFile, setFile, setCSVPreview],
  );

  const handleFileUpload = useCallback(async () => {
    if (!file) {
      toast.error("Please select a file before uploading.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setUploading(true);
    setUploadProgress(0);

    try {
      const response = await axios.post("/api/address/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setUploadProgress(progress);
        },
      });

      if (!response.data.success) {
        const errorMessages = response.data.errors?.join(", ") || "Unknown error";
        toast.error(`Failed to process CSV file: ${errorMessages}`);
        resetFile();
        return;
      }

      if (response.data.addressesToUpdate?.length > 0) {
        setAddressesToUpdate(response.data.addressesToUpdate);
        setIsConfirmationStep(true);
        toast.success(response.data.message);
        toast.info(`${response.data.addressesToUpdate.length} addresses need update`);
      } else {
        toast.success(response.data.message ?? "Successfully processed all addresses");
        handleReset();
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorMessage = formatValidationError(error.response.data.message);
        toast.error(errorMessage || "File upload failed.");
      } else {
        toast.error("Something went wrong when uploading the file.");
      }
      resetFile();
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  }, [file, resetFile, handleReset]);

  const handleConfirmUpdates = useCallback(async () => {
    if (selectedAddresses.length === 0) {
      toast.error("Please select at least one address to update");
      return;
    }

    setIsLoading(true);
    try {
      const addressesToConfirm = addressesToUpdate.filter((addr) => selectedAddresses.includes(addr.address));

      const response = await axios.post("/api/address/confirm-updates", {
        addressesToUpdate: addressesToConfirm,
      });

      if (response.data.success) {
        toast.success(`Successfully updated ${selectedAddresses.length} addresses`);
        handleReset();
      } else {
        toast.error(response.data.message ?? "Failed to update addresses");
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        const errorMessage = formatValidationError(error.response.data.message);
        toast.error(errorMessage || "Address update failed.");
      } else {
        toast.error("Something went wrong when confirming address updates.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [selectedAddresses, addressesToUpdate, handleReset]);

  const renderConfirmationStep = () => (
    <Box mt={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Subtitle variant="regular" color="secondary">
            Select addresses to update:
          </Subtitle>
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <Table
            rows={getUpdateTableRows(addressesToUpdate)}
            columns={updateTableColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            onSelectionModelChange={(model: GridSelectionModel) =>
              setSelectedAddresses(model.map((id) => id.toString()))
            }
            selectionModel={selectedAddresses}
            loading={isLoading}
          />
        </Grid>
        <Grid item>
          <Box mt={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReset}
              style={{ marginRight: "8px" }}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirmUpdates}
              disabled={isLoading || selectedAddresses.length === 0}
            >
              Update Selected ({selectedAddresses.length})
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderUploadStep = () => (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          icon={file ? undefined : <FileUploadOutlinedIcon />}
        >
          {file ? `Selected: ${file.name}` : "Select CSV File"}
          <input ref={fileInputRef} type="file" accept=".csv" onChange={handleFileChange} hidden />
        </Button>
      </Grid>

      {file && (
        <Grid item>
          <Subtitle variant="regular" color="secondary">
            File size: {formatFileSize(file.size)}
          </Subtitle>
        </Grid>
      )}

      <Grid item>
        {uploading && (
          <Grid item>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Subtitle variant="regular" color="secondary">
              Uploading: {uploadProgress}%
            </Subtitle>
          </Grid>
        )}

        {csvPreview && file && (
          <Grid item>
            <Box mb={2}>
              <Subtitle variant="regular" color="primary">
                Preview (first {MAX_PREVIEW_ROWS} rows):
              </Subtitle>
              <Table
                fontSize="small"
                {...getPreviewTableData(csvPreview)}
                initialState={{
                  sorting: { sortModel: [] },
                }}
              />
            </Box>
          </Grid>
        )}

        <Grid marginTop={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleReset}
            disabled={!file && !uploading && uploadProgress === 0}
            style={{ marginRight: "8px" }}
          >
            Reset
          </Button>
          <Button variant="contained" color="primary" onClick={handleFileUpload} disabled={uploading || !file}>
            {uploading ? `Uploading ${file?.name}...` : "Upload CSV"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return <Box mt={2}>{isConfirmationStep ? renderConfirmationStep() : renderUploadStep()}</Box>;
};

export default CSVFileUploader;
