import { ConfirmPopup } from "ui";

type Props = {
  onDelete: () => void;
  onCancel: () => void;
};

export const DeleteApiKey = ({ onDelete, onCancel }: Props) => (
  <ConfirmPopup title="Delete API Key" confirmText="Delete" onCancel={() => onCancel()} onConfirm={onDelete}>
    Do you really want to delete this API Key?
  </ConfirmPopup>
);

export default DeleteApiKey;
