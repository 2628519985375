import styled, { keyframes } from "styled-components";

const show = keyframes`
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.5);
`;

export const Dialog = styled.div`
  background: ${(p) => p.theme.colors.white};
  border-radius: 8px;
  animation: ${show} 0.3s linear;
  opacity: 1;

  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;

export const DialogContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogTitle = styled.div`
  ${(p) => p.theme.fonts.h4};
  position: relative;
  padding: 24px 32px;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 16px;
    bottom: 16px;
    width: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: ${(p) => p.theme.colors.primary};
  }
`;

export const Close = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleText = styled.span`
  ${(p) => p.theme.fonts.h4};
  font-weight: bold;
`;
