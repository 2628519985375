import MenuList from "@mui/material/MenuList";
import { Divider, Menu } from "@mui/material";
import PortfolioMenuItem from "./PortfolioMenuItem";
import { Grid, Subtitle, TextButton } from "ui";
import { useContext, useState } from "react";
import { AppContext } from "contexts";
import AddPortfolioItem from "pages/Report/ReportHeader/PortfolioMenu/AddPortfolioItem";
import { Portfolio } from "types";

type Props = {
  open: boolean;
  anchor: HTMLElement | null;
  reportAddress: string;
  onAddedToPortfolio: (portfolioID: number, portfolioName: string) => void;
  removeFromPortfolio: (portfolioId: number, portfolioName: string) => void;
  onClose: () => void;
};

export const PortfolioMenu = ({
  open,
  anchor,
  onClose,
  onAddedToPortfolio,
  removeFromPortfolio,
  reportAddress,
}: Props) => {
  const { portfolios, savePortfolio } = useContext(AppContext);
  const [editMode, setEditMode] = useState(false);

  const isAddressInPortfolio = (portfolio: Portfolio) => {
    return portfolio.data.some((report) => report.addressInfo.address === reportAddress);
  };

  return (
    <Menu
      disableScrollLock={true}
      open={open}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        marginTop: 2,
      }}
      onClose={onClose}
      slotProps={{
        paper: {
          style: {
            width: 280,
          },
        },
      }}
      MenuListProps={{
        style: {
          paddingBottom: 0,
        },
      }}
    >
      <MenuList sx={{ padding: "0 20px" }}>
        <Grid item padding={1}>
          <Subtitle>Add To Portfolio</Subtitle>
        </Grid>

        {portfolios.map((portfolio) => (
          <PortfolioMenuItem
            path={`/portfolios/${portfolio}`}
            key={portfolio.name}
            label={portfolio.name}
            isAddressInPortfolio={isAddressInPortfolio(portfolio)}
            onRemoveFromPortfolio={() => {
              removeFromPortfolio(portfolio.id, portfolio.name);
            }}
            onAddToPortfolio={() => {
              onAddedToPortfolio(portfolio.id, portfolio.name);
            }}
          />
        ))}
        {editMode && (
          <AddPortfolioItem
            existingPortfolioNames={portfolios.map((portfolio) => portfolio.name)}
            onSavePortfolio={(name: string) => {
              savePortfolio(name);
              setEditMode(false);
            }}
          />
        )}
      </MenuList>
      <Grid item alignSelf="stretch">
        <Divider flexItem />
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignContent="center"
        direction="column"
        height={50}
        padding="0  20px"
      >
        {editMode ? (
          <TextButton
            text="Cancel"
            variant="secondary"
            onClick={(e) => {
              setEditMode(false);
            }}
          />
        ) : (
          <TextButton
            text="Create New"
            onClick={(e) => {
              setEditMode(true);
            }}
          />
        )}
      </Grid>
    </Menu>
  );
};

export default PortfolioMenu;
