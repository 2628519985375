import { Grid, Chip } from "ui";
import { useTheme } from "styled-components";
import { getScoreColors } from "utils";
type Props = {
  score: number;
  entityTag?: string;
  value: number | string;
  justifyContent?: string;
  variant?: "expandend" | "compact";
  ignoreScoreColor?: boolean;
  borderRadius?: number;
};

export const RisktrailScore = ({
  score,
  value,
  entityTag,
  variant = "expandend",
  justifyContent,
  ignoreScoreColor,
  borderRadius = 4,
}: Props) => {
  const theme: Theme = useTheme() as Theme;

  const colors = getScoreColors(score, entityTag === "Wallet", theme.colors, ignoreScoreColor);
  return (
    <Grid container justifyContent={justifyContent}>
      {variant === "compact" ? (
        <Chip
          label={value}
          variant="outlined"
          size="small"
          customBackground={colors.backgroundColor}
          customColor={colors.color}
        />
      ) : (
        <Chip
          label={value}
          // size="small"
          customBackground={colors.backgroundColor}
          customColor={colors.color}
          sx={{
            width: "100%",
            fontWeight: "bold",
            borderRadius: borderRadius,
          }}
        />
        // <Grid container justifyContent={justifyContent}>
        //   <S.RisktrailScore
        //     bgcolor={colors.backgroundColor}
        //     color={colors.color}
        //   >
        //     {value}
        //   </S.RisktrailScore>
        // </Grid>
      )}
    </Grid>
  );
};

export default RisktrailScore;
