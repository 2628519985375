import CSS from "csstype";
import * as S from "./styled";
import { Colors } from "../../styles/variables";
import React from "react";

type Props = {
  children: React.ReactNode[] | React.ReactNode | string;
  color?: keyof Colors;
  style?: CSS.Properties;
};

const Description = ({ children, color = "darkGray", style }: Props) => (
  <S.Description color={color} style={style}>
    {children}
  </S.Description>
);

export default Description;
