import { SxProps } from "@mui/material";
import { InputProps as MuiInputProps } from "@mui/material/Input";
import React from "react";

import * as S from "./styled";

type Props = {
  placeholder?: string;
  value?: string;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  invalid?: boolean;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  pattern?: string;
  ref?: React.ForwardedRef<HTMLInputElement>;
  name?: string;
  error?: boolean;
  helperText?: string;
  type?: string;
  variant?: "standard" | "filled" | "outlined" | undefined;
  defaultValue?: string | number;
  multiline?: boolean;
  rows?: number;
  fullWidth?: boolean;
  InputProps?: MuiInputProps;
  sx?: SxProps<Theme>;
};

const Input = React.forwardRef(function Input(
  {
    value,
    onChange,
    onBlur,
    label,
    placeholder = "",
    disabled = false,
    required = false,
    pattern,
    name,
    error,
    helperText,
    type,
    variant,
    defaultValue,
    multiline,
    rows,
    fullWidth = false,
    InputProps,
    sx,
    ...otherProps
  }: Props,
  ref,
) {
  return (
    <S.TextField
      sx={sx}
      error={error}
      value={value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      required={required}
      inputRef={ref}
      variant={variant || "outlined"}
      helperText={helperText}
      onBlur={onBlur}
      inputProps={{
        pattern: pattern,
        defaultValue,
      }}
      InputProps={InputProps}
      type={type}
      multiline={multiline}
      rows={rows}
      fullWidth={fullWidth}
      {...otherProps}
    />
  );
});

export default Input;
