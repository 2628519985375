import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { Grid } from "ui";

import { useErrorHandler } from "hooks";
import ClusterDetail from "./ClusterDetail";
import ClusterHeader from "./ClusterHeader";
import { ClusterForm } from "./ClusterForm";
import { Cluster as ClusterMeta } from "types";
import UnlinkCluster from "./UnlinkCluster";

type Props = {
  cluster: ClusterMeta;
  address: string;
  onClusterSave: (cluster: ClusterMeta) => void;
};

const Cluster = ({ cluster, onClusterSave, address }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [showDeleteAddress, setShowRemoveAddress] = useState(false);

  const handleError = useErrorHandler();

  const updateClusterData = async (data: ClusterMeta) => {
    const { tag, type } = data;
    try {
      const response = await axios.put<ClusterMeta>(`/api/address/${address}/cluster/`, {
        address: address,
        id: cluster.id,
        tag: tag,
        type: type,
      });
      toast("Address cluster saved successfully.");
      setEditMode(false);
      onClusterSave(response.data);
    } catch (error) {
      handleError("Something went wrong when updating the cluster.");
    }
  };

  const removeEntityFromCluster = async () => {
    try {
      const response = await axios.put<ClusterMeta>(`/api/address/${address}/cluster/`, {
        ...cluster,
        tag: "",
        type: "",
      });
      toast("Address cluster saved successfully.");
      onClusterSave(response.data);
    } catch (error) {
      handleError("Something went wrong when updating the cluster.");
    } finally {
      setShowRemoveAddress(false);
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid container item direction="column" rowSpacing={2} xs={12}>
        <Grid item>
          <ClusterHeader
            cluster={cluster}
            address={address}
            editMode={editMode}
            removeAddressFromCluster={() => setShowRemoveAddress(true)}
            setEditMode={(isEditMode) => setEditMode(isEditMode)}
          />
          {editMode && (
            <ClusterForm cluster={cluster} onSubmit={updateClusterData} onCancel={() => setEditMode(false)} />
          )}
        </Grid>
        {!editMode && <ClusterDetail cluster={cluster} />}
        {showDeleteAddress && (
          <UnlinkCluster onUnlink={() => removeEntityFromCluster()} onCancel={() => setShowRemoveAddress(false)} />
        )}
      </Grid>
    </Grid>
  );
};

export default Cluster;
