import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "types";
import { ErrorDialog, Loading } from "ui";

type Props = {
  children: React.ReactNode;
};

type AuthContextType = {
  user: User;
  updateUser: () => void;
  updateNumberOfSearches: (numberOfSearches: number) => void;
  updateNumberOfPortfolios: (numberOfCreatedPortfolios: number) => void;
};

export const AuthContext: React.Context<AuthContextType> = createContext<AuthContextType>({
  user: {} as User,
  updateUser: () => null,
  updateNumberOfSearches: () => null,
  updateNumberOfPortfolios: () => null,
});

export const AuthProvider = ({ children }: Props) => {
  const { isLoading, error, isAuthenticated, loginWithRedirect, getAccessTokenSilently, user: auth0User } = useAuth0();

  const [token, setToken] = useState<string | null>(null);
  const [inActiveuser, setInActiveUser] = useState(false);
  const [numberOfPortfolios, setNumberOfPortfolios] = useState(0);
  const [numberOfSearches, setNumberOfSearches] = useState(0);
  const [remainingPortfolios, setRemainingPortfolios] = useState(0);
  const [remainingSearches, setRemainingSearches] = useState(0);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  if (!isLoading && !isAuthenticated && !error) {
    loginWithRedirect({
      // audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      // redirectUri: window.location.origin,
      appState: { targetUrl: window.location.pathname },
      // scope:
      //   "read:current_user update:current_user_metadata profile email openid",
    });
  }

  const updateUser = async () => {
    // update user object properties with userData
    if (auth0User) {
      const auth0Response = await axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${auth0User?.sub}`,
      );
      const rolesAttribute = `${process.env.REACT_APP_DOMAIN}/roles`;
      const userAppResponse = await axios.get("/api/users/me");
      const userData = auth0Response.data;
      const user = {
        roles: auth0User[rolesAttribute],
        isAdmin: auth0User[rolesAttribute].includes("admin"),
        numberOfSearches: userAppResponse.data.number_of_searches,
        numberOfPortfolios: userAppResponse.data.number_of_portfolios,
        maxSearches: userAppResponse.data.maximum_searchers,
        maxPortfolios: userAppResponse.data.maximum_portfolios,
        remainingPortfolios: userAppResponse.data.remaining_portfolios,
        remainingSearches: userAppResponse.data.remaining_searches,
        lastUserSearches: userAppResponse.data.latest_searches,
        company: userData.user_metadata.company,
        position: userData.user_metadata.position,
        phone: userData.user_metadata.phone,
        name: userData.user_metadata.name,
        email: userData.email,
        api_access: userData.user_metadata.api_access,
      };
      setUser(user);
      setRemainingPortfolios(userAppResponse.data.remaining_portfolios);
      setRemainingSearches(userAppResponse.data.remaining_searches);
      setNumberOfPortfolios(userAppResponse.data.number_of_portfolios);
      setNumberOfSearches(userAppResponse.data.number_of_searches);
      setInActiveUser(userData.user_metadata.status === "inactive");
    }
  };

  const updateNumberOfSearches = (numberOfSearches: number) => {
    setNumberOfSearches(numberOfSearches);
  };

  const updateNumberOfPortfolios = (numberOfPortfolios: number) => {
    setNumberOfPortfolios(numberOfPortfolios);
    setRemainingPortfolios(user!.maxPortfolios - numberOfPortfolios);
  };

  useEffect(() => {
    const loadUserData = async () => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently({
          // audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          // scope:
          //   "read:current_user update:current_user_metadata profile email openid",
        });
        setToken(token);
        localStorage.setItem("token", token);
      }
    };
    loadUserData();
  }, [isAuthenticated]);

  useEffect(() => {
    if (token && auth0User) {
      updateUser();
    }
  }, [token, auth0User]);

  useEffect(() => {
    // Removes URL params to prevent invalid state on refresh
    if (error) {
      navigate("/");
    }
  }, [error]);

  if (isLoading || (!isLoading && isAuthenticated && token === null)) {
    return <Loading />;
  }

  if (error !== undefined) {
    return <ErrorDialog title={error.message} />;
  }

  if (inActiveuser) {
    return (
      <ErrorDialog
        title={"Thank you for registering!  Please await account activation."}
        actionName="LOGOUT"
        onAction={() =>
          (location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`)
        }
      />
    );
  }

  return (
    user && (
      <AuthContext.Provider
        value={{
          user: {
            ...user,
            remainingPortfolios,
            remainingSearches,
            numberOfPortfolios,
            numberOfSearches,
          },
          updateUser,
          updateNumberOfSearches,
          updateNumberOfPortfolios,
        }}
      >
        {isAuthenticated && children}
      </AuthContext.Provider>
    )
  );
};

export default AuthContext;
